// Route

export const APP_ROUTE = 'app.route'

export const APP_ROUTE_UPDATE = 'app.route.update'

// Page

export const APP_PAGE_ANIMATION = 'app.page.animation'

export const APP_PAGE_ANIMATION_STARTED = 'app.page.animation.started'

export const APP_PAGE_ANIMATION_COMPLETED = 'app.page.animation.completed'

export const APP_PAGE_CHANGE_THEME = 'app.page.change.theme'

export const APP_PAGE_MOBILE_SLIDE_UPDATE = 'app.page.mobile.slide.update'

export const APP_PAGE_TESTIMONIAL_SLIDE_UPDATE =
  'app.page.testimonial.slide.update'

export const APP_PAGE_NEXT_AVAILABLE = 'app.page.next.available'

// Other

export const APP_VIEW_UPDATE = 'app.view.update'

export const APP_THREE_PAGE_READY = 'app.three.page.ready'

export const APP_MENU_TOGGLE = 'app.menu.toggle'

export const APP_MENU_ANIMATION_STATE = 'app.menu.animation.state'

export const APP_PARTNER_SELECT = 'app.partner.select'

export const APP_SWITCH_BACKGROUND_MEDIA = 'app.switch.background.media'

export const APP_SWITCH_BACKGROUND_THREE_SCENE =
  'app.switch.background.three.scene'

export const APP_ADD_THREE_CUSTOM_SCENE = 'app.add.three.custom.scene'
