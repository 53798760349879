import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { siteWrapperCss } from '@/modules/utils/siteWrapper'

export const ThreeContainer = styled.div`
  background: transparent;
  height: 100vh;
  position: fixed;
  width: 100vw;
`

interface LayoutProps {
  flex: boolean
}

interface FooterProps {
  subPage: boolean
}

export const ShareIconWrap = styled.div`
  width: 24px;
  height: 24px;
  /* background-color: pink; */
`

export const ShareIcon = styled.div`
  background-image: url('/images/share.png');
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const LayoutContainer = styled.div<LayoutProps>`
  ${(props) =>
    props.flex &&
    css`
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      aside {
        ${breakpoints({
          /* eslint-disable sort-keys */
          xxl: css`
            display: flex;
          `,
          /* eslint-enable sort-keys */
        })}
      }
    `}
  height: 100%;
`

export const Content = styled.div<LayoutProps>`
  ${(props) =>
    props.flex
      ? css`
          align-items: center;
          display: flex;
          height: 100%;
        `
      : css`
          margin-top: 2rem;
        `}
`
export const ContentFullHeight = styled.div<LayoutProps>`
  ${(props) =>
    props.flex
      ? css`
          align-items: center;
          display: flex;
          height: 100%;
        `
      : css`
          margin-top: 2rem;
        `}
`
export const ContentScrollable = styled.div<LayoutProps>`
  overflow: auto;
  width: 100vw;
  height: 100vh;
`

const SideBar = styled.aside`
  align-items: center;
  color: light;
  display: none;
  height: 100vh;
  justify-content: center;
  ${breakpoints({
    /* eslint-disable sort-keys */
    xxl: css`
      display: none;
    `,
    /* eslint-enable sort-keys */
  })}
  position: absolute;
  top: 0;
  width: 154px;
  display: none;
`

const SideBarOnSmaller = styled.aside`
  align-items: center;
  color: light;
  display: none;
  height: 100vh;
  justify-content: center;
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      display: flex;
    `,
    /* eslint-enable sort-keys */
  })}
  position: absolute;
  top: 0;
  width: 154px;
`

export const Left = styled(SideBar)`
  z-index: 2;
`

export const LeftOnSmaller = styled(SideBarOnSmaller)`
  right: 0;
  z-index: 2;
`
export const RightOnSmaller = styled(SideBarOnSmaller)`
  right: 0;
  z-index: 2;
`

export const Right = styled(SideBar)`
  right: 0;
  z-index: 2;
`

export const Footer = styled.div<FooterProps>`
  align-items: center;
  color: light;
  position: fixed;
  bottom: 32px;
  display: flex;
  height: 30px;

  margin: 0 auto;
  justify-content: ${(props) => (props.subPage ? 'flex-end' : 'space-between')};
  z-index: 4;
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      z-index: 1;
    `,
    xl: css`
      padding: 0.5rem;
    `,
    xxl: css`
      display: none;
    `,
    /* eslint-enable sort-keys */
  })}
  padding: 0.5rem 2rem;
  width: 100%;

  ${siteWrapperCss};
  max-width: 100%;
`

export const MobileFooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 5;
  left: 0;
  padding: 0px 0px 0px 25px;
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      position: absolute;
      max-width: 352px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 0 30px 0;
    `,
    xl: css`
      padding: 0 40px 30px 40px;
      max-width: initial;
      left: initial;
      transform: initial;
    `,
    /* eslint-enable sort-keys */
  })}
`

export const MobileFooterBackground = styled(Footer)`
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
  padding: 40px 30px 30px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  ${breakpoints({
    /* eslint-disable sort-keys */
    xxl: css`
      display: none;
    `,
    /* eslint-enable sort-keys */
  })};
`

export const MobileFooter = styled.div<{ subPage: boolean }>`
  align-items: center;
  color: light;
  position: fixed;
  display: flex;
  height: 30px;
  pointer-events: none;

  > * {
    pointer-events: initial;
  }

  margin: 0 auto;
  justify-content: ${(props) => (props.subPage ? 'flex-end' : 'space-between')};

  z-index: 5;
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      z-index: 1;
    `,
    xl: css`
      padding: 0.5rem;
    `,
    xxl: css`
      display: none;
    `,
    /* eslint-enable sort-keys */
  })}
  padding: 0.5rem 2rem;
  width: 100%;

  ${siteWrapperCss};
  bottom: 32px;
  max-width: 100%;
  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      padding: 10px 30px;
    `,
    xl: css`
      padding: 10px 30px;
    `,
    xxl: css`
      display: none;
    `,
    /* eslint-enable sort-keys */
  })}
`

export const ContactHolder = styled.div`
  position: absolute;
  left: 32px;
`

export const HamburgerWrap = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 32px;
  width: 100%;
  height: 21px;
  z-index: 5;
  padding: 0.5rem 2rem;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`
export const HamburgerWrapInner = styled.div`
  position: relative;

  * {
    color: #000;
  }

  > div {
    margin-right: 1px;
    top: -24px;
    right: 0px;
  }
`
