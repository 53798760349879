import React, { useEffect } from 'react'

import {
  LayoutContainer,
  Content,
  Button,
  ShareIconWrap,
  ShareIcon,
} from '../styled'
import Footer from '@/components/Footer'
import PubSub from 'pubsub-js'
import { APP_THREE_PAGE_READY, APP_PAGE_CHANGE_THEME } from '@/events'

//
// @ts-ignore
const noThreeTemplateNews: React.FC<{
  path: string
  desktop: boolean
  dropdownActive: boolean
}> = ({ children, dropdownActive }) => {
  useEffect(() => {
    PubSub.publish(APP_THREE_PAGE_READY, {})
  }, [])
  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'title',
          url: `https://ohpen.com/${'slug'}`,
        })
        .then(() => {
          // console.log('Thanks for sharing!')
        })
    } else {
      console.log('no sher')
    }
  }
  return (
    <>
      <LayoutContainer flex>
        <Content flex>{children}</Content>
        <Footer
          customIcon={
            <ShareIconWrap onClick={() => onShare()}>
              <ShareIcon />
            </ShareIconWrap>
          }
          withContact={false}
          dropdownActive={dropdownActive}
          subPage
        />
      </LayoutContainer>
    </>
  )
}

export default noThreeTemplateNews
