import theme from '@/layouts/theme'

const containerWidth = () => {
  let width = 1000

  const isServer = typeof window === 'undefined'
  if (!isServer) {
    width = window.innerWidth - 200
  }
  if (width > theme.breakpoints.xxxl) {
    width = theme.breakpoints.xxxl
  }
  if (!isServer) {
    if (window.innerWidth <= theme.breakpoints.xxl) {
      width = window.innerWidth - 64
    }
    if (window.innerWidth <= theme.breakpoints.xl) {
      width = window.innerWidth
    }
  }
  return width
}

export default containerWidth
