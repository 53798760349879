// import { useStaticQuery, graphql } from 'gatsby'

export const useSitePages = (): Record<string, unknown> => ({
  contact: ['/contact'],
  home: [
    '/home',
    '/home/about',
    '/home/adaptable',
    '/home/reliable',
    '/home/compliant',
    '/home/testimonials',
    '/home/followup',
  ],
  'our-dna': [
    '/our-dna',
    '/our-dna/manifest',
    '/our-dna/first',
    '/our-dna/join',
    '/our-dna/values',
    '/our-dna/ohpeneers',
    '/our-dna/ohpeneer-overview',
    '/our-dna/social',
  ],
  'our-platform': [
    '/our-platform',
    '/our-platform/legacy',
    '/our-platform/one',
    '/our-platform/foundation',
    '/our-platform/modules',
    '/our-platform/partners',
    '/our-platform/certifications',
    '/our-platform/followup',
  ],
  careers: [
    '/careers',
    '/careers/become',
    '/careers/tour',
    '/careers/settle',
    '/careers/health',
    '/careers/play',
    '/careers/overview',
  ],
  'latest-insights': ['/latest-insights', '/latest-insights/overview'],
  'clients-and-cases': [
    '/clients-and-cases',
    '/clients-and-cases/freedom-for-our-clients',
    '/clients-and-cases/cases',
  ],
})
