import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
/* eslint-disable indent */

export const Content = styled.div`
  max-width: 1120px;
  width: 100%;
  position: absolute;
  overflow-y: auto;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;

  > button {
    flex: 0 0 auto;
    align-self: flex-end;
  }

  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      padding: 0 35px;
      top: 130px;
      bottom: 85px;
    `,
    md: css`
      max-height: 100%;
      padding: 0 10px 0 0;
      bottom: 0;
      top: 160px;
    `,
    /* eslint-enable sort-keys */
  })}
  ul, li {
    display: block;
    list-style: none;
  }
`

export const DropdownMenuWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  right: 0;
  top: 0;
  z-index: 4;
  counter-reset: link-group;
`

export const Footer = styled.div`
  bottom: 21px;
  color: #676767;
  display: flex;
  font-family: VisueltPro-Light, sans-serif;
  font-size: 1.2rem;
  justify-content: space-between;
  flex: 1 0 auto;
  padding-bottom: 10px;
  align-items: flex-end;

  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      margin-top: 25px;
    `,
    md: css`
      margin-top: 110px;
    `,
    /* eslint-enable sort-keys */
  })}
  a {
    color: #676767;
  }

  .social-links {
    padding-left: 130px;
  }
  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      .social-links {
        display: none;
      }
      > * {
        flex: 1 0 100%;
      }
    `,
    md: css`
      .social-links {
        display: block;
      }
      > * {
        flex: 1 0 50%;
      }
    `,
    /* eslint-enable sort-keys */
  })}
`
export const LocationsWrapper = styled.div`
  width: 100%;
  display: flex;
  font-family: VisueltPro-Light, sans-serif;
  transition: max-height 250ms ease-in-out, margin 250ms ease-in-out;
  overflow: hidden;
  flex: 1 0 auto;

  .image-wrapper {
    flex: 1 0 50%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    transition: clip-path 400ms 250ms ease-in-out;

    ${breakpoints({
      /* eslint-disable sort-keys */
      xs: css`
        order: 2;
      `,
      md: css`
        order: initial;
      `,
      /* eslint-enable sort-keys */
    })}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .locations {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 50%;
    font-size: 1.4rem;
    line-height: 1.3em;

    ${breakpoints({
      /* eslint-disable sort-keys */
      xs: css`
        margin-bottom: 0;
      `,
      md: css`
        padding: 0 0 0 10%;
        margin-bottom: -55px;
      `,
      /* eslint-enable sort-keys */
    })}
    > li {
      flex: 1 0 60%;

      &:nth-child(2n) {
        flex: 1 0 40%;
      }
    }
  }

  &.is-closed {
    max-height: 0;
    margin: 0;

    .image-wrapper {
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
      transition: clip-path 750ms ease-in-out;
    }
  }

  &.is-open {
    max-height: 1000px;
  }

  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      margin-top: 0;
      flex-direction: column;
    `,
    md: css`
      margin-top: 180px;
      flex-direction: row;
    `,
    /* eslint-enable sort-keys */
  })}
`

export const LocationChevron = styled.button`
  float: right;
  width: 30px;
  height: 30px;
  background: url('/images/chevron-down.svg') center center no-repeat;
  cursor: pointer;

  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;

  transition: transform 1s ease-in-out;

  &.is-open {
    transform: rotate3d(1, 0, 0, 180deg);
  }
`
