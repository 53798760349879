// @ts-nocheck
/* tslint:disable */

import * as THREE from 'three'
import { globalHistory, HistoryLocation } from '@reach/router'

/**
 * TODO: Type all data instead of using data: Record<string, unknown>
 */
interface Callbacks {
  onInit: (data: Record<string, unknown>) => void
  onUpdate: (deltaTime: number) => void
  onPointerMove: (e: PointerEvent) => void
  onPointerDown: (e: PointerEvent) => void
  onPointerUp: (e: PointerEvent) => void
  //onRouteChange: (location: string) => void
  onResize: (data: Record<string, unknown>) => void
}

interface ThreeSourceData {
  data: []
}

export default class ThreeManager {
  // General

  private containerElement: HTMLElement = null
  // Callbacks

  private onInit: (data: Record<string, unknown>) => void = null

  private onUpdate: (deltaTime: number) => void = null

  private onPointerMove: (e: PointerEvent) => void = null

  private onPointerDown: (e: PointerEvent) => void = null

  private onPointerUp: (e: PointerEvent) => void = null

  //private onRouteChange: (location: string) => void = null

  private onResize: (data: Record<string, unknown>) => void = null

  private removeHistoryListener: () => void = null

  // Three

  private clock: THREE.Clock = null

  constructor(containerElement: HTMLElement) {
    this.containerElement = containerElement
    this.clock = new THREE.Clock()
    //this.attachHistoryEvents()
  }

  public init(location: HistoryLocation, sourcedData): void {
    this.onInit({
      location,
      sourcedData,
      threeContainerElement: this.containerElement,
    })
    this.attachDomEvents()
    this.update()
  }

  public destroy(): void {
    this.removeDomEvents()
    this.removeHistoryEvents()
  }

  public setCallbacks(callbacks: Callbacks): void {
    this.onInit = callbacks.onInit
    this.onUpdate = callbacks.onUpdate
    this.onPointerMove = callbacks.onPointerMove
    this.onPointerDown = callbacks.onPointerDown
    this.onPointerUp = callbacks.onPointerUp
    //this.onRouteChange = callbacks.onRouteChange
    this.onResize = callbacks.onResize
  }

  private attachDomEvents(): void {
    window.addEventListener('resize', this.resize)
    document.addEventListener('pointermove', this.onPointerMove)
    document.addEventListener('pointerdown', this.onPointerDown)
    document.addEventListener('pointerup', this.onPointerUp)
  }

  private removeDomEvents(): void {
    window.removeEventListener('resize', this.resize)
    document.removeEventListener('pointermove', this.onPointerMove)
    document.removeEventListener('pointerdown', this.onPointerDown)
    document.removeEventListener('pointerup', this.onPointerUp)
  }

  // private attachHistoryEvents = (): void => {
  //   this.removeHistoryListener = globalHistory.listen(({ location }) => {
  //     this.onRouteChange(location.pathname)
  //   })
  // }

  private removeHistoryEvents = (): void => {
    this.removeHistoryListener()
  }

  private update = (): void => {
    this.onUpdate(this.clock.getDelta())
    window.requestAnimationFrame(this.update)
  }

  private resize = (): void => {
    clearTimeout(this.resizeTimeout)
    const scope = this
    this.resizeTimeout = setTimeout(function () {
      scope.onResize({
        aspect:
          scope.containerElement.offsetWidth /
          scope.containerElement.offsetHeight,
        height: scope.containerElement.offsetHeight,
        width: scope.containerElement.offsetWidth,
      })
    }, 200)
  }
}
