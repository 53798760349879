// @ts-nocheck
/* tslint:disable */

var OhpenShaderCustom = {
  uniforms: {
    colorBlockOpacity: {
      value: 1,
    },
    colorBlockOpacity2: {
      value: 1,
    },
    tColor: {
      value: null,
    },
    tDepth: {
      value: null,
    },
    tColor2: {
      value: null,
    },
    tDepth2: {
      value: null,
    },
    tMask: {
      value: null,
    },
    sinCosTexture: {
      value: null,
    },
    focus: {
      value: 1.0,
    },
    focusRange: {
      value: 0.5,
    },
    aperture: {
      value: 0.025,
    },
    maxblur: {
      value: 0.01,
    },
    forwardBlurLimit: {
      value: 0.0,
    },
    focus2: {
      value: 1.0,
    },
    focusRange2: {
      value: 0.5,
    },
    aperture2: {
      value: 0.025,
    },
    maxblur2: {
      value: 0.01,
    },
    aspect: {
      value: 1.0,
    },
    width: {
      value: 1.0,
    },
    height: {
      value: 1.0,
    },
    time: {
      value: null,
    },
    sceneOpacity: {
      value: 1,
    },
    backgroundColor: {
      value: {
        x: 21 / 255,
        y: 21 / 255,
        z: 21 / 255,
      },
    },
    blockColorLeft: {
      value: {
        x: 0 / 255,
        y: 0 / 255,
        z: 0 / 255,
      },
    },
    blockColorRight: {
      value: {
        x: 0 / 255,
        y: 0 / 255,
        z: 0 / 255,
      },
    },
    blockColorLeft2: {
      value: {
        x: 0 / 255,
        y: 0 / 255,
        z: 0 / 255,
      },
    },
    blockColorRight2: {
      value: {
        x: 0 / 255,
        y: 0 / 255,
        z: 0 / 255,
      },
    },
    blockTLPos: {
      value: {
        x: 0,
        y: 0,
      },
    },
    blockBRPos: {
      value: {
        x: 0,
        y: 0,
      },
    },
    blockTLPos2: {
      value: {
        x: 0,
        y: 0,
      },
    },
    blockBRPos2: {
      value: {
        x: 0,
        y: 0,
      },
    },
    blockInCurrent: {
      value: 1.0,
    },
    blockMaskInvered: {
      value: 0,
    },
    blockMaskInvered2: {
      value: 0,
    },
  },

  vertexShader: [
    'varying vec2 vUv;',

    'void main() {',

    '	vUv = uv;',
    '	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

    '}',
  ].join('\n'),

  fragmentShaderConstructor: (highQualityShader: boolean) => {
    var shader = [
      '// blur shader',
      '#include <common>',

      'varying vec2 vUv;',

      'uniform float colorBlockOpacity;',
      'uniform float colorBlockOpacity2;',

      'uniform sampler2D tColor;',
      'uniform sampler2D tDepth;',
      'uniform sampler2D tColor2;',
      'uniform sampler2D tDepth2;',
      'uniform sampler2D tMask;',
      'uniform sampler2D sinCosTexture;',

      'uniform float maxblur;',
      'uniform float forwardBlurLimit;',
      'uniform float aperture;',
      'uniform float focus;',
      'uniform float focusRange;',

      'uniform float maxblur2;',
      'uniform float aperture2;',
      'uniform float focus2;',
      'uniform float focusRange2;',

      'uniform float aspect;',

      'uniform float width;',
      'uniform float height;',
      'uniform float time;',
      'uniform float sceneOpacity;',
      'uniform vec3 backgroundColor;',

      'uniform vec3 blockColorLeft;',
      'uniform vec3 blockColorRight;',
      'uniform vec3 blockColorLeft2;',
      'uniform vec3 blockColorRight2;',

      'uniform vec2 blockTLPos;',
      'uniform vec2 blockBRPos;',

      'uniform vec2 blockTLPos2;',
      'uniform vec2 blockBRPos2;',

      'uniform float blockMaskInvered;',
      'uniform float blockMaskInvered2;',

      'uniform float blockInCurrent;',

      '#include <packing>',

      `float sinf(float x)
        {
          x*=0.159155;
          x-=floor(x);
          float xx=x*x;
          float y=-6.87897;
          y=y*xx+33.7755;
          y=y*xx-72.5257;
          y=y*xx+80.5874;
          y=y*xx-41.2408;
          y=y*xx+6.28077;
          return x*y;
        }
        float cosf(float x)
        {
          return sinf(x+1.5708);
    }`,

      'vec4 depthOfField(in vec4 col, float dofblur, sampler2D tDepth, sampler2D tDepth2, sampler2D tColor, sampler2D tColor2, float viewZ, float forwardBlurLimit, vec2 aspectcorrect, float masked, vec2 vUvDistorted) {',
      '  if (!(abs(dofblur) > 0.0001 && viewZ < 0.99)) { return col; }',
      '    float colorDivision = 1.0;',
      '    vec4 colorValue;',
      '    float depthValue;',
      '    float depthOffset;',
      '    vec2 currentDofBlur;',
      '    const int blurLevels = 2;',
      '    const int radialDetail = 8;',
      '    int radialSkip = 1;',
      '    vec2 pos;',
      '    float sinPos;',
      '    float cosPos;',
      '    float angle;',
      '      for (int j = 0; j < blurLevels; j++){',
      '          currentDofBlur = (1.0 - (float(j) / float(blurLevels))) * vec2(dofblur, dofblur);',
      '          for (int i = 0; i < radialDetail; i++){',
      '              angle = float(i) / float(radialDetail);',
      '              sinPos = (texture2D(sinCosTexture, vec2(angle)).x - 0.5) * 2.0;',
      '              cosPos = (texture2D(sinCosTexture, vec2(angle)).y - 0.5) * 2.0;',
      '              pos = vec2(sinPos, cosPos);',
      '	             depthValue = texture2D( tDepth, vUvDistorted.xy + ( pos * aspectcorrect * 0.35 ) * currentDofBlur ).x * masked;',
      '	             colorValue = texture2D( tColor, vUvDistorted.xy + ( pos * aspectcorrect * 0.35 ) * currentDofBlur ) * masked;',
      '	             depthValue += texture2D( tDepth2, vUvDistorted.xy + ( pos * aspectcorrect * 0.35 ) * currentDofBlur ).x * (1.0 - masked);',
      '	             colorValue += texture2D( tColor2, vUvDistorted.xy + ( pos * aspectcorrect * 0.35 ) * currentDofBlur ) * (1.0 - masked);',
      '              depthOffset = min(max(pow((viewZ - depthValue) + 1.5, 3.0), forwardBlurLimit), 1.0);',
      '              col += colorValue * depthOffset;',
      '              colorDivision += depthOffset;',
      '         }',
      '     }',
      '    col = col / colorDivision;',
      '    return col;',
      '}',

      'void main() {',

      // get aspect ratio
      '	vec2 aspectcorrect = vec2( 1.0, aspect );',
      // see if pixel is masked or not
      '   vec4 mask = texture2D(tMask, vUv);',
      '   float masked = floor(mask.a + 0.5);',

      '  float randomValTimed = fract(sinf(dot(vUv.xy + vec2(time * 1.1, time * 2.6), vec2(12.9898,78.233))) * 43758.5453123);',
      '  float randomVal = fract(sinf(dot(vUv.xy + vec2(1.1, 2.6), vec2(12.9898,78.233))) * 43758.5453123);',

      // add the distortion from the mask texture
      '   vec2 vUvDistorted = vUv * vec2(1.0 - mask.g * 0.1, 1.0 - mask.g * 0.1);',
      '   vUvDistorted = ((vUvDistorted - 0.5) * (1.0 - min(1.0, (mask.r * mask.r * mask.r)) * 0.1)) + 0.5;',

      // get depth and pixel of both scenes
      '	  vec4 col = vec4( 0.0 );',
      '   col += texture2D( tColor, vUvDistorted.xy) * masked;',
      '   col += texture2D( tColor2, vUvDistorted.xy ) * (1.0 - masked);',

      // check if the color box is on this pixel
      '   float blockAlpha = (vUv.x >= blockTLPos.x && vUv.x <= blockBRPos.x && 1.0 - vUv.y >= (blockTLPos.y) && 1.0 - vUv.y <= (blockBRPos.y)) ? 1.0 : 0.0;',
      '   float blockAlpha2 = (vUv.x >= blockTLPos2.x && vUv.x <= blockBRPos2.x && 1.0 - vUv.y >= (blockTLPos2.y) && 1.0 - vUv.y <= (blockBRPos2.y)) ? 1.0 : 0.0;',

      '   blockAlpha = blockAlpha * (1.0 - blockMaskInvered) + (1.0 - blockAlpha) * (blockMaskInvered);',
      '   blockAlpha2 = blockAlpha2 * (1.0 - blockMaskInvered2) + (1.0 - blockAlpha2) * (blockMaskInvered2);',

      '   blockAlpha = blockAlpha * masked;',
      '   blockAlpha = max(0.0,min(1.0, blockAlpha));',

      '   blockAlpha2 = blockAlpha2 * (1.0 - masked);',
      '   blockAlpha2 = max(0.0,min(1.0, blockAlpha2));',

      '   blockAlpha *= colorBlockOpacity;',
      '   blockAlpha2 *= colorBlockOpacity2;',
    ].join('\n')

    var shaderMid = [
      // calculate depth of field

      '   float viewZ = 0.0;',
      '   viewZ += texture2D( tDepth2, vUvDistorted).x * (1.0 - masked);',
      '   viewZ += texture2D( tDepth, vUvDistorted).x * masked;',

      // get the depth factor
      '   float trueFocus =  ((focus * masked) + focus2 * (1.0 - masked));',
      '   float trueFocusRange =  ((focusRange * masked) + focusRange2 * (1.0 - masked));',
      '   float trueAperture =  ((aperture * masked) + aperture2 * (1.0 - masked));',
      '   float trueMaxblur =  ((maxblur * masked) + maxblur2 * (1.0 - masked));',

      '	  float factor = viewZ - trueFocus;',
      '	  factor = abs(factor * 2.0 - 1.0);',
      '	  factor = factor - trueFocusRange;',
      '	  factor = clamp(factor, forwardBlurLimit, 1.0);',
      '	  factor = factor / trueAperture;',
      '	  float dofblur = ( clamp( -factor, -trueMaxblur, trueMaxblur ) );',
      '   dofblur = dofblur * (1.0 - blockAlpha);',

      '  if (abs(dofblur) > 0.0001 && viewZ < 0.99){',
      '      col = depthOfField(col, dofblur, tDepth, tDepth2, tColor, tColor2, viewZ, forwardBlurLimit, aspectcorrect, masked, vUvDistorted);',
      '  }',
    ].join('\n')

    // colored block
    var shaderEnd = [
      '   vec4 blockColor = vec4(blockColorLeft * vUv.x + blockColorRight * (1.0 - vUv.x), 1.0);',
      '   vec4 blockColor2 = vec4(blockColorLeft2 * vUv.x + blockColorRight2 * (1.0 - vUv.x), 1.0);',

      '   col = col * (1.0 - blockAlpha) + blockColor * blockAlpha;',
      '   col = col * (1.0 - blockAlpha2) + blockColor2 * blockAlpha2;',

      // add noise

      '   col = col + (col * (clamp( 0.1 + randomValTimed, 0.0, 0.02 ) - 0.01) + (randomValTimed * 0.02 - 0.01)) * (1.1 - blockAlpha);',

      // set pixel color and check if shadow is applied trough the mask blue channel
      '  gl_FragColor = (col * (1.0 - mask.b) + mask.r * ((1.0 - blockAlpha) + 1.0) / 2.0 * 0.02) * sceneOpacity + vec4(backgroundColor, 1.0) * (1.0 - sceneOpacity);',
      '  gl_FragColor.a = 1.0;',

      //'  gl_FragColor = texture2D( tColor, vUv.xy) * masked;',

      '}',
    ].join('\n')

    if (highQualityShader) {
      shader += shaderMid
      shader += shaderEnd
    } else {
      shader += shaderEnd
    }

    return shader
  },
  fragmentShader: [
    '// low shader',
    '#include <common>',

    'varying vec2 vUv;',

    'uniform sampler2D tColor;',

    '#include <packing>',

    'void main() {',

    '  gl_FragColor = texture2D( tColor, vUv.xy);',
    '  gl_FragColor.a = 1.0;',

    '}',
  ].join('\n'),
}
export { OhpenShaderCustom }
