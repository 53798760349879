// @ts-nocheck
/* tslint:disable */

import * as THREE from 'three'
import PubSub from 'pubsub-js'
import { GLTFLoader } from './modules/GLTFLoader'
import { TweenMax, Power2, Power1 } from 'gsap'
import Stats from './modules/stats.module'
import { navigate } from 'gatsby'
import { EffectComposer } from './modules/EffectComposer'
import { ShaderPass } from './modules/ShaderPass'
import { OhpenShaderCustom } from './modules/OhpenShaderCustom'
import ThreeMaskCanvas from './modules/ThreeMaskCanvas'
import CheckWebGLSupport from './modules/CheckWebGLSupport'
import ScenesData from './modules/ScenesData'
import OhpenPointMaterial from './modules/OhpenPointMaterial'
import {
  APP_ROUTE_UPDATE,
  APP_PAGE_MOBILE_SLIDE_UPDATE,
  APP_VIEW_UPDATE,
  APP_PAGE_CHANGE_THEME,
  APP_THREE_PAGE_READY,
  APP_MENU_TOGGLE,
  APP_PAGE_ANIMATION_COMPLETED,
  APP_PARTNER_SELECT,
  APP_SWITCH_BACKGROUND_MEDIA,
  APP_SWITCH_BACKGROUND_THREE_SCENE,
  APP_ADD_THREE_CUSTOM_SCENE,
} from '../../events'
import { getSanitizedLocation } from '@/modules/utils/location'

export default class Three {
  private renderer: THREE.Renderer = null

  private composer: Composer = null

  private scene: THREE.Scene = null

  private threeContainerElement: HTMLElement = null

  private camera: THREE.PerspectiveCamera = null

  private ohpenShader: ShaderPass = null

  private threeMaskCanvas: ThreeMaskCanvas = null

  private canvasMaskTexture: THREE.CanvasTexture = null

  private currentPath: string = null

  private renderQuality = 4

  private renderQualityMax = 4

  private renderQualityTimeout = 4

  private renderQualityTimeoutStart = 2

  private renderCountDown = 2

  private renderCountDownStart = 0

  private tickerCountDown = 0

  private tickerCountDownStart = 0

  private pixelRatio = 1

  private pixelRatioOffset = 0.6

  private newPixelRatioOffset = 0.6

  private lowResVideo = false

  private viewMode = 'desktop'

  private playingMediaSrc = ''

  private playingMediaFocusPoint = new THREE.Vector2()

  private currentSlide = 1

  private sceneSwitchTime = 0

  private sceneSwitchBlockTime = 0.4

  private backgroundMediaRatio = new THREE.Vector2(16, 9)

  // amount of polys used for the main geometry
  private maxPolys = 3040

  private firstPageLoad = true
  private pageOutComplete = false
  private pageReadyComplete = false

  // saves the mouse position in targetX and targetY, then LERPs the x and y towards them in the onUpdate method
  private mouseProps = {
    x: 0.5,
    y: 0.5,
    targetX: 0.5,
    targetY: 0.5,
  }

  // browser checks
  private isIE = !!window.navigator.userAgent.match(/MSIE|Trident/)
  private safariBrowser = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent,
  )

  // all props needed to render the colored blocks in the CustomOhpenShader
  private colorBlockProps = {
    pos: 0,
    x: 0,
    y: 0,
    x2: 0,
    y2: 0,
    sx: 0,
    sy: 0,
    sx2: 0,
    sy2: 0,
    ex: 0,
    ey: 0,
    ex2: 0,
    ey2: 0,
    inverted: 0,
  }

  public onInit = (data: Record<string, unknown>): void => {
    this.assetStorageArray = []
    this.moduleData = data.sourcedData
    // Check which WebGL versions are available
    this.webGLVersions = CheckWebGLSupport()
    // moduleData = data.sourcedData.moduleData
    this.setupWorld(data)

    // setup the scene based on current route
    this.onRouteChange(getSanitizedLocation(data.location.pathname))
  }

  // special vector3 that has extra props to calculate their movement per vertex
  private boidVector3 = (children): THREE.Vector3 => {
    const boidVector = new THREE.Vector3()
    boidVector.currentPos = new THREE.Vector3()
    boidVector.speed = new THREE.Vector3()
    boidVector.freePos = new THREE.Vector3()
    boidVector.lockedPos = new THREE.Vector3()
    boidVector.speedOffset = 1 + Math.random() * 1
    boidVector.lockedFactor = 1
    boidVector.lockedFactorTarget = 1
    boidVector.hasLockedPos = false
    boidVector.children = children
    return boidVector
  }

  public setupWorld = (data): void => {
    // setup canvas based on WebGL version available
    const canvas = document.createElement('canvas')
    const context = this.webGLVersions.gl2
      ? canvas.getContext('webgl2', {})
      : canvas.getContext('webgl', {})

    this.threeContainerElement = data.threeContainerElement as HTMLElement
    this.threeContainerElement.appendChild(canvas)
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      context,
      autoClear: false,
    })
    this.scene = new THREE.Scene()

    // setup fog, this will also be used for the depth render cycle
    const fog = new THREE.Fog(0x151515, 0, 25)
    this.scene.fog = fog
    this.scene.background = new THREE.Color(0x151515)

    this.camera = new THREE.PerspectiveCamera(
      35,
      this.threeContainerElement.offsetWidth /
        this.threeContainerElement.offsetHeight,
      0.1,
      1000,
    )
    this.scene.add(this.camera)

    // add refference of the container in the scene to be used to add DOM objects in certain situations
    this.scene.threeContainerElement = this.threeContainerElement

    // the model holder will hold extra 3D models in certain situations
    this.scene.modelHolder = new THREE.Object3D()
    this.scene.modelHolder.position.x = this.scene.modelHolder.position.y = this.scene.modelHolder.position.z = 5000
    this.scene.modelHolder.frustumCulled = false
    this.scene.add(this.scene.modelHolder)

    /* setup the render targets (based on WebGL version) for depth and color. There are 2 sets. On route change,
    the active targets will be swapped, so an image of the last scene can be used in the CustomOhpenShader */
    const renderTargetOptions = {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      stencilBuffer: false,
    }

    if (this.webGLVersions.gl2) {
      this.renderTargetDepth = new THREE.WebGLMultisampleRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
      this.renderTargetColor = new THREE.WebGLMultisampleRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
      this.renderTargetDepth2 = new THREE.WebGLMultisampleRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
      this.renderTargetColor2 = new THREE.WebGLMultisampleRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
      this.renderTargetDepth.samples = 2
      this.renderTargetColor.samples = 2
      this.renderTargetDepth2.samples = 2
      this.renderTargetColor2.samples = 2
    } else {
      this.renderTargetDepth = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
      this.renderTargetColor = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
      this.renderTargetDepth2 = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
      this.renderTargetColor2 = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        renderTargetOptions,
      )
    }

    // set active render targets
    this.activeRenderTargetDepth = this.renderTargetDepth
    this.activeRenderTargetColor = this.renderTargetColor
    this.oldRenderTargetDepth = this.renderTargetDepth2
    this.oldRenderTargetColor = this.renderTargetColor2

    /* setup the 2D canvas that renders the color data, that will be used by the CustomOhpenShader as data
    to what needs to be masked and distorted */
    this.threeMaskCanvas = new ThreeMaskCanvas()
    this.threeMaskCanvas.renderMask()
    this.canvasMaskTexture = new THREE.CanvasTexture(
      this.threeMaskCanvas.canvas,
    )
    this.canvasMaskTexture.minFilter = THREE.LinearFilter
    this.canvasMaskTexture.magFilter = THREE.LinearFilter

    // setup the CustomOhpenShader
    this.ohpenShader = new ShaderPass(OhpenShaderCustom)

    // there are 2 render modes, one with depth of field effect, and one without.
    // These will switch based on the route and render quality specified
    this.ohpenShader.fragmentShader = OhpenShaderCustom.fragmentShaderConstructor(
      true,
    )
    this.ohpenShader.lowFragmentShader = OhpenShaderCustom.fragmentShaderConstructor(
      false,
    )

    // set defaults for al the uniforms used by the shader
    this.ohpenShader.uniforms['focus'].value = this.ohpenShader.uniforms[
      'focus2'
    ].value = 0.4
    this.ohpenShader.uniforms['focusRange'].value = this.ohpenShader.uniforms[
      'focusRange2'
    ].value = 0.1
    this.ohpenShader.uniforms['aperture'].value = this.ohpenShader.uniforms[
      'aperture2'
    ].value = 15
    this.ohpenShader.uniforms['maxblur'].value = this.ohpenShader.uniforms[
      'maxblur2'
    ].value = 0.0
    this.ohpenShader.uniforms['aspect'].value =
      window.innerWidth / window.innerHeight
    this.ohpenShader.uniforms[
      'tDepth'
    ].value = this.activeRenderTargetDepth.texture
    this.ohpenShader.uniforms[
      'tColor'
    ].value = this.activeRenderTargetColor.texture
    this.ohpenShader.uniforms[
      'tDepth2'
    ].value = this.oldRenderTargetDepth.texture
    this.ohpenShader.uniforms[
      'tColor2'
    ].value = this.oldRenderTargetColor.texture
    this.ohpenShader.uniforms['width'].value = Math.round(window.innerWidth)
    this.ohpenShader.uniforms['height'].value = Math.round(window.innerHeight)
    this.ohpenShader.uniforms['tMask'].value = this.canvasMaskTexture.canvas
    this.ohpenShader.uniforms[
      'sinCosTexture'
    ].value = this.createSinCosDataTexture()
    this.ohpenShader.uniforms['time'].value = 0
    this.ohpenShader.uniforms['blockInCurrent'].value = 1

    // setup the effect composer, that makes the CustomOhpenShader work as post process effect
    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(this.ohpenShader)

    // setting up scene lighting
    const dirLight = new THREE.DirectionalLight(0xffffff, 2)
    dirLight.position.set(3, 2, 2)
    this.scene.add(dirLight)

    const dirLight2 = new THREE.DirectionalLight(0xffffff, 1)
    dirLight2.position.set(-2, -1, 0)
    this.scene.add(dirLight2)

    // this is the main opague material
    this.glossMaterial = new THREE.MeshPhongMaterial({
      color: 0x050505,
      emissive: 0x151515,
      shininess: 15,
      side: 2,
      transparent: true,
      flatShading: true,
    })

    // this is the material used to render the depth on opague objects
    this.glossDepthMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      flatShading: true,
    })

    // add code to the vertexShader so that we can adjust the position of the vertices so the wireframe lines wont glitch trough the polygons
    this.glossMaterial.onBeforeCompile = (shader) => {
      shader.uniforms.normalOffset = { value: new THREE.Vector3(0, 0, 0) }
      shader.vertexShader = 'uniform vec3 normalOffset;\n' + shader.vertexShader
      const token = '#include <begin_vertex>'
      const customTransform = `
          vec3 transformed = vec3(position);
          //transformed -= normalize(transformedNormal ) * normalOffset;
          transformed -= normalOffset;
          `
      shader.vertexShader = shader.vertexShader.replace(token, customTransform)
      this.glossMaterial.userData.shader = shader
    }

    // the wireframe material
    this.wireFrameMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      opacity: 1,
      transparent: true,
      wireframe: true,
    })

    // the point material
    this.pointMaterial = OhpenPointMaterial

    // setup the main geometry
    this.polyGeometry = new THREE.PlaneGeometry(1, 1, 1, 1)
    this.polyGeometry.vertices = []
    this.polyGeometry.faces = []
    this.polyGeometry.faceVertexUvs[0] = []
    let vec, vec2, vec3
    for (let i = 0; i < this.maxPolys; i++) {
      // add first 2 vectors to last as children, so they stay together as a polygon when floating around
      vec = this.boidVector3()
      vec2 = this.boidVector3()
      vec3 = this.boidVector3([vec, vec2])

      // set default offset for the other 2 verts to create a polygon
      vec2.x = 1
      vec3.z = 1
      vec3.y = 1

      this.polyGeometry.vertices.push(vec)
      this.polyGeometry.vertices.push(vec2)
      this.polyGeometry.vertices.push(vec3)
      const vertLength = this.polyGeometry.vertices.length
      this.polyGeometry.faces.push(
        new THREE.Face3(vertLength - 3, vertLength - 2, vertLength - 1),
      )
    }

    // create the different meshes for the opague polys, wireframe and point meshes.
    this.polyParticleMesh = new THREE.Mesh(
      this.polyGeometry,
      this.glossMaterial,
    )
    this.wireParticleMesh = new THREE.Mesh(
      this.polyGeometry,
      this.wireFrameMaterial,
    )
    this.pointParticleMesh = new THREE.Points(
      this.polyGeometry,
      this.pointMaterial,
    )

    // Turn frustemCulling off to prevent the object from dissapearing,
    // since the bounding box isn't calculated per frame to increase performance.
    // The visibility is managed manually per route scene
    this.polyParticleMesh.frustumCulled = false
    this.wireParticleMesh.frustumCulled = false
    this.pointParticleMesh.frustumCulled = false

    this.scene.add(this.polyParticleMesh)
    this.scene.add(this.wireParticleMesh)
    this.scene.add(this.pointParticleMesh)

    // setup smaller particle system that animates every time a route changes
    const vertices = []
    const speed = []

    let x, y, z

    for (let i = 0; i < 150; i++) {
      x = THREE.MathUtils.randFloatSpread(5)
      y = THREE.MathUtils.randFloatSpread(5)
      z = -(Math.random() * 20 + 6)
      vertices.push(x, y, z)
      speed.push(0, 0, 0)
    }

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3),
    )
    geometry.setAttribute('speed', new THREE.Float32BufferAttribute(speed, 3))

    const material = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 2.5,
      alphaTest: 0.2,
      sizeAttenuation: false,
      opacity: 1,
      transparent: true,
      alphaMap: this.pointMaterial.alphaMap,
    })

    this.particlesMesh = new THREE.Points(geometry, material)
    this.camera.add(this.particlesMesh)

    // setup the video object to be used in the mediaTexture
    this.videoHTMLElement = document.createElement('video')
    this.videoHTMLElement.setAttribute('width', '640')
    this.videoHTMLElement.setAttribute('height', '360')
    this.videoHTMLElement.setAttribute('crossorigin', 'anonymous')
    this.videoHTMLElement.setAttribute('muted', 'true')
    this.videoHTMLElement.setAttribute('playsinline', 'true')
    this.videoHTMLElement.setAttribute('webkit-playsinline', 'true')
    this.videoHTMLElement.setAttribute('loop', 'true')
    this.videoHTMLElement.setAttribute('autoplay', 'true')
    this.videoHTMLElement.setAttribute('preload', 'false')
    this.videoHTMLElement.autoplay = true
    this.videoHTMLElement.muted = true
    this.videoHTMLElement.playsinline = true
    this.videoHTMLElement.preload = false
    this.videoHTMLElement.width = 640 / 2
    this.videoHTMLElement.height = 360 / 2
    this.videoHTMLElement.controls = false
    this.videoHTMLElement.loop = true
    this.videoHTMLElement.style.position = 'fixed'
    this.videoHTMLElement.style.top = '0px'
    this.videoHTMLElement.style.left = '0px'
    this.videoHTMLElement.style.visibility = 'hidden'
    this.videoHTMLElement.style.display = 'none'

    // add to body so browsers accept it as an actual video playing
    document.body.appendChild(this.videoHTMLElement)

    // setup media screen mesh and texture for displaying videos and images on the background
    const dummyCanvas = document.createElement('canvas')
    dummyCanvas.width = dummyCanvas.height = 32
    dummyCanvas.getContext('2d').fillStyle = '#000000'
    dummyCanvas
      .getContext('2d')
      .fillRect(0, 0, dummyCanvas.width, dummyCanvas.height)
    this.screenDummyTexture = new THREE.CanvasTexture(dummyCanvas)

    this.backgroundDummyMaterial = new THREE.MeshBasicMaterial({
      map: this.screenDummyTexture,
      transparent: true,
      opacity: 1,
    })

    this.backgroundImageTexture = new THREE.Texture(this.imageElement)
    this.backgroundImageTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy()
    this.backgroundImageTexture.minFilter = THREE.LinearFilter
    this.backgroundImageTexture.maxFilter = THREE.LinearFilter

    this.backgroundImageMaterial = new THREE.MeshBasicMaterial({
      map: this.backgroundImageTexture,
      transparent: true,
      opacity: 0.5,
    })

    this.backgroundVideoTexture = new THREE.Texture(this.videoHTMLElement)
    this.backgroundVideoTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy()
    this.backgroundVideoTexture.minFilter = THREE.LinearFilter
    this.backgroundVideoTexture.maxFilter = THREE.LinearFilter

    this.backgroundVideoMaterial = new THREE.MeshBasicMaterial({
      map: this.backgroundVideoTexture,
      transparent: true,
      opacity: 0,
    })

    const planeGeom = new THREE.PlaneGeometry(3.16, 3.16, 2, 2)

    this.mediaScreenPlane = new THREE.Mesh(
      planeGeom,
      this.backgroundDummyMaterial,
    )
    this.mediaScreenPlane.visible = false
    this.mediaScreenPlane.position.z = -5
    this.mediaScreenPlane.position.zScale = 1
    this.camera.add(this.mediaScreenPlane)

    // setup the image element that will load the background images to be displayed in in the background mediaTexture
    this.imageElement = new Image()
    this.imageElement.crossOrigin = 'anonymous'

    this.imageElement.onload = (e) => {
      delete this.backgroundImageTexture

      this.backgroundMediaRatio.x =
        this.imageElement.naturalWidth / this.imageElement.naturalHeight
      this.backgroundMediaRatio.y = 1

      console.log('image ratio', this.backgroundMediaRatio)

      this.backgroundImageTexture = new THREE.Texture(this.imageElement)
      this.backgroundImageTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy()
      this.backgroundImageTexture.minFilter = THREE.LinearFilter
      this.backgroundImageTexture.maxFilter = THREE.LinearFilter

      this.backgroundImageTexture.needsUpdate = true
      this.backgroundImageMaterial.map = this.backgroundImageTexture
      this.mediaScreenPlane.material = this.backgroundImageMaterial
      this.mediaScreenPlane.visible = true
      TweenMax.to(this.backgroundImageMaterial, 0.5, {
        opacity: 1,
      })
    }

    // when video has readyState 3, add the video to the texture map, and start playing
    this.videoHTMLElement.addEventListener('loadeddata', (e) => {
      delete this.backgroundVideoTexture

      this.backgroundMediaRatio.x =
        this.videoHTMLElement.videoWidth / this.videoHTMLElement.videoHeight
      this.backgroundMediaRatio.y = 1

      console.log('video ratio', this.backgroundMediaRatio)

      this.backgroundVideoTexture = new THREE.VideoTexture(
        this.videoHTMLElement,
      )
      this.backgroundVideoTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy()
      this.backgroundVideoTexture.minFilter = THREE.LinearFilter
      this.backgroundVideoTexture.maxFilter = THREE.LinearFilter

      this.backgroundVideoMaterial.map = this.backgroundVideoTexture
      this.mediaScreenPlane.material = this.backgroundVideoMaterial
      this.mediaScreenPlane.visible = true
      this.videoHTMLElement.play()
      TweenMax.to(this.backgroundVideoMaterial, 0.5, {
        opacity: 1,
      })
    })

    // do an extra resize to make sure all render targets and canvases are the correct size before the first render
    this.onResize({
      aspect:
        this.threeContainerElement.offsetWidth /
        this.threeContainerElement.offsetHeight,
      height: this.threeContainerElement.offsetHeight,
      width: this.threeContainerElement.offsetWidth,
    })

    // setup alle the PubSub subscribes
    PubSub.subscribe(APP_ROUTE_UPDATE, this.onRouteUpdate)
    PubSub.subscribe(APP_PAGE_MOBILE_SLIDE_UPDATE, this.onMobileSlideUpdate)
    PubSub.subscribe(APP_VIEW_UPDATE, this.onViewUpdate)
    PubSub.subscribe(APP_MENU_TOGGLE, this.onMenuToggle)
    PubSub.subscribe(
      APP_PAGE_ANIMATION_COMPLETED,
      this.onPageOutAnimationComplete,
    )
    PubSub.subscribe(APP_PARTNER_SELECT, this.onPartnerSelect)
    PubSub.subscribe(APP_SWITCH_BACKGROUND_MEDIA, this.onSwitchBackgroundMedia)
    PubSub.subscribe(APP_SWITCH_BACKGROUND_THREE_SCENE, this.onSwitchScene)
    PubSub.subscribe(APP_ADD_THREE_CUSTOM_SCENE, this.onAddCustomScene)

    // trigger the delayed preloader for the animated 3D models
    TweenMax.delayedCall(2, () => {
      this.preloadModels()
    })

    this.stats = new Stats()
    //this.setupDebug()
    //this.activateDebug()
  }

  // preload the 3D animated models, unless the route for that model is already enabled
  public preloadModels = (): void => {
    if (this.currentPath != '/home/compliant') {
      if (this.assetStorageArray['treebranch'] === undefined) {
        //console.log("preload tree model")
        this.assetStorageArray['treebranch'] = { loaded: false }
        const loader = new GLTFLoader()
        loader.load('/models3d/branch.gltf', (gltf) => {
          this.assetStorageArray['treebranch'].loaded = true
          this.assetStorageArray['treebranch'].model = gltf
          this.assetStorageArray['treebranch'].model.scene.name =
            'treebranch-model'
        })
      }
    }

    if (this.currentPath != '/our-platform/legacy') {
      if (this.assetStorageArray['cheetah'] === undefined) {
        this.assetStorageArray['cheetah'] = { loaded: false }
        const loader2 = new GLTFLoader()
        loader2.load('/models3d/cheetah_animation.gltf', (gltf) => {
          this.assetStorageArray['cheetah'].loaded = true
          this.assetStorageArray['cheetah'].model = gltf
          this.assetStorageArray['cheetah'].model.scene.name = 'cheetah-model'
        })
      }
    }
  }

  public onSwitchBackgroundMedia = (name: string, data: any): void => {
    if (data != undefined) {
      if (data.src != undefined) {
        this.switchBackgroundMedia(data.src, data.focusPoint)
      }
    }
  }

  public onSwitchScene = (name: string, data: any): void => {
    if (data.name !== undefined) {
      this.changeSceneData(data, {
        route: this.currentPath,
      })
    } else if (data.sceneName !== undefined) {
      this.changeSceneData(ScenesData[data.sceneName], {
        route: this.currentPath,
      })
    }
  }

  public onAddCustomScene = (name: string, data: any): void => {
    if (data != undefined) {
      if (data.customScene != undefined) {
        if (data.customScene.mediaSettings === undefined) {
          data.customScene.mediaSettings = {
            mediaHTMLElement: '',
          }
        }
        if (data.customScene.backgroundColor === undefined) {
          data.customScene.backgroundColor = new THREE.Color(0x191919)
        }
        if (data.customScene.colorBlockSettings === undefined) {
          data.customScene.colorBlockSettings = {
            desktopType: 'none',
            slideAnimations: ['none', 'none'],
            slideAnimationsMobile: ['none', 'none'],
            blockColor1: { r: 0, g: 0, b: 0 },
            blockColor2: { r: 0, g: 0, b: 0 },
          }
        }

        ScenesData[data.customScene.name] = data.customScene

        this.changeSceneData(ScenesData[data.customScene.name], {
          route: this.currentPath,
        })
      }
    }
  }

  // listen to the React page out ready, and check if the THREE scene is also ready,
  // if so, send "APP_THREE_PAGE_READY" event
  public onPageOutAnimationComplete = (name: string, data: any): void => {
    this.pageOutComplete = true
    TweenMax.killTweensOf(this.sendThemeChangeEvent)
    TweenMax.delayedCall(0.3, this.sendThemeChangeEvent)
    this.checkPageReadyAndPageOutReady()
  }

  // if partner select event is published, trigger the partnerSelect method in the THREE scene for partners
  public onPartnerSelect = (name: string, data: any): void => {
    if (this.currentSceneData.tempData != undefined) {
      if (this.currentSceneData.tempData.partnerSelect != undefined) {
        this.currentSceneData.tempData.partnerSelect(data)
      }
    }
  }

  public onMenuToggle = (name: string, data: any): void => {
    let themeColor = this.currentSceneData.theme
      ? this.currentSceneData.theme
      : 'light'
    if (data === true) {
      themeColor = 'dark'
    }
    PubSub.publish(APP_PAGE_CHANGE_THEME, {
      theme: themeColor,
    })
  }

  // setup and prep the debug menu, the stats module is used to check the avarage FPS for use with the render quality manager
  public setupDebug = (): void => {
    // setup stats style
    this.stats.dom.style.position = 'fixed'
    this.stats.dom.style.opacity = 0.5
    this.stats.dom.style.visibility = 'hidden'
    this.stats.dom.style.display = 'none'
    this.stats.dom.style.height = '50px'
    this.stats.dom.style.width = '80px'
    this.stats.dom.style.left = '0px'
    this.stats.dom.style.top = '0px'

    // show specific render data
    this.fpsShowDiv = document.createElement('div')
    this.fpsShowDiv.style.position = 'fixed'
    this.fpsShowDiv.style.display = 'none'
    this.fpsShowDiv.style.fontSize = '10px'
    this.fpsShowDiv.style.height = '100px'
    this.fpsShowDiv.style.width = '80px'
    this.fpsShowDiv.style.left = '0px'
    this.fpsShowDiv.style.top = '50px'
    this.fpsShowDiv.style.backgroundColor = 'rgba(0,0,0,0.7)'
    this.fpsShowDiv.style.color = '#00ff00'
    this.fpsShowDiv.style.padding = '0px 4px'
    this.fpsShowDiv.style.opacity = 0.5
    this.fpsShowDiv.style.zIndex = 9999999
    this.fpsShowDiv.style.visibility = 'hidden'

    this.dataShowDiv = document.createElement('div')
    this.dataShowDiv.style.position = 'fixed'
    this.dataShowDiv.style.display = 'none'
    this.dataShowDiv.style.fontSize = '10px'
    this.dataShowDiv.style.height = 'auto'
    this.dataShowDiv.style.width = 'auto'
    this.dataShowDiv.style.left = '0px'
    this.dataShowDiv.style.top = '150px'
    this.dataShowDiv.style.backgroundColor = 'rgba(0,0,0,0.7)'
    this.dataShowDiv.style.color = '#00ff00'
    this.dataShowDiv.style.padding = '0px 4px'
    this.dataShowDiv.style.opacity = 0.5
    this.dataShowDiv.style.zIndex = 9999999
    this.dataShowDiv.style.visibility = 'hidden'

    // setup the secret key code to activate the debug menu
    const allowedKeys = {
      37: 'left',
      38: 'up',
      39: 'right',
      40: 'down',
      65: 'a',
      66: 'b',
    }

    // the 'official' Konami Code sequence
    //const konamiCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];
    const konamiCode = ['up', 'up', 'down', 'down']
    let konamiCodePosition = 0

    document.addEventListener('keydown', (e) => {
      // hard set render quality level
      if (e.keyCode >= 49 && e.keyCode < 58) {
        const newQuality = e.keyCode - 49

        if (this.renderQuality != newQuality) {
          this.renderQuality = newQuality
          this.onResize({
            aspect:
              this.threeContainerElement.offsetWidth /
              this.threeContainerElement.offsetHeight,
            height: this.threeContainerElement.offsetHeight,
            width: this.threeContainerElement.offsetWidth,
          })
          if (this.renderQuality < 2) {
            this.switchBackgroundMedia(
              this.currentSceneData.mediaSettings.mediaHTMLElement,
              this.currentSceneData.mediaSettings.position,
              this.currentSceneData.mediaSettings.crop,
            )
          }
        }
      }

      // konami code

      const key = allowedKeys[e.keyCode]
      const requiredKey = konamiCode[konamiCodePosition]
      if (key == requiredKey) {
        konamiCodePosition++

        if (konamiCodePosition == konamiCode.length) {
          activateCheats()
          konamiCodePosition = 0
        }
      } else {
        konamiCodePosition = 0
      }
    })

    const activateCheats = () => {
      this.activateDebug()
    }
  }

  private activateDebug = () => {
    this.fpsShowDiv.style.visibility = 'visible'
    this.fpsShowDiv.style.display = 'block'
    document.body.appendChild(this.fpsShowDiv)

    this.dataShowDiv.style.visibility = 'visible'
    this.dataShowDiv.style.display = 'block'
    document.body.appendChild(this.dataShowDiv)

    this.stats.dom.style.visibility = 'visible'
    this.stats.dom.style.display = 'block'
    document.body.appendChild(this.stats.dom)
  }

  // create a texture that prebakes the values of sin and cos to a texture used by the CustomOhpenShader
  private createSinCosDataTexture = (): THREE.Texture => {
    const canvas = document.createElement('canvas')
    const detailLevel = 64
    canvas.width = canvas.height = detailLevel
    const ctx = canvas.getContext('2d')
    let sin, cos
    for (let i = 0; i < detailLevel; i++) {
      sin = Math.round(
        (Math.sin((i / detailLevel) * Math.PI * 2) / 2 + 0.5) * 255,
      )
      cos = Math.round(
        (Math.cos((i / detailLevel) * Math.PI * 2) / 2 + 0.5) * 255,
      )
      ctx.fillStyle = 'rgb(' + sin + ', ' + cos + ', 0)'
      ctx.fillRect(i, 0, i + 1, detailLevel)
    }

    const sinCosTexture = new THREE.CanvasTexture(canvas)
    sinCosTexture.minFilter = THREE.LinearFilter
    sinCosTexture.magFilter = THREE.LinearFilter

    return sinCosTexture
  }

  public onMobileSlideUpdate = (name: string, data: any): void => {
    // updates the slide number for mobile, and activates the animation
    this.currentSlide = data
    if (this.currentSlide != 0) {
      const slideReadyDelay =
        this.newSceneData.slideReadyDelay != undefined
          ? this.newSceneData.slideReadyDelay
          : 1
      TweenMax.killTweensOf(this.sendPageReadyEvent)
      TweenMax.delayedCall(slideReadyDelay, this.sendPageReadyEvent)
      this.animateInColorBlock(0)
    }
  }

  // change the viewmode to desktop or mobile
  public onViewUpdate = (name: string, data: any): void => {
    let newViewMode = 'desktop'
    if (data === 'mobile') newViewMode = 'mobile'
    if (this.viewMode != newViewMode) {
      this.viewMode = newViewMode
      this.animateInColorBlock()
      if (this.currentSceneData) {
        this.currentSceneData.viewMode = this.viewMode
      }
    }
  }

  // changes the data for the scene. Cleans up the old one if needed, and starts the new one
  public changeSceneData = (sceneData, options): void => {
    if (options === undefined) options = {}
    sceneData.route = options.route != undefined ? options.route : ''

    // share prev and next route with the new and current scene for propper in and out sequences
    if (this.currentSceneData != undefined) {
      sceneData.prevRoute = this.currentSceneData.route
      this.currentSceneData.nextRoute = sceneData.route
    }

    this.saveOldSceneShaderData()

    // set the new scene for refference
    this.newSceneData = sceneData

    // check if there is an scene currently active
    if (this.currentSceneData) {
      const sceneSwitchTimeDiff = Math.round(
        performance.now() - this.sceneSwitchTime,
      )
      // check if the scene is different
      if (this.currentSceneData.name != this.newSceneData.name) {
        if (
          sceneSwitchTimeDiff > this.sceneSwitchBlockTime * 1000 ||
          this.currentSceneData.name === 'default'
        ) {
          //console.log("THREE > sceneSwitchTimeDiff: ", sceneSwitchTimeDiff);
          // check if the current scene has a cleanup function, if so, run it
          if (this.currentSceneData.cleanupScene != undefined) {
            this.currentSceneData.cleanupScene(
              this.currentSceneData,
              this.scene,
              this.polyParticleMesh,
              this.wireParticleMesh,
              this.pointParticleMesh,
              this.assetStorageArray,
              () => {
                this.setNewSceneSceneData()
                this.setupNewSlideEvents()
              },
            )
          } else {
            // no cleanup needed, so switch to the new one
            this.setNewSceneSceneData()
            this.setupNewSlideEvents()
          }
        } else {
          //console.log("THREE > scene switch blocked because inbetween scene switch time is: ", sceneSwitchTimeDiff, this.sceneSwitchBlockTime * 1000);
        }
      } else {
        // same THREE scene, so no change is needed, but we do need to publish the APP_THREE_PAGE_READY
        this.setupNewSlideEvents()
      }
    } else {
      // no current scene, so switch to the new one
      this.setNewSceneSceneData()
      this.setupNewSlideEvents()
    }
  }

  // this function sends page ready event when the THREE scene is done animating
  private setupNewSlideEvents = (): void => {
    if (this.firstPageLoad) {
      this.pageOutComplete = true
      this.firstPageLoad = false
    } else {
      this.pageOutComplete = false
    }

    this.pageReadyComplete = false

    const pageReadyDelay =
      this.newSceneData.pageReadyDelay != undefined
        ? this.newSceneData.pageReadyDelay
        : 0.65

    TweenMax.killTweensOf(this.sendPageReadyEvent)
    TweenMax.delayedCall(pageReadyDelay, this.sendPageReadyEvent)
  }

  // this will set new scene data, and load up all the media, setup the scene, and start the transition animation
  public setNewSceneSceneData = (): void => {
    this.sceneSwitchTime = Math.round(performance.now())

    this.mediaScreenPlane.material.opacity = 0
    this.mediaScreenPlane.visible = false
    this.polyParticleMesh.visible = this.wireParticleMesh.visible = this.pointParticleMesh.visible = false

    //console.log('THREE > sceneSwitchTime',this.sceneSwitchTime);

    this.oldSceneData = this.currentSceneData
    this.currentSceneData = this.newSceneData

    this.currentSceneData.viewMode = this.viewMode

    if (this.currentSceneData.startupScene != undefined) {
      this.currentSceneData.startupScene(
        this.currentSceneData,
        this.scene,
        this.polyParticleMesh,
        this.wireParticleMesh,
        this.pointParticleMesh,
        this.assetStorageArray,
        this.moduleData,
      )
    }

    this.switchBackgroundMedia(
      this.currentSceneData.mediaSettings.mediaHTMLElement,
      this.currentSceneData.mediaSettings.position,
      this.currentSceneData.mediaSettings.crop,
    )

    this.updateSceneData()
    this.transition()
  }
  public saveOldSceneShaderData = (): void => {
    // save old scene shader data
    const uniforms = this.ohpenShader.uniforms

    uniforms['focus2'].value = uniforms['focus'].value
    uniforms['focusRange2'].value = uniforms['focusRange'].value
    uniforms['aperture2'].value = uniforms['aperture'].value
    uniforms['maxblur2'].value = uniforms['maxblur'].value

    uniforms['blockTLPos2'].value = uniforms['blockTLPos'].value
    uniforms['blockBRPos2'].value = uniforms['blockBRPos'].value

    uniforms['blockColorLeft2'].value = uniforms['blockColorLeft'].value
    uniforms['blockColorRight2'].value = uniforms['blockColorRight'].value

    uniforms['colorBlockOpacity2'].value = uniforms['colorBlockOpacity'].value
    uniforms['blockMaskInvered2'].value = uniforms['blockMaskInvered'].value
  }

  public animationComplete = true

  // changes the background media for the scene
  public switchBackgroundMedia = (
    currentHTMLMediaElement: string,
    focusPoint: THREE.Vector2,
  ): void => {
    // choose lower res video for lower end devices and browsers
    if (this.renderQuality < 2 || this.safariBrowser || this.lowResVideo) {
      currentHTMLMediaElement = currentHTMLMediaElement.replace(
        '.mp4',
        '_720.mp4',
      )
    }

    // check if the new media or focuspoint is different
    if (
      this.playingMediaSrc != currentHTMLMediaElement ||
      this.playingMediaFocusPoint != focusPoint
    ) {
      this.playingMediaSrc = currentHTMLMediaElement
      this.playingMediaFocusPoint = focusPoint

      this.currentSceneData.mediaSettings.position = focusPoint
      this.videoHTMLElement.pause()
      this.videoHTMLElement.innerHTML = ''
      this.videoHTMLElement.load()

      delete this.backgroundVideoTexture
      delete this.backgroundImageTexture

      if (this.dataShowDiv) {
        this.dataShowDiv.innerHTML = ''
      }
      TweenMax.killTweensOf(this.mediaScreenPlane.material)
      TweenMax.killTweensOf(this.mediaScreenPlane.position)
      this.mediaScreenPlane.position.x = 0.25
      this.mediaScreenPlane.position.y = 0
      this.mediaScreenPlane.position.z = -5
      this.mediaScreenPlane.material.opacity = 0
      this.mediaScreenPlane.visible = false

      // if scene has an actual source path, load the type of media
      if (currentHTMLMediaElement.length > 1) {
        if (
          currentHTMLMediaElement.toLowerCase().indexOf('.jpg') > 0 ||
          currentHTMLMediaElement.toLowerCase().indexOf('.png') > 0
        ) {
          this.imageElement.src = currentHTMLMediaElement

          TweenMax.set(this.mediaScreenPlane.position, {
            zScale: 1.2,
          })

          TweenMax.to(this.mediaScreenPlane.position, 12, {
            zScale: 1.15,
            yoyo: true,
            repeat: -1,
            ease: Power1.easeInOut,
          })
        } else if (
          currentHTMLMediaElement.toLowerCase().indexOf('.mp4') > 0 ||
          currentHTMLMediaElement.toLowerCase().indexOf('.webm') > 0
        ) {
          // show poster image if video does not load on safari
          //this.imageElement.src = currentHTMLMediaElement.replace('.mp4', '_poster.jpg');

          TweenMax.set(this.mediaScreenPlane.position, { zScale: 1 })

          let showVideo = true
          //if (!this.firstInteraction && this.safariBrowser) showVideo = false

          if (showVideo) {
            const source = document.createElement('source')
            source.setAttribute('src', currentHTMLMediaElement)
            this.videoHTMLElement.appendChild(source)

            this.videoHTMLElement.load()
            this.videoHTMLElement.muted = true
          }
        } else {
          // hide media texture plane
          this.mediaScreenPlane.material.map = this.screenDummyTexture
          this.mediaScreenPlane.visible = false
        }
      } else {
        // hide media texture plane
        this.mediaScreenPlane.material.map = this.screenDummyTexture
        this.mediaScreenPlane.visible = false
      }
    }
  }

  // send theme change event
  public sendThemeChangeEvent = (): void => {
    // set theme color
    const themeColor = this.newSceneData.theme
      ? this.newSceneData.theme
      : 'light'
    PubSub.publish(APP_PAGE_CHANGE_THEME, {
      theme: themeColor,
    })
  }

  public sendPageReadyEvent = (): void => {
    this.pageReadyComplete = true
    this.checkPageReadyAndPageOutReady()
  }

  // this checks if both the THREE scene is ready, and the React page in is ready, then publishes APP_THREE_PAGE_READY
  public checkPageReadyAndPageOutReady = (): void => {
    if (this.pageOutComplete && this.pageReadyComplete) {
      PubSub.publish(APP_THREE_PAGE_READY, {})
      this.sendThemeChangeEvent()
    }
  }

  // this function collects all current scene data for this render frame, and sets all materials and shader values accordingly
  public updateSceneData = (deltaTime): void => {
    if (deltaTime === undefined) deltaTime = 0

    // set default camera position
    this.camera.position.x = this.camera.position.y = 0
    this.camera.position.z = 5
    this.camera.rotation.y = this.camera.position.z = 0
    this.camera.rotation.x = -0.2
    this.camera.fov = 35
    this.camera.near = 0.01
    this.camera.far = 1000

    // if camera props is set in scenedata, overwrite default
    if (this.currentSceneData.camera) {
      if (this.currentSceneData.camera.position) {
        this.camera.position.x = this.currentSceneData.camera.position.x
        this.camera.position.y = this.currentSceneData.camera.position.y
        this.camera.position.z = this.currentSceneData.camera.position.z
      }
      if (this.currentSceneData.camera.rotation) {
        this.camera.rotation.x = this.currentSceneData.camera.rotation.x
        this.camera.rotation.y = this.currentSceneData.camera.rotation.y
        this.camera.rotation.z = this.currentSceneData.camera.rotation.z
      }
      if (this.currentSceneData.camera.fov)
        this.camera.fov = this.currentSceneData.camera.fov
      if (this.currentSceneData.camera.near)
        this.camera.near = this.currentSceneData.camera.near
      if (this.currentSceneData.camera.far)
        this.camera.far = this.currentSceneData.camera.far
    }

    // update camera matrix
    this.camera.updateProjectionMatrix()

    // extra materials to be swapped back from the depthmap to the actual color map
    if (window.ohpenExtraMaterials != undefined) {
      for (let i = 0; i < window.ohpenExtraMaterials.length; i++) {
        window.ohpenExtraMaterials[i].map =
          window.ohpenExtraMaterials[i].backupColorMap
      }
    }

    // set poly material to correct color values from the depth render values, or if not excisiting, go for default settings
    if (this.currentSceneData.polyMaterialSettings != undefined) {
      this.glossMaterial.emissive = this.currentSceneData.polyMaterialSettings.emissive
      this.glossMaterial.color = this.currentSceneData.polyMaterialSettings.color
      this.glossMaterial.opacity =
        this.currentSceneData.polyMaterialSettings.alpha != undefined
          ? this.currentSceneData.polyMaterialSettings.alpha
          : 1
      this.glossMaterial.transparent = this.glossMaterial.opacity != 1

      if (this.glossMaterial.userData.shader != undefined) {
        this.glossMaterial.userData.shader.uniforms.normalOffset.value =
          this.currentSceneData.polyMaterialSettings.normalOffset != undefined
            ? this.currentSceneData.polyMaterialSettings.normalOffset
            : new THREE.Vector3(0.0, 0.0, 0.0)
      }
    } else {
      this.glossMaterial.color = new THREE.Color(0x050505)
      this.glossMaterial.emissive = new THREE.Color(0x151515)
      this.glossMaterial.opacity = 1
      if (this.glossMaterial.userData.shader != undefined) {
        this.glossMaterial.userData.shader.uniforms.normalOffset.value = 0.01
      }
    }

    // set wirewframe material back for color render
    if (this.currentSceneData.wireMaterialSettings != undefined) {
      this.wireFrameMaterial.color = this.currentSceneData.wireMaterialSettings.color
      this.wireFrameMaterial.opacity = this.currentSceneData.wireMaterialSettings.alpha
    } else {
      this.wireFrameMaterial.color = new THREE.Color(0xffffff)
      this.wireFrameMaterial.opacity = 1
    }

    // set point material back for color render
    if (this.currentSceneData.pointMaterialSettings != undefined) {
      this.pointMaterial.color = this.currentSceneData.pointMaterialSettings.color
      this.pointMaterial.size = this.currentSceneData.pointMaterialSettings.size
      this.pointMaterial.opacity = this.currentSceneData.pointMaterialSettings.alpha
      this.pointMaterial.sizeAttenuation =
        this.currentSceneData.pointMaterialSettings.sizeAttenuation != false
          ? true
          : false
    } else {
      this.pointMaterial.color = new THREE.Color(0xaaaaaa)
      this.pointMaterial.size = 0.01
      this.pointMaterial.opacity = 1
      this.pointMaterial.sizeAttenuation = true
    }

    // set fog values for color render
    if (this.currentSceneData.fog != undefined) {
      this.scene.fog.far =
        this.currentSceneData.fog.colorFar != undefined
          ? this.currentSceneData.fog.colorFar
          : 30
      this.scene.fog.near =
        this.currentSceneData.fog.colorNear != undefined
          ? this.currentSceneData.fog.colorNear
          : 0.01
    } else {
      this.scene.fog.far = 30
      this.scene.fog.near = 0.01
    }

    // set background color for color render
    if (this.currentSceneData.backgroundColor != undefined) {
      this.scene.background = this.scene.fog.color = this.currentSceneData.backgroundColor
    } else {
      this.scene.background = this.scene.fog.color = new THREE.Color(0x191919)
    }

    // position the media screen based on focus point
    const mediaSettings =
      this.currentSceneData.mediaSettings != undefined
        ? this.currentSceneData.mediaSettings
        : {}
    const focusPos =
      mediaSettings.position != undefined
        ? mediaSettings.position
        : new THREE.Vector2(0.5, 0.5)

    let mediaCrop =
      mediaSettings.crop != undefined
        ? mediaSettings.crop.toLowerCase()
        : 'full'

    if (this.viewMode === 'mobile') mediaCrop = 'full'

    // set mediaPlane scale based on the size of the renderer

    let trueWidth = this.ohpenShader.uniforms['width'].value
    let trueHeight = this.ohpenShader.uniforms['height'].value

    if (mediaCrop === 'left' || mediaCrop === 'right') {
      trueWidth *= 0.5
    }

    let screenRatio = trueWidth / trueHeight
    let mediaRatio = this.backgroundMediaRatio.x / this.backgroundMediaRatio.y

    let videoScale = 1
    if (mediaRatio / screenRatio < 1) {
      videoScale = screenRatio / mediaRatio
    }

    videoScale *= this.mediaScreenPlane.position.zScale

    this.mediaScreenPlane.scale.x = videoScale * this.backgroundMediaRatio.x
    this.mediaScreenPlane.scale.y = videoScale * this.backgroundMediaRatio.y
    this.mediaScreenPlane.scale.z = 1

    // this.mediaScreenPlane.scale.x *= 0.9
    // this.mediaScreenPlane.scale.y *= 0.9

    // TODO: set right center position for media

    const mediaScreenWidth = 3.16 * this.mediaScreenPlane.scale.x
    const mediaScreenHeight = 3.16 * this.mediaScreenPlane.scale.y

    // const mediaScreenWidth = (16 * 0.71 * this.mediaScreenPlane.scale.x) / 2
    // const mediaScreenHeight = (16 * 0.71 * this.mediaScreenPlane.scale.y) / 2

    const screenHeight = 3.16
    const screenWidth = (screenHeight / trueHeight) * trueWidth

    let xOffset = 0
    let yOffset = 0

    switch (mediaCrop) {
      case 'left':
        xOffset += screenWidth * 0.5
        break
      case 'right':
        xOffset -= screenWidth * 0.5
        break
    }

    xOffset += (screenWidth - mediaScreenWidth) * (0.5 - focusPos.x)
    yOffset += (screenHeight - mediaScreenHeight) * (0.5 - focusPos.y)

    // console.log(this.mediaScreenPlane)
    // console.log(this.imageElement)
    if (!this.backgroundImageTexture) {
      this.mediaScreenPlane.position.x = -xOffset
      this.mediaScreenPlane.position.y = -yOffset
    } else {
      TweenMax.to(this.mediaScreenPlane.position, 0.05, {
        x: -this.mouseProps.x / 7 + 0.25,
        y: this.mouseProps.y / 7 - 0.025,
      })
    }

    // set the shader values if available, else do fallback
    if (this.currentSceneData.ohpenShaderSettings != undefined) {
      this.ohpenShader.uniforms[
        'focus'
      ].value = this.currentSceneData.ohpenShaderSettings.focus
      this.ohpenShader.uniforms[
        'focusRange'
      ].value = this.currentSceneData.ohpenShaderSettings.focusRange
      this.ohpenShader.uniforms[
        'aperture'
      ].value = this.currentSceneData.ohpenShaderSettings.aperture
      this.ohpenShader.uniforms[
        'maxblur'
      ].value = this.currentSceneData.ohpenShaderSettings.maxblur
    } else {
      this.ohpenShader.uniforms['focus'].value = 0.4
      this.ohpenShader.uniforms['focusRange'].value = 0.3
      this.ohpenShader.uniforms['aperture'].value = 15
      this.ohpenShader.uniforms['maxblur'].value = 0
    }

    this.renderColorBlock()
  }

  // set the shader values for the current color block render based on scene data and animation position
  public renderColorBlock = (): void => {
    // check if we need color block settings from old scene or current scene for render
    let colorBlockSettings

    if (this.currentSceneData) {
      colorBlockSettings = this.currentSceneData.colorBlockSettings
    }

    // set THREE scene total opacity to default, and overwrite with the value for current slide if available in scene data
    let sceneAlphaTarget = 1

    if (colorBlockSettings) {
      let slideNr = this.currentSlide

      if (colorBlockSettings.sceneOpacity != undefined) {
        if (colorBlockSettings.sceneOpacity[slideNr] != undefined) {
          sceneAlphaTarget = colorBlockSettings.sceneOpacity[slideNr]
        }
      }

      if (
        this.viewMode === 'mobile' &&
        colorBlockSettings.sceneOpacityMobile != undefined
      ) {
        if (colorBlockSettings.sceneOpacityMobile[slideNr] != undefined) {
          sceneAlphaTarget = colorBlockSettings.sceneOpacityMobile[slideNr]
        }
      }

      // set colors for block color gradient
      this.ohpenShader.uniforms['blockColorLeft'].value = {
        x: colorBlockSettings.blockColor1.r,
        y: colorBlockSettings.blockColor1.g,
        z: colorBlockSettings.blockColor1.b,
      }
      this.ohpenShader.uniforms['blockColorRight'].value = {
        x: colorBlockSettings.blockColor2.r,
        y: colorBlockSettings.blockColor2.g,
        z: colorBlockSettings.blockColor2.b,
      }

      // set the default x values of where the block starts and stops
      let blockStartX = 0
      let blockEndX = 1

      if (this.viewMode === 'desktop') {
        slideNr = 0
        switch (colorBlockSettings.desktopType) {
          case 'left':
            blockStartX = 0
            blockEndX = 0.5
            break
          case 'right':
            blockStartX = 0.5
            blockEndX = 1
            break
          case 'full':
            blockStartX = 0
            blockEndX = 1
            break
          case 'none':
            blockStartX = 0
            blockEndX = 0
            break
        }
      }

      let slideAnimations =
        this.viewMode === 'mobile'
          ? colorBlockSettings.slideAnimationsMobile
          : colorBlockSettings.slideAnimations

      // set the start and stop value for top/left and bottom/right corners of the block
      switch (slideAnimations[slideNr]) {
        case 'fromTop':
          this.colorBlockProps.sx = blockStartX
          this.colorBlockProps.sy = 0
          this.colorBlockProps.sx2 = blockEndX
          this.colorBlockProps.sy2 = 0
          this.colorBlockProps.x = blockStartX
          this.colorBlockProps.y = 0
          this.colorBlockProps.x2 = blockEndX
          this.colorBlockProps.y2 = 1
          this.colorBlockProps.inverted = 0
          break
        case 'fromTopInverted':
          this.colorBlockProps.sx = blockStartX
          this.colorBlockProps.sy = 0
          this.colorBlockProps.sx2 = blockEndX
          this.colorBlockProps.sy2 = 0
          this.colorBlockProps.x = blockStartX
          this.colorBlockProps.y = 0
          this.colorBlockProps.x2 = blockEndX
          this.colorBlockProps.y2 = 1
          this.colorBlockProps.inverted = 1
          break
        case 'toTop':
          this.colorBlockProps.sx = blockStartX
          this.colorBlockProps.sy = 0
          this.colorBlockProps.sx2 = blockEndX
          this.colorBlockProps.sy2 = 1
          this.colorBlockProps.x = blockStartX
          this.colorBlockProps.y = 0
          this.colorBlockProps.x2 = blockEndX
          this.colorBlockProps.y2 = 0
          this.colorBlockProps.inverted = 0
          break
        case 'fromBottom':
          this.colorBlockProps.sx = blockStartX
          this.colorBlockProps.sy = 1
          this.colorBlockProps.sx2 = blockEndX
          this.colorBlockProps.sy2 = 1
          this.colorBlockProps.x = blockStartX
          this.colorBlockProps.y = 0
          this.colorBlockProps.x2 = blockEndX
          this.colorBlockProps.y2 = 1
          this.colorBlockProps.inverted = 0
          break
        case 'fromBottomInverted':
          this.colorBlockProps.sx = blockStartX
          this.colorBlockProps.sy = 1
          this.colorBlockProps.sx2 = blockEndX
          this.colorBlockProps.sy2 = 1
          this.colorBlockProps.x = blockStartX
          this.colorBlockProps.y = 0
          this.colorBlockProps.x2 = blockEndX
          this.colorBlockProps.y2 = 1
          this.colorBlockProps.inverted = 1
          break
        case 'toBottom':
          this.colorBlockProps.sx = blockStartX
          this.colorBlockProps.sy = 0
          this.colorBlockProps.sx2 = blockEndX
          this.colorBlockProps.sy2 = 1
          this.colorBlockProps.x = blockStartX
          this.colorBlockProps.y = 1
          this.colorBlockProps.x2 = blockEndX
          this.colorBlockProps.y2 = 1
          this.colorBlockProps.inverted = 0
          break
        default:
          this.colorBlockProps.sx = 0
          this.colorBlockProps.sy = 0
          this.colorBlockProps.sx2 = 0
          this.colorBlockProps.sy2 = 0
          this.colorBlockProps.x = 0
          this.colorBlockProps.y = 0
          this.colorBlockProps.x2 = 0
          this.colorBlockProps.y2 = 0
          this.colorBlockProps.inverted = 0
          break
      }

      // set the blok position for this frame based on current animation position
      let blockX =
        this.colorBlockProps.sx * (1 - this.colorBlockProps.pos) +
        this.colorBlockProps.x * this.colorBlockProps.pos
      let blockY =
        this.colorBlockProps.sy * (1 - this.colorBlockProps.pos) +
        this.colorBlockProps.y * this.colorBlockProps.pos
      let blockX2 =
        this.colorBlockProps.sx2 * (1 - this.colorBlockProps.pos) +
        this.colorBlockProps.x2 * this.colorBlockProps.pos
      let blockY2 =
        this.colorBlockProps.sy2 * (1 - this.colorBlockProps.pos) +
        this.colorBlockProps.y2 * this.colorBlockProps.pos

      // add values to the shader uniforms
      this.ohpenShader.uniforms['blockTLPos'].value = { x: blockX, y: blockY }
      this.ohpenShader.uniforms['blockBRPos'].value = { x: blockX2, y: blockY2 }

      this.ohpenShader.uniforms['blockInCurrent'].value =
        this.colorBlockProps.pos <= 1 ? 1 : 0

      this.ohpenShader.uniforms[
        'blockMaskInvered'
      ].value = this.colorBlockProps.inverted

      // for testing
      // this.ohpenShader.uniforms['blockTLPos'].value = { x: 0, y: 0 }
      // this.ohpenShader.uniforms['blockBRPos'].value = { x: 0.4, y: 1 }

      // this.ohpenShader.uniforms['blockTLPos2'].value = { x: 0.7, y: 0 }
      // this.ohpenShader.uniforms['blockBRPos2'].value = { x: 1, y: 1 }

      // this.ohpenShader.uniforms['blockColorLeft'].value.x = 1
      // this.ohpenShader.uniforms['blockColorLeft'].value.y = 0
      // this.ohpenShader.uniforms['blockColorLeft'].value.z = 0
      // this.ohpenShader.uniforms['blockColorRight'].value.x = 0
      // this.ohpenShader.uniforms['blockColorRight'].value.y = 0
      // this.ohpenShader.uniforms['blockColorRight'].value.z = 1

      // this.ohpenShader.uniforms['blockColorLeft2'].value.x = 0
      // this.ohpenShader.uniforms['blockColorLeft2'].value.y = 1
      // this.ohpenShader.uniforms['blockColorLeft2'].value.z = 0
      // this.ohpenShader.uniforms['blockColorRight2'].value.x = 1
      // this.ohpenShader.uniforms['blockColorRight2'].value.y = 0
      // this.ohpenShader.uniforms['blockColorRight2'].value.z = 1
    }

    // LERP the THREE scene opacity towards the target opacity for this current scene and slide
    this.ohpenShader.uniforms['sceneOpacity'].value -=
      (this.ohpenShader.uniforms['sceneOpacity'].value - sceneAlphaTarget) / 20
  }

  public checkQualityLevel = (): void => {
    /*

     renderQuality actions

     4: all will throttle
     3: geom update tick to 30fps
     2: geom update and render tick to 30fps
     1: video to 720p, max render res to 1280x720
     0: dynamic resolution scaling activated below 1280x720 to a minimum of 1024x512

     */

    if (this.stats.getAverageFPS() < 45) {
      this.newPixelRatioOffset = Math.max(0.6, this.newPixelRatioOffset - 0.002)
    }

    if (this.stats.getAverageFPS() > 55) {
      this.newPixelRatioOffset = Math.min(1, this.newPixelRatioOffset + 0.002)
    }

    // check if the render resolution needs to be changed
    if (
      Math.round(this.newPixelRatioOffset * 5) / 5 !=
      Math.round(this.pixelRatioOffset * 5) / 5
    ) {
      // set the new pixel ratio offset
      this.pixelRatioOffset = Math.round(this.newPixelRatioOffset * 5) / 5

      // resize all the render targets and shader settings
      this.onResize({
        aspect:
          this.threeContainerElement.offsetWidth /
          this.threeContainerElement.offsetHeight,
        height: this.threeContainerElement.offsetHeight,
        width: this.threeContainerElement.offsetWidth,
      })

      // render everything in the new resolution to avoid frame skips
      this.onUpdate()
    }

    // check fps and see if the qualitiy level needs to be lowered
    if (this.renderQualityTimeout <= 0) {
      if (this.stats.getAverageFPS() < 35) {
        if (this.renderQuality > 0) {
          this.renderQuality--
          this.onResize({
            aspect:
              this.threeContainerElement.offsetWidth /
              this.threeContainerElement.offsetHeight,
            height: this.threeContainerElement.offsetHeight,
            width: this.threeContainerElement.offsetWidth,
          })
          if (this.renderQuality < 2) {
            this.switchBackgroundMedia(
              this.currentSceneData.mediaSettings.mediaHTMLElement,
              this.currentSceneData.mediaSettings.position,
              this.currentSceneData.mediaSettings.crop,
            )
          }
        }
      }

      if (this.stats.getAverageFPS() > 58) {
        if (this.renderQuality < this.renderQualityMax) {
          this.renderQuality++
          this.onResize({
            aspect:
              this.threeContainerElement.offsetWidth /
              this.threeContainerElement.offsetHeight,
            height: this.threeContainerElement.offsetHeight,
            width: this.threeContainerElement.offsetWidth,
          })
          if (this.renderQuality < 2) {
            this.switchBackgroundMedia(
              this.currentSceneData.mediaSettings.mediaHTMLElement,
              this.currentSceneData.mediaSettings.position,
              this.currentSceneData.mediaSettings.crop,
            )
          }
        }
      }

      this.renderQualityTimeout = this.renderQualityTimeoutStart
    }

    // make exceptions for render quality on certain routes
    if (
      this.currentPath === '/our-platform/partners' ||
      this.currentPath === '/our-platform/our-partners'
    ) {
      if (this.renderQuality < 2) {
        this.renderQuality = 2
      }
    }

    if (this.renderQuality > 3) {
      this.renderCountDownStart = 0
      this.tickerCountDownStart = 0
    } else if (this.renderQuality > 1) {
      this.renderCountDownStart = 2
      this.tickerCountDownStart = 2
      // } else {
      //   this.renderCountDownStart = 3
      //   this.tickerCountDownStart = 3
    }

    // set shader depth of field to zero to deactivate it
    // if (this.renderQuality <= 0) {
    //   this.ohpenShader.uniforms['maxblur'].value = 0
    // }

    // check which fragment shader should be used based on if there is depth of field
    if (
      this.ohpenShader.uniforms['maxblur'].value > 0 ||
      this.ohpenShader.uniforms['maxblur2'].value > 0
    ) {
      if (
        this.ohpenShader.material.fragmentShader !=
        this.ohpenShader.fragmentShader
      ) {
        this.ohpenShader.material.fragmentShader = this.ohpenShader.fragmentShader
        this.ohpenShader.material.needsUpdate = true
      }
    } else {
      if (
        this.ohpenShader.material.fragmentShader !=
        this.ohpenShader.lowFragmentShader
      ) {
        this.ohpenShader.material.fragmentShader = this.ohpenShader.lowFragmentShader
        this.ohpenShader.material.needsUpdate = true
      }
    }
  }

  // resets the small particle animation
  private resetParticles = (): void => {
    const positions = this.particlesMesh.geometry.attributes.position
    const speed = this.particlesMesh.geometry.attributes.speed
    let vertPos
    for (let i = 0; i < positions.count; i++) {
      vertPos = i * 3
      speed.array[vertPos] = 0
      speed.array[vertPos + 1] = 0
      speed.array[vertPos + 2] = 0

      positions.array[vertPos] = THREE.MathUtils.randFloatSpread(5)
      positions.array[vertPos + 1] = THREE.MathUtils.randFloatSpread(5)
      positions.array[vertPos + 2] = -(Math.random() * 20 + 6)
    }

    positions.needsUpdate = true
  }

  // animate the small particle animation per frame
  private processParticles = (deltaTime: number): void => {
    const positions = this.particlesMesh.geometry.attributes.position
    const speed = this.particlesMesh.geometry.attributes.speed
    let vertPos
    for (let i = 0; i < positions.count; i++) {
      // update the x and y of each partice. Leave the z axis as it is so it won't move too far or too close to the camera
      vertPos = i * 3
      speed.array[vertPos] +=
        (Math.random() - 0.5) *
        (0.0002 + this.threeMaskCanvas.maskProps.particlePos * 0.007) *
        (60 * deltaTime)
      speed.array[vertPos + 1] +=
        (Math.random() - 0.5) *
        (0.0002 + this.threeMaskCanvas.maskProps.particlePos * 0.007) *
        (60 * deltaTime)

      speed.array[vertPos] *= 0.99
      speed.array[vertPos + 1] *= 0.99

      positions.array[vertPos] += speed.array[vertPos]
      positions.array[vertPos + 1] += speed.array[vertPos + 1]
    }

    // tell THREE the verts need an update
    positions.needsUpdate = true
  }

  // main render update function
  public onUpdate = (deltaTime: number): void => {
    deltaTime = deltaTime ? deltaTime : 0.0166

    // if debug is enabled, update stats
    if (this.fpsShowDiv != undefined) {
      if (this.fpsShowDiv.style.display != 'none') {
        this.fpsShowDiv.innerHTML = ''
        this.fpsShowDiv.innerHTML = 'F ' + this.stats.getAverageFPS()
        this.fpsShowDiv.innerHTML += '<br>Q ' + this.renderQuality
        this.fpsShowDiv.innerHTML +=
          '<br>P ' + this.pixelRatio + ' / ' + this.pixelRatioOffset
        this.fpsShowDiv.innerHTML +=
          '<br>R ' +
          this.ohpenShader.uniforms['width'].value +
          'x' +
          this.ohpenShader.uniforms['height'].value
      }
    }

    // check render quality
    this.renderQualityTimeout -= deltaTime
    this.checkQualityLevel()

    // if stats is defined, start the frame timing check
    if (this.stats) {
      this.stats.begin()
    }

    // if mobile, reset the mouse target position so the scenes do not sway on touch events
    if (this.viewMode === 'mobile') {
      this.mouseProps.targetX = 0.5
      this.mouseProps.targetY = 0.5
    }

    // LERP mouse position towards target position
    if (this.mouseProps) {
      this.mouseProps.x -=
        (this.mouseProps.x - this.mouseProps.targetX) / (2000 * deltaTime)
      this.mouseProps.y -=
        (this.mouseProps.y - this.mouseProps.targetY) / (2000 * deltaTime)
    }

    // update the position of the distortion ring
    this.threeMaskCanvas.maskProps.ringPos += deltaTime * 0.02
    this.threeMaskCanvas.maskProps.ringPos =
      this.threeMaskCanvas.maskProps.ringPos % 1

    // draw the mask canvas for this frame and update the texture and shader uniforms
    this.canvasMaskTexture.needsUpdate = true
    this.threeMaskCanvas.renderMask()
    this.ohpenShader.uniforms['tMask'].value = this.canvasMaskTexture
    this.ohpenShader.uniforms['time'].value = deltaTime % 1

    // set transparency of the color block based on if the site is mobile or desktop
    this.ohpenShader.uniforms['colorBlockOpacity'].value = 1
    if (this.viewMode === 'mobile') {
      this.ohpenShader.uniforms['colorBlockOpacity'].value = 0.92
    }

    if (this.currentSceneData) {
      // countdown to render time, and ticker time
      this.renderCountDown--
      this.tickerCountDown--

      let geomMeshProcessing = false

      // update animation of the current scene
      if (this.currentSceneData.meshUpdateScript != undefined) {
        geomMeshProcessing = true
        this.currentSceneData.meshUpdateScript(
          this.renderer,
          this.currentSceneData,
          this.camera,
          this.scene,
          this.polyParticleMesh,
          this.wireParticleMesh,
          this.pointParticleMesh,
          this.assetStorageArray,
          this.mouseProps,
          deltaTime,
        )
      }

      // update geometry of current scene
      if (this.currentSceneData.geomProcessScript != undefined) {
        geomMeshProcessing = true
        if (this.tickerCountDown <= 0) {
          this.currentSceneData.geomProcessScript(
            this.currentSceneData,
            this.camera,
            this.scene,
            this.polyParticleMesh,
            this.wireParticleMesh,
            this.pointParticleMesh,
            this.assetStorageArray,
            this.mouseProps,
            deltaTime * Math.max(1, this.tickerCountDownStart),
          )
        }
      }

      // if no geometry is updating, hide it
      if (!geomMeshProcessing) {
        this.polyParticleMesh.visible = this.wireParticleMesh.visible = this.pointParticleMesh.visible = false
      }

      // update align particles mesh
      this.particlesMesh.position.x = 0
      this.particlesMesh.position.y = 0
      this.particlesMesh.position.z = 0

      this.particlesMesh.scale.x = 1
      this.particlesMesh.scale.y = 1
      this.particlesMesh.scale.z = 1

      if (this.currentSceneData.particlesMeshSettings != undefined) {
        if (this.currentSceneData.particlesMeshSettings.position != undefined) {
          this.particlesMesh.position.x = this.currentSceneData.particlesMeshSettings.position.x
          this.particlesMesh.position.y = this.currentSceneData.particlesMeshSettings.position.y
          this.particlesMesh.position.z = this.currentSceneData.particlesMeshSettings.position.z
        }
        if (this.currentSceneData.particlesMeshSettings.scale != undefined) {
          this.particlesMesh.scale.x = this.currentSceneData.particlesMeshSettings.scale.x
          this.particlesMesh.scale.y = this.currentSceneData.particlesMeshSettings.scale.y
          this.particlesMesh.scale.z = this.currentSceneData.particlesMeshSettings.scale.z
        }
      }

      // size the particles to the resolution of the canvas
      this.particlesMesh.material.size =
        2.5 / (1920 / this.ohpenShader.uniforms['width'].value)
      if (this.particlesMesh.material.size < 1.7)
        this.particlesMesh.material.size = 1.7

      this.particlesMesh.rotation.x = (this.mouseProps.y - 0.5) * 0.1
      this.particlesMesh.rotation.y = (this.mouseProps.x - 0.5) * 0.1

      this.processParticles(deltaTime)

      // render only at certain frames based on renderCountDown
      if (
        this.renderCountDown <= 0 &&
        this.ohpenShader.uniforms['maxblur'].value > 0.0001
      ) {
        // set material colors and stuf for depth render

        // set extra materials for depth render
        if (window.ohpenExtraMaterials != undefined) {
          for (let i = 0; i < window.ohpenExtraMaterials.length; i++) {
            window.ohpenExtraMaterials[i].map =
              window.ohpenExtraMaterials[i].depthColorMap
          }
        }

        // prep all materials for depth render
        this.polyParticleMesh.material = this.glossDepthMaterial

        this.wireFrameMaterial.color = new THREE.Color(0xffffff)
        this.wireFrameMaterial.opacity = 1

        this.pointMaterial.color = new THREE.Color(0xffffff)
        this.pointMaterial.opacity = 1

        this.scene.fog.color = new THREE.Color(0x000000)
        if (this.currentSceneData.fog != undefined) {
          this.scene.fog.far =
            this.currentSceneData.fog.depthFar != undefined
              ? this.currentSceneData.fog.depthFar
              : 30
          this.scene.fog.near =
            this.currentSceneData.fog.depthNear != undefined
              ? this.currentSceneData.fog.depthNear
              : 0.01
        } else {
          this.scene.fog.far = 30
          this.scene.fog.near = 0.01
        }
        this.scene.background = new THREE.Color(0x000000)

        // render depth scene
        this.renderer.setRenderTarget(this.activeRenderTargetDepth)
        this.renderer.clear()
        this.renderer.render(this.scene, this.camera)

        // if debug is active, add more data
        if (this.fpsShowDiv != undefined) {
          if (this.fpsShowDiv.style.display != 'none') {
            this.fpsShowDiv.innerHTML += '<br>r. d. buffer'
          }
        }
      }

      // set back the poly material if depth material was set for depth render
      if (this.polyParticleMesh.material != this.glossMaterial) {
        this.polyParticleMesh.material = this.glossMaterial
      }

      // this needs to run every frame
      this.updateSceneData(deltaTime)

      // check render quality again after all the changes made
      this.checkQualityLevel()

      // render only at certain frames based on renderCountDown
      if (this.renderCountDown <= 0) {
        this.renderer.setRenderTarget(this.activeRenderTargetColor)
        this.renderer.clear()
        this.renderer.render(this.scene, this.camera)
      }

      // if renderCountDown if triggered, reset it to start value
      if (this.renderCountDown <= 0) {
        this.renderCountDown = this.renderCountDownStart
      }
      if (this.tickerCountDown <= 0) {
        this.tickerCountDown = this.tickerCountDownStart
      }
    }

    // compose the rendertargets with the customshader
    this.composer.render()

    // end stats analysis for this frame
    if (this.stats) {
      this.stats.end()
    }
  }

  // on pointer move, set the target mouse positions, and mask circle position
  public onPointerMove = (e: PointerEvent): void => {
    if (this.threeMaskCanvas) {
      this.threeMaskCanvas.maskProps.x = e.clientX / window.innerWidth
      this.threeMaskCanvas.maskProps.y = e.clientY / window.innerHeight
    }
    if (this.mouseProps) {
      this.mouseProps.targetX = e.clientX / window.innerWidth
      this.mouseProps.targetY = e.clientY / window.innerHeight
    }
  }

  public onPointerDown = (e: PointerEvent): void => {
    this.firstInteraction = true

    if (this.mouseProps) {
      this.mouseProps.targetX = e.clientX / window.innerWidth
      this.mouseProps.targetY = e.clientY / window.innerHeight
    }
  }

  public onPointerUp = (e: PointerEvent): void => {
    // set first interaction on true so certain render elements know there was interaction with the site
    this.firstInteraction = true

    if (this.mouseProps) {
      this.mouseProps.targetX = e.clientX / window.innerWidth
      this.mouseProps.targetY = e.clientY / window.innerHeight
    }

    // do a navigate if the module sphere is out, and a face is selected
    if (
      this.currentSceneData.route === '/our-platform/modules' ||
      this.currentSceneData.route === '/our-platform/all-modules'
    ) {
      if (this.currentSceneData.tempData != undefined) {
        if (
          this.currentSceneData.tempData.currentModuleData != undefined &&
          this.currentSceneData.tempData.selectedFace != undefined
        ) {
          navigate(
            '/our-platform/module/' +
              this.currentSceneData.tempData.currentModuleData.link,
          )
        }
      }
    }
  }

  public onRouteUpdate = (name: string, data: any): void => {
    this.onRouteChange(data.location)
  }

  // when route changes, check which scene needs to be set
  public onRouteChange = (location: string): void => {
    // clean up the location string to make sure it comes trough properly in the switch funtion
    if (location.substr(-1) === '/' && location.length > 2) {
      location = location.substr(0, location.length - 1)
    }

    // if the route is the same as the current, do nothing
    if (this.currentPath === location) {
      return
    }

    // set new current path so there can be checks later on
    this.currentPath = location

    // check for module and ohpeneer sub pages, and load the propper sub page THREE scene
    const darkThemedPages = [/\/latest-insights\/article/g]

    if (this.currentPath.indexOf('/our-platform/module/') === 0) {
      this.changeSceneData(ScenesData['ourPlatformOne'], {
        route: this.currentPath,
      })
    } else if (
      darkThemedPages.find((pattern) => this.currentPath.match(pattern)) ||
      this.currentPath.indexOf('/job/') !== -1
    ) {
      const scene = {
        ...ScenesData['default'],
        theme: 'dark',
      }
      this.changeSceneData(scene)
    } else if (
      this.currentPath.indexOf('/our-dna/ohpeneers') === 0 &&
      this.currentPath.split('/').filter(Boolean).length === 3
    ) {
      // Do nothing, React client will tell which scene to show
    } else {
      if (this.currentPath) {
        switch (this.currentPath) {
          case '/':
            this.changeSceneData(ScenesData['home'])
            break
          case '/home':
            this.changeSceneData(ScenesData['home'])
            break
          case '/home/about':
            this.changeSceneData(ScenesData['landscapeDark'])
            break
          case '/home/reliable':
            this.changeSceneData(ScenesData['landscapeLight'])
            break
          case '/home/adaptable':
            this.changeSceneData(ScenesData['waterDark'])
            break
          case '/home/compliant':
            this.changeSceneData(ScenesData['treebranch'])
            break
          case '/home/followup/hotel':
            this.changeSceneData(ScenesData['hotel'])
            break
          case '/home/followup/meet':
            this.changeSceneData(ScenesData['meeting'])
            break
          case '/home/followup':
            this.changeSceneData(ScenesData['followup'])
            break
          case '/home/testimonials':
            this.changeSceneData(ScenesData['malehead'])
            break
          case '/home/demo':
            this.changeSceneData(ScenesData['demo'])
            break
          case '/our-platform':
            this.changeSceneData(ScenesData['ourPlatform'])
            break
          case '/our-platform/legacy':
            this.changeSceneData(ScenesData['ourPlatformAbout'], {
              route: this.currentPath,
            })
            break
          case '/our-platform/one':
            this.changeSceneData(ScenesData['ourPlatformOne'], {
              route: this.currentPath,
            })
            break
          case '/our-platform/foundation':
            this.changeSceneData(ScenesData['ourPlatformOne'], {
              route: this.currentPath,
            })
            break
          case '/our-platform/modules':
            this.changeSceneData(ScenesData['ourPlatformOne'], {
              route: this.currentPath,
            })
            break
          case '/our-platform/all-modules':
            this.changeSceneData(ScenesData['ourPlatformOne'], {
              route: this.currentPath,
            })
            break
          case '/our-platform/partners':
            this.changeSceneData(ScenesData['ourPlatformOne'], {
              route: this.currentPath,
            })
            break
          case '/our-platform/our-partners':
            this.changeSceneData(ScenesData['ourPlatformOne'], {
              route: this.currentPath,
            })
            break
          case '/our-platform/certifications':
            this.changeSceneData(ScenesData['ourPlatformCertifications'])
            break
          case '/our-platform/demo':
            this.changeSceneData(ScenesData['demo'])
            break
          case '/our-platform/followup/hotel':
            this.changeSceneData(ScenesData['hotel'])
            break
          case '/our-platform/followup/meet':
            this.changeSceneData(ScenesData['meeting'])
            break
          case '/our-platform/followup':
            this.changeSceneData(ScenesData['followup'])
            break
          case '/our-dna':
            this.changeSceneData(ScenesData['ourDNA'])
            break
          case '/our-dna/manifest':
            this.changeSceneData(ScenesData['ourDNA'])
            break
          case '/our-dna/first':
            this.changeSceneData(ScenesData['ourDNAFirst'])
            break
          case '/our-dna/join':
            this.changeSceneData(ScenesData['ourDNAJoin'])
            break
          case '/our-dna/values':
          case '/our-dna/values/extraordinarity':
          case '/our-dna/values/reliable':
          case '/our-dna/values/companionship':
          case '/our-dna/values/initiative':
          case '/our-dna/values/persevere':
            this.changeSceneData(ScenesData['ourDNAValues'])
            break
          case '/our-dna/ohpeneers':
            this.changeSceneData(ScenesData['ourDNAOhpeneers'])
            break
          case '/our-dna/ohpeneer-overview':
            this.changeSceneData(ScenesData['ourDNAOhpeneerOverview'])
            break
          case '/our-dna/all-ohpeneers':
            this.changeSceneData(ScenesData['ourDNAOhpeneerOverview'])
            break
          case '/our-dna/sponsor':
            this.changeSceneData(ScenesData['ourDNASponsor'])
            break
          case '/our-dna/sponsor/marks-journey':
            this.changeSceneData(ScenesData['ourDNASponsorJourney'])
            break
          case '/our-dna/social':
            this.changeSceneData(ScenesData['ourDNASocial'])
            break
          case '/our-dna/social/planet':
            this.changeSceneData(ScenesData['ourDNASocialPlanet'])
            break
          case '/our-dna/social/people':
            this.changeSceneData(ScenesData['ourDNASocialPeople'])
            break
          case '/our-dna/followup':
            this.changeSceneData(ScenesData['followup'])
            break
          case '/our-dna/followup/hotel':
            this.changeSceneData(ScenesData['hotel'])
            break
          case '/our-dna/followup/meet':
            this.changeSceneData(ScenesData['meeting'])
            break
          case '/contact':
            this.changeSceneData(ScenesData['contact'])
            break
          case '/careers':
            this.changeSceneData(ScenesData['careersMission'])
            break
          case '/careers/tour':
            this.changeSceneData(ScenesData['careersTour'])
            break
          case '/careers/become':
            this.changeSceneData(ScenesData['careersBecome'])
            break
          case '/careers/health':
            this.changeSceneData(ScenesData['careersHealth'])
            break
          case '/careers/play':
            this.changeSceneData(ScenesData['careersPlay'])
            break
          case '/careers/hotel':
            this.changeSceneData(ScenesData['careersHotel'])
            break
          case '/careers/settle':
            this.changeSceneData(ScenesData['careersSettle'])
            break
          case '/careers/overview':
            this.changeSceneData(ScenesData['careersOverview'])
            break
          case '/latest-insights':
            this.changeSceneData(ScenesData['newsIntro'])
            break
          case '/clients-and-cases':
            this.changeSceneData(ScenesData['newsIntro'])
            break
          case '/clients-and-cases/freedom-for-our-clients':
            this.changeSceneData(ScenesData['clientLogos'])
            break
          case '/clients-and-cases/cases':
            this.changeSceneData(ScenesData['malehead'])
            break
          case '/privacy-and-policy':
            this.changeSceneData(ScenesData['defaultDark'])
            break
          default:
            this.changeSceneData(ScenesData['default'])
        }
      }
    }
  }

  // set the tweens for the color block animation
  public animateInColorBlock = (delay: number): void => {
    delay = delay != undefined ? delay : 1.2

    TweenMax.killTweensOf(this.colorBlockProps)
    TweenMax.set(this.colorBlockProps, {
      pos: 0,
    })
    TweenMax.to(this.colorBlockProps, 1, {
      delay: delay,
      pos: 1,
      ease: Power2.easeInOut,
    })
  }

  // resize all objects needed for rendering, and take the render quality and pixelRatioOffset into account
  public onResize = (data: Record<string, unknown>): void => {
    const { aspect, width, height } = data

    this.pixelRatio = Math.max(
      1,
      Math.min(2, window.devicePixelRatio) * this.pixelRatioOffset,
    )

    let trueWidth = width * this.pixelRatio
    let trueHeight = height * this.pixelRatio

    // const maxResX = 2048
    // const maxResY = 2048

    const minResX = 512
    const minResY = 512

    // let maxResScale = 1
    // if (maxResScale > maxResX / trueWidth) {
    //   maxResScale = maxResX / trueWidth
    // }
    // if (maxResScale > maxResY / trueHeight) {
    //   maxResScale = maxResY / trueHeight
    // }

    // maxResScale = Math.min(1, maxResScale)

    // trueWidth *= maxResScale
    // trueHeight *= maxResScale

    let minResScale = 1
    if (minResScale < minResX / trueWidth) {
      minResScale = minResX / trueWidth
    }
    if (minResScale < minResY / trueHeight) {
      minResScale = minResY / trueHeight
    }

    // minResScale = Math.max(2, minResScale)

    trueWidth *= minResScale
    trueHeight *= minResScale

    // trueWidth = Math.min(trueWidth, width)
    // trueHeight = Math.min(trueHeight, height)

    // always keep dimentions devidable by 2
    trueWidth = Math.round(trueWidth / 2) * 2
    trueHeight = Math.round(trueHeight / 2) * 2

    const canvasWidth = Math.round((width * 0.5) / 2) * 2
    const canvasHeight = Math.round((height * 0.5) / 2) * 2
    this.threeMaskCanvas.setSize(canvasWidth as number, canvasHeight as number)

    this.camera.aspect = aspect as number
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(trueWidth as number, trueHeight as number)

    this.renderTargetDepth.setSize(trueWidth as number, trueHeight as number)
    this.renderTargetColor.setSize(trueWidth as number, trueHeight as number)
    this.renderTargetDepth2.setSize(trueWidth as number, trueHeight as number)
    this.renderTargetColor2.setSize(trueWidth as number, trueHeight as number)

    // set custom shader uniforms to match the new dimentions
    this.ohpenShader.uniforms['aspect'].value = trueWidth / trueHeight
    this.ohpenShader.uniforms['width'].value = trueWidth
    this.ohpenShader.uniforms['height'].value = trueHeight

    this.renderer.domElement.style.width = width + 'px'
    this.renderer.domElement.style.height = height + 'px'

    // check if low res video can be used
    this.lowResVideo = trueHeight < 900
  }

  // the transition function sets all the values and tweens to make a scene transion
  private transition = (): void => {
    this.threeMaskCanvas.maskProps.ringPos = 0.2

    this.threeMaskCanvas.maskProps.ringX = Math.min(
      0.8,
      Math.max(0.2, this.mouseProps.x),
    )
    this.threeMaskCanvas.maskProps.ringY = Math.min(
      0.8,
      Math.max(0.2, this.mouseProps.y),
    )

    if (this.activeRenderTargetDepth === this.renderTargetDepth) {
      if (this.currentSceneData.transitionFrom === 'currentScene') {
        this.activeRenderTargetDepth = this.renderTargetDepth2
        this.activeRenderTargetColor = this.renderTargetColor2
        this.oldRenderTargetDepth = this.renderTargetDepth2
        this.oldRenderTargetColor = this.renderTargetColor2
      } else {
        this.activeRenderTargetDepth = this.renderTargetDepth2
        this.activeRenderTargetColor = this.renderTargetColor2
        this.oldRenderTargetDepth = this.renderTargetDepth
        this.oldRenderTargetColor = this.renderTargetColor
      }
    } else {
      if (this.currentSceneData.transitionFrom === 'currentScene') {
        this.activeRenderTargetDepth = this.renderTargetDepth
        this.activeRenderTargetColor = this.renderTargetColor
        this.oldRenderTargetDepth = this.renderTargetDepth
        this.oldRenderTargetColor = this.renderTargetColor
      } else {
        this.activeRenderTargetDepth = this.renderTargetDepth
        this.activeRenderTargetColor = this.renderTargetColor
        this.oldRenderTargetDepth = this.renderTargetDepth2
        this.oldRenderTargetColor = this.renderTargetColor2
      }
    }

    this.resetParticles()

    // set the depth of field settings from the old scene, to the second custom shader scene
    this.ohpenShader.uniforms[
      'tDepth'
    ].value = this.activeRenderTargetDepth.texture
    this.ohpenShader.uniforms[
      'tColor'
    ].value = this.activeRenderTargetColor.texture
    this.ohpenShader.uniforms[
      'tDepth2'
    ].value = this.oldRenderTargetDepth.texture
    this.ohpenShader.uniforms[
      'tColor2'
    ].value = this.oldRenderTargetColor.texture

    // tween all the values used by the mask canvas and custom shader
    TweenMax.killTweensOf(this.threeMaskCanvas.maskProps)
    TweenMax.set(this.threeMaskCanvas.maskProps, {
      pos: 1,
      ripplePos: 1,
      particlePos: 1,
    })

    TweenMax.to(this.threeMaskCanvas.maskProps, 2, {
      delay: 0,
      pos: 0,
      ease: Power2.easeInOut,
    })
    TweenMax.to(this.threeMaskCanvas.maskProps, 2, {
      delay: 0 + 0.3,
      ripplePos: 0,
      ease: Power2.easeInOut,
    })
    TweenMax.to(this.threeMaskCanvas.maskProps, 4, {
      particlePos: 0,
      ease: Power2.easeInOut,
    })
    this.animateInColorBlock()
  }
}
