// @ts-nocheck
/* tslint:disable */

import * as THREE from 'three'

const isServer = typeof window === 'undefined'
if (!isServer) {
  var pointAlphaCanvas = document.createElement('canvas')
  pointAlphaCanvas.width = pointAlphaCanvas.height = 64
  var ctx = pointAlphaCanvas.getContext('2d')
  ctx.fillStyle = '#000000'
  ctx.fillRect(0, 0, pointAlphaCanvas.width, pointAlphaCanvas.height)
  ctx.fillStyle = '#ffffff'
  ctx.beginPath()
  var halfSize = pointAlphaCanvas.width * 0.5
  ctx.arc(halfSize, halfSize, halfSize * 0.9, 0, 2 * Math.PI)
  ctx.closePath()
  ctx.fill()

  var pointAlphaTexture = new THREE.CanvasTexture(pointAlphaCanvas)
  pointAlphaTexture.minFilter = THREE.LinearFilter
  pointAlphaTexture.magFilter = THREE.LinearFilter

  var OhpenPointMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
   // vertexColors: THREE.VertexColors,
    size: 0.03,
    alphaTest: 0.2,
    opacity: 1,
    transparent: true,
    alphaMap: pointAlphaTexture,
  })

  // console.log("vertexColors",OhpenPointMaterial.vertexColors);
  // OhpenPointMaterial. vertexColors = THREE.VertexColors;
  // console.log("vertexColors",OhpenPointMaterial.vertexColors);
} else {
  var OhpenPointMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
  //  vertexColors: THREE.VertexColors,
    size: 0.03,
    alphaTest: 0.2,
    opacity: 1,
    transparent: true,
  })
}

export default OhpenPointMaterial
