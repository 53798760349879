import React, { ReactNode, useState, useEffect, useRef } from 'react'
import { Link } from '@reach/router'
import Hamburger from '@/components/Hamburger'
import {
  FooterContainer,
  ContactHolder,
  MobileCenteredDiv,
  FooterBackground,
} from './styled'
import { getIsMobile } from '@/modules/utils/getMobile'
import gsap from 'gsap'
import { APP_MENU_TOGGLE } from '@/events'
import PubSub from 'pubsub-js'

const Footer: React.FC<{
  withContact: boolean
  subPage: boolean
  Paginator?: ReactNode
  dropdownActive: boolean
  backButton?: ReactNode
  customIcon?: ReactNode
  scrollAwayMobile: boolean
  withMobileBackground: boolean
}> = ({
  withContact,
  subPage,
  dropdownActive,
  Paginator,
  backButton,
  customIcon,
  scrollAwayMobile,
  withMobileBackground,
}) => {
  const [scrolledAway, setScrolledAway] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [offsetTop, setOffsetTop] = useState(false)
  const [isMobile, setIsMobile] = useState(getIsMobile())
  const timelineRef = useRef(null)
  const containerRef = useRef(null)

  const scrollAwayTop = () => {
    if (timelineRef.current) {
      timelineRef.current.kill()
    }
    timelineRef.current = gsap.timeline()

    timelineRef.current.fromTo(
      containerRef.current,
      0.5,
      { y: 0 },
      { y: 100, opacity: 0.3, onComplete: setInitialized(true) },
    )
  }
  const resetTop = () => {
    if (timelineRef.current) {
      timelineRef.current.kill()
    }
    timelineRef.current = gsap.timeline()
    timelineRef.current.fromTo(
      containerRef.current,
      0.5,
      { y: 100, opacity: 1 },
      { y: 0, opacity: 1 },
    )
  }
  const initScrolledView = () => {
    if (timelineRef.current) {
      timelineRef.current.kill()
    }
    timelineRef.current = gsap.timeline()
    timelineRef.current.fromTo(
      containerRef.current,
      3,
      { y: 100, opacity: 0 },
      { y: 100, opacity: 0, onComplete: () => resetTop() },
    )
  }

  const onResize = () => {
    const newIsMobile = getIsMobile()
    setIsMobile(newIsMobile)
    if (!newIsMobile) {
      setOffsetTop(false)
    }
  }

  const onTouchMove = () => {
    if (isMobile && scrollAwayMobile) {
      if (document.getElementById('footer-offset').scrollTop > 0) {
        setOffsetTop(true)
      } else {
        setOffsetTop(false)
      }
    }
  }

  const onWheel = (e: TouchEvent) => {
    onTouchMove()
  }

  const addDomEvents = () => {
    document.addEventListener('touchmove', onWheel)
    window.addEventListener('resize', onResize)
  }

  const removeDomEvents = () => {
    document.removeEventListener('touchmove', onWheel)
    window.removeEventListener('resize', onResize)
  }

  useEffect(() => {
    if (scrollAwayMobile) {
      addDomEvents()
      initScrolledView()
    }
    return () => {
      removeDomEvents()

      if (timelineRef.current) {
        timelineRef.current.kill()
      }
    }
  }, [])

  useEffect(() => {
    if (scrollAwayMobile) {
      if (offsetTop) {
        scrollAwayTop()
      } else if (initialized) {
        resetTop()
      }
    }
  }, [offsetTop])

  return (
    <FooterContainer ref={containerRef} subPage={subPage}>
      {backButton}
      {withContact && (
        <ContactHolder>
          <Link to="/contact">
            <img
              src="/images/contact.png"
              width="22"
              height="27"
              alt="contact"
              data-url
            />
          </Link>
        </ContactHolder>
      )}
      {customIcon && <MobileCenteredDiv>{customIcon}</MobileCenteredDiv>}
      {Paginator}
      {withMobileBackground && <FooterBackground />}
      <Hamburger
        isOpen={dropdownActive}
        inProp
        color="#ffffff"
        variant="footer"
      />
    </FooterContainer>
  )
}

export default Footer
