import React, { useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { TweenMax } from 'gsap'
import { Container, NavigationLink } from './styled'
import { useContentInProp } from '@/hooks/useContentInProp'

interface Props {
  text: string
  variant: string
  url: string
  time?: number
  delay?: number
  alwaysShow?: boolean
}

const MobileBack: React.FC<Props> = ({
  text,
  url,
  variant,
  time,
  delay,
  alwaysShow,
}) => {
  const isServer = typeof window === 'undefined'
  const back = useRef(null)
  const inProp = useContentInProp()
  const mobileBreakpoint = 960

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (back.current) {
      const tween = TweenMax.fromTo(
        back.current,
        time,
        {
          opacity: 0,
        },
        {
          ease: 'Power2.inOut',
          delay,
          opacity: 1,
        },
      )

      return () => {
        tween.kill()
      }
    }
  })

  let isMobile

  if (!isServer) {
    isMobile = document.documentElement.clientWidth <= mobileBreakpoint
  } else {
    isMobile = false
  }

  if (!isMobile && !alwaysShow) {
    return null
  }
  return (
    <Container ref={back}>
      {inProp && (
        <NavigationLink to={url} variant={variant} data-url>
          <span data-url>{text}</span>
        </NavigationLink>
      )}
    </Container>
  )
}

MobileBack.defaultProps = {
  delay: 3,
  time: 1,
  alwaysShow: false,
}

MobileBack.propTypes = {
  delay: PropTypes.number,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  time: PropTypes.number,
  variant: PropTypes.string.isRequired,
  alwaysShow: PropTypes.bool,
}

export default MobileBack
