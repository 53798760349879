import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { siteWrapperCss } from '@/modules/utils/siteWrapper'

interface FooterProps {
  subPage: boolean
}

export const FooterContainer = styled.div<FooterProps>`
  align-items: center;
  color: light;
  position: fixed;
  bottom: 32px;
  display: flex;

  margin: 0 auto;
  justify-content: ${(props) => (props.subPage ? 'flex-end' : 'space-between')};
  z-index: 4;
  padding: 0.5rem 3.2rem;
  width: 100%;

  ${siteWrapperCss};
  max-width: 100%;
`

export const ContactHolder = styled.div`
  position: absolute;
  left: 32px;
`
export const FooterBackground = styled.div`
  background-color: #efefef;
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding: 45px;
  box-shadow: 0px 0px 0px 1px rgba(71, 71, 71, 0.09);
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      display: none;
    `,
    xl: css`
      display: none;
    `,
    xxl: css`
      display: none;
    `,
    /* eslint-enable sort-keys */
  })}
`

export const MobileCenteredDiv = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${breakpoints({
    md: css`
      display: none;
    `,
  })};
  img {
    object-fit: contain;
  }
`
