import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import theme from '../../layouts/theme'
import proportion from '@/modules/utils/proportion'
import { siteWrapperCss } from '@/modules/utils/siteWrapper'

export const HeaderWrapper = styled.div`
  background: transparent;
  color: light;
  display: flex;
  margin: 0 auto;
  max-width: ${theme.breakpoints.xl}px;
  height: 10rem;
  padding: 0 2rem;
  z-index: 5;
  position: fixed;
  top: 17px;
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
    /*padding-top: ${proportion(71, 'desktop')};*/
    `,
    xl: css`
      padding: 0;
      /* padding-top: 71px; */
    `,
    /* eslint-enable sort-keys */
  })}
  ${siteWrapperCss};
`

export const Navigation = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  z-index: 2;
  position: relative;
`

export const Links = styled.div`
  display: none;
  opacity: 0;

  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      display: flex;
    `,
    /* eslint-enable sort-keys */
  })}
`

export const LogoLink = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 2;
`

export const HamburgerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
