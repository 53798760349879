import { graphql, useStaticQuery } from 'gatsby'
// Type hook output
type Page = {
  url: string
  title: string
  noPrefix?: boolean
}

export interface SiteMetadata {
  title: string
  description: string
  siteUrl: string
  pageMeta: {
    pages: Page[]
  }
}
function useSiteMetadata(): SiteMetadata {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
          pageMeta {
            pages {
              url
              title
              noPrefix
            }
          }
        }
      }
    }
  `)
  // Return directly wanted data
  return data.site.siteMetadata
}
export default useSiteMetadata
