import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { ContainerHorizontalLeft, DotsHorizontal, PaginationElement, PaginationTitle, PaginationDot} from './styled'
import { getSanitizedLocation } from '@/modules/utils/location'
import { useSitePages } from '@/hooks/useSitePages'
import { CustomNavigate } from '@/modules/utils/CustomNavigate'

//
interface Props {
  number: number
  max: number
}

const Dotted: React.FC<Props> = ({ number, max }) => {
  const sitePages = useSitePages()
  const numbersRef = useRef(null)
  const containerRef = useRef(null)
  const range = [...Array(max).keys()]

  const currentPath = getSanitizedLocation(window.location.pathname)
  const currentPage = currentPath.split('/')[1];
  const page = sitePages[currentPage] || sitePages.home
  
  const pageTitle = (pageNumber) => {
    const currentPageArray = page[pageNumber] ? page[pageNumber].split('/') : []
    return pageNumber > 0 ? currentPageArray[currentPageArray.length - 1] : '';
  }

  const onClickNumber = (newNumber) => {
    const newRoute = page[newNumber]

    if (newRoute) {
      CustomNavigate(newRoute)
    }
  }


  const HomeIcon = () => (
    <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3V8H2V4.5H5V8H7V3L3.5 0L0 3Z" fill="inherit"/>
    </svg>
  )

  return (
    <ContainerHorizontalLeft data-url ref={containerRef}>
      <DotsHorizontal data-url ref={numbersRef}>
        {range.map((index) => (
          <PaginationElement onClick={() => onClickNumber(index)} key={index}>
            <PaginationDot className={number === index ? 'active' : ''}>
              {index === 0 ? <HomeIcon/> : ''}
            </PaginationDot>
            <PaginationTitle className={number === index ? 'active' : ''}>{pageTitle(index)}</PaginationTitle>
          </PaginationElement>
        ))}
      </DotsHorizontal>
    </ContainerHorizontalLeft>
  )
}

Dotted.propTypes = {
  number: PropTypes.number.isRequired,
}

export default Dotted
