import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

type LocationInfo = {
  countryAbbr: string
  country: string
  addresses: {
    street: string
    pc: string
    city: string
  }[]
}

const Address = styled.address`
  position: relative;
  font-style: normal;
  transition: transform, opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0, -0.01, 0, 1.01),
    cubic-bezier(0, -0.01, 1, -0.02);
  transition-delay: calc(250ms + calc(var(--index) * 125ms));

  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      margin-bottom: 25px;
    `,
    md: css`
      margin-bottom: 51px;
    `,
    /* eslint-enable sort-keys */
  })}
  .addresses {
    display: flex;

    .address {
      flex: 1 0 60%;
      padding-right: 0.5em;
      > div {
        white-space: pre;
      }
      &:nth-child(2n) {
        flex: 1 0 40%;
      }
    }
  }

  .is-closed & {
    transform: translateY(50%);
    opacity: 0;
    transition-duration: 50ms;
  }
`
const CountryAbbr = styled.div`
  &:before {
    content: '(';
  }

  &:after {
    content: ')';
  }

  font-size: 0.7em;
  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      display: none;
    `,
    md: css`
      display: block;
    `,
    /* eslint-enable sort-keys */
  })}
`
const CountryName = styled.div`
  font-weight: bold;
  text-decoration: underline;
  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      margin: 0;
    `,
    md: css`
      margin: 1.6rem 0 1.7rem 0;
    `,
    /* eslint-enable sort-keys */
  })}
  font-size: 1.5rem;
`
export const Location = (props: { locationInfo: LocationInfo }) => {
  const { locationInfo } = props
  return (
    <Address>
      <CountryAbbr>{locationInfo.countryAbbr}</CountryAbbr>
      <CountryName>{locationInfo.country}</CountryName>
      <div className="addresses">
        {locationInfo.addresses.map((address, index) => (
          <div className="address" key={address.pc}>
            <div>{address.street}</div>
            <div>{address.pc}</div>
            <div>{address.city}</div>
          </div>
        ))}
      </div>
    </Address>
  )
}
