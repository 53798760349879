import React from 'react'
import Footer from '@/components/Footer'

import { PaginatorProps } from '@/components/Paginator'
import MobileBack from '@/components/MobileBack'

//
// @ts-ignore
const NewsOverviewTemplate: React.FC<{
  path: string
  paginatorData: PaginatorProps
  desktop: boolean
  dropdownActive: boolean
}> = ({ children, path, paginatorData, desktop, dropdownActive }) => {
  const { number } = paginatorData

  return (
    <>
      {children}
      <Footer
        withContact={
          !desktop && number === 0 && !dropdownActive && path !== '/contact'
        }
        dropdownActive={dropdownActive}
        subPage={false}
        backButton={
          <MobileBack
            variant="light"
            delay={0}
            time={1}
            url="/latest-insights"
            text="back"
          />
        }
      />
    </>
  )
}

export default NewsOverviewTemplate
