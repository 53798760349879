import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { NavigationLink, NavigationLinkContainer, Line, Label } from './styled'
import { Transition } from 'react-transition-group'
import gsap from 'gsap'
import { SplitText } from 'gsap/dist/SplitText'

const LinkOrText: React.FC<{
  currentLocation: string
  label: string
  inProp: boolean
  to: string
  forceText?: boolean
}> = ({ inProp, currentLocation, forceText, label, to }) => {
  const isActive = Boolean(currentLocation.includes(to))
  const spanRef = useRef(null)
  const lineRef = useRef(null)

  let content = (
    <NavigationLinkContainer data-url to={to}>
      <NavigationLink data-url to={to}>
        <span data-url ref={spanRef}>
          {label}
        </span>
        {isActive && <Line ref={lineRef} />}
      </NavigationLink>
    </NavigationLinkContainer>
  )

  if (forceText) {
    content = (
      <Label>
        <span ref={spanRef}>{label}</span>
      </Label>
    )
  }

  function onEnter() {
    const st = new SplitText(spanRef.current, { type: 'chars' })

    st.chars.forEach((char) => {
      char.setAttribute('data-url', 'true')
    })

    gsap.fromTo(
      st.chars,
      0.4,
      {
        y: 20,
      },
      {
        y: 0,
        stagger: 0.003,
      },
    )

    if (lineRef.current) {
      gsap.fromTo(
        lineRef.current,
        0.6,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          delay: 0.125,
        },
      )
    }
  }

  return (
    <Transition in={inProp} onEnter={onEnter} timeout={0}>
      {content}
    </Transition>
  )
}

LinkOrText.defaultProps = {
  forceText: false,
}

LinkOrText.propTypes = {
  currentLocation: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inProp: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  forceText: PropTypes.bool,
}

export default LinkOrText
