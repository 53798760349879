import React, { useRef, useState } from 'react'
import gsap from 'gsap'
import { Background } from './Background'
import { Transition } from 'react-transition-group'
import { Location } from './Location'
// import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  DropdownMenuWrap,
  Footer,
  Content,
  LocationsWrapper,
  LocationChevron,
} from './styled'

import { LinkGroup } from '@/components/DropdownMenu/LinkGroup'

interface Props {
  variant?: string
  active: boolean
}

const useTranslation = () => {
  return {
    t: function (key) {
      return {
        'global.menu_platform': 'OUR PLATFORM',
        'global.menu_modules': 'MODULES',
        'global.menu_partners': 'PARTNERS',
        'global.menu_dna': 'OUR DNA',
        'global.menu_our_story': 'OUR STORY',
        'global.menu_our_team': 'OUR TEAM',
        'global.menu_careers': 'CAREERS',
        'global.menu_media': 'MEDIA',
        'global.menu_clients_cases': 'clients & cases',
        'global.menu_news': 'news',
        'global.menu_privacy_policy': 'privacy policy',
        'global.menu_sub_mediakit': 'media kit',
        'global.menu_contact': 'contact',
        'global.menu_email': 'email',
        'global.menu_phone': 'phone',
        'global.menu_social': 'social',
        'global.menu_offices': 'offices',
      }[key]
    },
  }
}
const links = (props) => {
  const { t } = useTranslation()
  const openOffices = () => {
    props.toggleOffices(true)
  }
  return [
    {
      primaryLink: {
        url: '/our-platform',
        text: t('global.menu_platform'),
      },
      subLinks: [
        {
          url: '/our-platform/all-modules',
          text: t('global.menu_modules'),
        },
        {
          url: '/our-platform/partners',
          text: t('global.menu_partners'),
        },
      ],
    },
    {
      primaryLink: {
        url: '/our-dna',
        text: t('global.menu_dna'),
      },
      subLinks: [
        {
          url: '/our-dna/first',
          text: t('global.menu_our_story'),
        },
        {
          url: '/our-dna/ohpeneer-overview',
          text: t('global.menu_our_team'),
        },
        {
          url: 'https://ohpen.jobs.personio.com/',
          text: t('global.menu_careers'),
        },
      ],
    },
    {
      primaryLink: {
        url: '/latest-insights',
        text: t('global.menu_media'),
      },
      subLinks: [
        {
          url: '/clients-and-cases/cases',
          text: t('global.menu_clients_cases'),
        },
        {
          url: '/latest-insights/overview',
          text: t('global.menu_news'),
        },
        {
          url: '/privacy-and-policy',
          text: t('global.menu_privacy_policy'),
        },
        // {
        //   url: '/files/media-kit-ohpen-2021-v2.zip',
        //   text: t('global.menu_sub_mediakit'),
        // },
      ],
    },
    {
      primaryLink: {
        url: '/contact',
        text: t('global.menu_contact'),
      },
      subLinks: [
        {
          url: '/contact',
          text: t('global.menu_email'),
        },
        {
          url: '/contact',
          text: t('global.menu_phone'),
        },
        {
          url: '/contact',
          text: t('global.menu_social'),
        },
      ],
    },
    {
      primaryLink: {
        action: openOffices,
        text: t('global.menu_offices'),
      },
      subLinks: [
        {
          action: openOffices,
          text: 'nl',
        },
        {
          action: openOffices,
          text: 'sp',
        },
        {
          action: openOffices,
          text: 'uk',
        },
        {
          action: openOffices,
          text: 'be',
        },
        {
          action: openOffices,
          text: 'sk',
        },
      ],
    },
  ]
}

const offices = [
  {
    country: 'THE NETHERLANDS (HQ)',
    countryAbbr: 'NL',
    addresses: [
      {
        street: 'Rokin 111',
        pc: '1012KN',
        city: 'Amsterdam',
      },
    ],
  },
  {
    country: 'SPAIN',
    countryAbbr: 'SP',
    addresses: [
      {
        street: 'Passeig de Gracia 110, 3º 2ª',
        pc: '08008',
        city: 'Barcelona',
      },
    ],
  },
  {
    country: 'UNITED KINGDOM',
    countryAbbr: 'UK',
    addresses: [
      {
        street: `The White Collar factory,
First Floor, Studio 9`,
        pc: 'EC1Y 8AF',
        city: 'London',
      },
    ],
  },
  {
    country: 'BELGIUM',
    countryAbbr: 'BE',
    addresses: [
      {
        street: 'Van de Wervestraat 8 bus 10',
        pc: '2060',
        city: 'Antwerp',
      },
    ],
  },
  {
    country: 'SLOVAKIA',
    countryAbbr: 'SK',
    addresses: [
      {
        street: 'Tomášikova 64',
        pc: 'Lakeside park 831 04',
        city: 'Bratislava',
      },
      {
        street: 'Veľká Okružná 26',
        pc: '010 01 ',
        city: 'Žilina',
      },
    ],
  },
]

export const DropdownMenu: React.FC<Props> = ({ active, ...props }) => {
  const backgroundRef = useRef(null)
  const contentRef = useRef(null)
  const imageRef = useRef(null)
  const menuRefs = {}
  const addMenuRef = (key) => {
    return (ref) => (menuRefs[key] = ref)
  }

  const [state, setState] = useState({
    officesVisible: true,
  })

  const onEnter = () => {
    gsap.set(backgroundRef.current, {
      opacity: 1,
    })

    gsap.fromTo(
      contentRef.current,
      0.25,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.75,
      },
    )
  }

  const onExit = () => {
    gsap
      .fromTo(
        contentRef.current,
        0.25,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
      )
      .then(() => {
        gsap.delayedCall(0.125, () => {
          gsap.set(backgroundRef.current, {
            opacity: 0,
          })
        })
      })
  }

  const toggleOffices = function (newState) {
    setState({
      officesVisible: [true, false].includes(newState)
        ? newState
        : !state.officesVisible,
    })
    let dist =
      menuRefs[4].getBoundingClientRect().top +
      contentRef.current.scrollTop -
      contentRef.current.offsetTop
    if (!state.officesVisible) {
      gsap.to(contentRef.current, 0.25, {
        scrollTop: dist,
      })
    }
  }

  const changeImage = (image) => {
    return () =>
      (imageRef.current.src = image
        ? image
        : imageRef.current.getAttribute('data-default-src'))
  }
  return (
    <Transition
      in={active}
      onEnter={onEnter}
      onExit={onExit}
      unmountOnExit
      timeout={0}
    >
      <DropdownMenuWrap ref={backgroundRef} {...props}>
        <Content ref={contentRef}>
          <nav>
            <ul>
              {links({ toggleOffices: toggleOffices }).map(
                (linkGroup, index) => (
                  <li key={index} ref={addMenuRef(index)}>
                    <LinkGroup {...linkGroup}></LinkGroup>
                  </li>
                ),
              )}
            </ul>
          </nav>
          <LocationChevron
            onClick={toggleOffices}
            className={state.officesVisible ? 'is-open' : 'is-closed'}
          />
          <LocationsWrapper
            className={state.officesVisible ? 'is-open' : 'is-closed'}
          >
            <div className="image-wrapper">
              <img
                data-default-src="/images/contact-image.jpg"
                src="/images/contact-image.jpg"
                alt=""
                ref={imageRef}
              />
            </div>
            <ul className="locations">
              {offices.map((location, index) => (
                <li
                  key={index}
                  style={{ '--index': index.toString() }}
                  onMouseOver={changeImage(location.image)}
                >
                  <Location {...{ locationInfo: location, index: index }}>
                    {' '}
                  </Location>
                </li>
              ))}
            </ul>
          </LocationsWrapper>
          <Footer>
            <p>© 2020 Ohpen All rights reserved</p>
            <div className="social-links">
              <a href="https://www.linkedin.com/company/ohpen">Linkedin</a>{' '}
              <a href="https://www.instagram.com/lifeatohpen/">Instagram</a>
            </div>
          </Footer>
        </Content>
        <Background active={active} />
      </DropdownMenuWrap>
    </Transition>
  )
}
