/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import * as React from 'react'
import styled from '@xstyled/styled-components'

interface OwnProps {
  height?: string
  width?: string
  color?: string
}

const Path = styled.path`
  transition: fill 400ms ease-in-out;
`

const OhpenLogo = ({
  height = '48px',
  width = '48px',
  color = '#ffffff',
}: OwnProps) => (
  <svg
    height={height}
    width={width}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 141.7 161.6"
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M13.4,126.8c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12s12-5.4,12-12C25.4,132.2,20,126.8,13.4,126.8 M13.4,145
        c-3.5,0.1-6.3-2.7-6.4-6.2c-0.1-3.5,2.7-6.3,6.2-6.4s6.3,2.7,6.4,6.2c0,0,0,0.1,0,0.1C19.6,142.2,16.8,145,13.4,145 M53.5,136.5
        v14.2h-5.9v-13.5c0.2-1.9-0.9-3.8-2.7-4.5c-0.6-0.2-1.2-0.3-1.8-0.3c-2.8,0-5,1.7-5,5.7v12.5h-5.8v-32.9h5.9v12.4
        c0.8-1.1,1.8-1.9,3-2.5c1.2-0.6,2.5-0.8,3.8-0.7C49.8,126.9,53.6,130.3,53.5,136.5 M73.9,126.8c-1.4-0.1-2.7,0.2-4,0.8
        c-1.3,0.6-2.3,1.5-3.2,2.5v-2.7h-5.8v32.4h5.7v-12.4c0.9,1.1,2,1.9,3.2,2.5c1.3,0.6,2.6,0.8,4,0.8c6,0,10.8-5.2,10.8-11.9
        S79.8,126.8,73.9,126.8 M78.5,141.1c-0.3,0.8-0.7,1.5-1.3,2.1c-0.6,0.6-1.2,1.1-2,1.4c-0.8,0.3-1.6,0.5-2.4,0.4
        c-1.2,0-2.5-0.3-3.5-1c-1-0.7-1.9-1.7-2.3-2.8c-0.5-1.2-0.6-2.4-0.4-3.7c0.2-1.2,0.8-2.4,1.7-3.3c0.9-0.9,2-1.5,3.2-1.8
        c2.5-0.5,5.1,0.5,6.4,2.7c0.7,1.1,1,2.3,1,3.5C79,139.6,78.8,140.4,78.5,141.1 M101.7,126.9c-3.2-0.1-6.2,1.1-8.4,3.4
        c-2.2,2.3-3.4,5.4-3.3,8.6c0,6.8,4.7,11.9,12.2,11.9c3.9,0.2,7.5-1.7,9.7-4.8l-4.6-2.7c-1.2,1.5-3.1,2.3-5,2.3c-3,0-5.5-1.2-6.3-4.2
        h16.8c0.1-0.8,0.2-1.6,0.2-2.4C113,132.2,108.3,126.9,101.7,126.9 M95.9,136.7c0.2-1.4,1-2.6,2.1-3.4c1.1-0.8,2.4-1.3,3.8-1.2
        c1.3,0,2.6,0.4,3.7,1.3c1,0.8,1.7,2,1.9,3.4L95.9,136.7z M140.4,136.4v14.3h-5.9v-13.5c0.2-1.9-0.9-3.8-2.7-4.5
        c-0.6-0.2-1.2-0.3-1.8-0.3c-2.8,0-5,1.7-5,5.8v12.6H119v-23.3h5.9v2.6c0.8-1.1,1.8-2,3-2.5c1.2-0.6,2.5-0.8,3.8-0.7
        C136.6,126.8,140.4,130.3,140.4,136.4 M70.7,103c28,0,50.6-22.7,50.6-50.6c0-28-22.7-50.6-50.6-50.6S20.1,24.4,20.1,52.3
        c0,0,0,0,0,0C20.1,80.3,42.7,103,70.7,103C70.7,103,70.7,103,70.7,103 M70.7,10.8c22.9,0,41.5,18.6,41.5,41.5S93.6,93.9,70.7,93.9
        S29.2,75.3,29.2,52.3C29.2,29.4,47.7,10.8,70.7,10.8C70.7,10.8,70.7,10.8,70.7,10.8"
    />
  </svg>
)

export default OhpenLogo
