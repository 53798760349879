import React, { useContext } from 'react'
import Footer from '@/components/Footer'

import Paginator, { PaginatorProps } from '@/components/Paginator'
import MobileBack from '@/components/MobileBack'
import FilterContext from '@/context/FilterContext'
import FilterIconDark from '@/assets/images/svg/FilterIconDark'
import styled from '@xstyled/styled-components'
import { Left } from '../styled'
import FilterIcon from '@/assets/images/svg/FilterIcon'

const FilterButton = styled.div``
//
// @ts-ignore
const CareerOverviewTemplate: React.FC<{
  path: string
  paginatorData: PaginatorProps
  desktop: boolean
  dropdownActive: boolean
}> = ({ children, path, paginatorData, desktop, dropdownActive }) => {
  const { number, max } = paginatorData
  const filterContextInLine = useContext(FilterContext)

  return (
    <>
      <Left>
        {max > 1 && (
          <Paginator visible={!dropdownActive} number={number} max={max} />
        )}
      </Left>
      <div>{children}</div>
      <Footer
        scrollAwayMobile
        withMobileBackground={!filterContextInLine.filterActive}
        withContact={
          !desktop && number === 0 && !dropdownActive && path !== '/contact'
        }
        dropdownActive={dropdownActive}
        subPage={false}
        customIcon={
          <FilterContext.Consumer>
            {(filterContext) => (
              <FilterButton onClick={() => filterContext.updateFilterActive()}>
                {!filterContext.filterActive ? (
                  <FilterIconDark />
                ) : (
                  <FilterIcon />
                )}
              </FilterButton>
            )}
          </FilterContext.Consumer>
        }
        backButton={
          <MobileBack
            variant="light"
            delay={0}
            time={1}
            url="/careers/play"
            text="back"
          />
        }
      />
    </>
  )
}

export default CareerOverviewTemplate
