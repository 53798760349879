import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

export const Container = styled.div<{ disabled: boolean }>`
  align-items: center;
  color: #fff;
  cursor: ${(props) => !props.disabled && 'pointer'};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const LogoImage = styled.img`
  height: auto;
  width: 4.8rem;
`

export const Circle = styled.div`
  height: 10rem;
  width: 10rem;
  transition: margin 10s linear;
  transition-delay: 10s;
  margin-top: 2vh;
  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      margin-top: 2vh;
    `,
    md: css`
      margin-top: 0vh;
    `,
    /* eslint-enable sort-keys */
  })}
`

export const Text = styled.div`
  font-family: title;
  font-size: md;
  font-weight: bold;
  margin: xs;
  text-transform: uppercase;
`
