import React, { useRef, useEffect } from 'react'
import styled, { css } from '@xstyled/styled-components'
import gsap from 'gsap'
import { breakpoints } from '@xstyled/system'
import { Transition } from 'react-transition-group'

interface Props {
  variant: string
}

const Icon: React.FC<{ inProp: boolean; isOpen: boolean; variant: string }> = ({
  inProp = false,
  isOpen,
  variant,
}) => {
  const linesRef = useRef(null)
  const crossRef = useRef(null)
  const enterAnimation = useRef(null)
  const exitAnimation = useRef(null)

  const onEnter = () => {
    gsap.set(crossRef.current, {
      opacity: 1,
    })
    enterAnimation.current.restart()
  }

  const onExit = () => {
    exitAnimation.current.restart()
  }

  useEffect(() => {
    const tl = gsap.timeline()
    enterAnimation.current = gsap.timeline()
    exitAnimation.current = gsap.timeline()

    if (!inProp) {
      return
    }

    tl.fromTo(
      linesRef.current.children,
      0.5,
      {
        scaleX: 0,
        transformOrigin: 'right',
      },
      {
        scaleX: 1,
        ease: 'sine.inOut',
        stagger: {
          each: 0.05,
        },
      },
    )

    enterAnimation.current.fromTo(
      linesRef.current.children,
      0.5,
      {
        scaleX: 1,
        transformOrigin: 'right',
      },
      {
        scaleX: 0,
        ease: 'sine.inOut',
        stagger: {
          each: 0.05,
          from: 'end',
        },
      },
    )

    enterAnimation.current.fromTo(
      crossRef.current.children[0],
      0.5,
      {
        scale: 0,
        transformOrigin: 'top left',
      },
      {
        ease: 'sine.inOut',
        scale: 1,
      },
      0.5,
    )

    enterAnimation.current.fromTo(
      crossRef.current.children[1],
      0.5,
      {
        scale: 0,
        transformOrigin: 'bottom left',
      },
      {
        ease: 'sine.inOut',
        scale: 1,
      },
      0.5,
    )

    enterAnimation.current.pause()

    exitAnimation.current.to(
      crossRef.current.children[0],
      0.5,
      {
        ease: 'sine.inOut',
        scale: 0,
      },
      1,
    )

    exitAnimation.current.to(
      crossRef.current.children[1],
      0.5,
      {
        ease: 'sine.inOut',
        scale: 0,
        onComplete: () => {
          gsap.set(crossRef.current, { opacity: 0 })
        },
      },
      1,
    )

    exitAnimation.current.fromTo(
      linesRef.current.children,
      0.5,
      {
        scaleX: 0,
        transformOrigin: 'right',
      },
      {
        scaleX: 1,
        ease: 'sine.inOut',
        stagger: {
          each: 0.05,
          from: 'end',
        },
      },
    )

    exitAnimation.current.pause()

    // eslint-disable-next-line consistent-return
    return () => {
      tl.kill()
      enterAnimation.current.kill()
      exitAnimation.current.kill()
    }
  }, [inProp])

  return (
    <Container id="hamburger" variant={variant}>
      {inProp && (
        <Transition
          in={isOpen}
          onEnter={onEnter}
          onExit={onExit}
          timeout={1000}
        >
          <>
            <Graphic viewBox="0 0 47 47">
              <g strokeWidth="2">
                <g ref={linesRef} transform="translate(0 11)">
                  <path className="line" d="M0 0 L47 0" />
                  <path className="line" d="M0 11 L47 11" />
                  <path className="line" d="M0 22 L47 22" />
                </g>
              </g>
            </Graphic>
            <Graphic viewBox="0 0 47 47">
              <g className="cross" ref={crossRef}>
                <path className="line" d="M6 6 L41 41" />
                <path className="line" d="M6 41 L41 6" />
              </g>
            </Graphic>
          </>
        </Transition>
      )}
    </Container>
  )
}

const Container = styled.div<{ variant: string }>`
  visibility: hidden;
  cursor: pointer;
  transform: scale(1);
  width: 24px;
  height: 24px;
  color: secondary;
  z-index: 9;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  overflow: hidden;
  pointer-events: none;
  ${(props) =>
    /* eslint-disable indent */
    props.variant === 'header'
      ? breakpoints({
          md: css`
            visibility: visible;
          `,
        })
      : breakpoints({
          /* eslint-disable sort-keys */
          xs: css`
            visibility: visible;
          `,
          md: css`
            visibility: hidden;
          `,
          /* eslint-enable sort-keys */
        })}
`

const Graphic = styled.svg.attrs((props) => ({
  ...props,
  version: '1.1',
}))`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .cross {
    opacity: 0;
  }

  .line,
  .cross {
    stroke: currentColor;
  }
`

export default Icon
