import { navigate } from 'gatsby'
import PubSub from 'pubsub-js'
import {
  APP_ROUTE_UPDATE,
  APP_THREE_PAGE_READY,
  APP_PAGE_NEXT_AVAILABLE,
  APP_PAGE_ANIMATION_COMPLETED,
} from '@/events'

let timeOut

const appThreePageReady = PubSub.subscribe(
  APP_THREE_PAGE_READY,
  TransitionReady,
)

function TransitionReady() {
  global.cannavigate = true
}

export function CustomNavigate(location: string) {
  if (timeOut != null) {
    clearTimeout(timeOut)
  }

  if (global.cannavigate == undefined) {
    global.cannavigate = true
  }

  if (global.cannavigate) {
    navigate(location)
  }
  global.cannavigate = false
  timeOut = setTimeout(TransitionReady, 5000)
}
