import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const defaultState = {
  filterActive: false,
  filterList: [],
  groupList: [],
  activeGroupIndex: -1,
  updateActiveGroupIndex: null,
  updateFilterActive: null,
  update: null,
  clear: null,
}

const FilterContext = React.createContext(defaultState)

// export const query = graphql`
//   query allJobs {  }
// `

const FilterDataProvider: React.FC<{ children }> = ({ children }) => {
  // const data = useStaticQuery(query)
  const [filterActive, setFilterActive] = useState(false)
  // const { allGreenhouseDepartment, allGreenhouseJobPost } = data
  const allGreenhouseJobPost = {
    edges: [],
  }
  const allGreenhouseDepartment = {
    nodes: [],
  }

  const getGreenhouseLocations = () => {
    const allLocations = allGreenhouseJobPost.edges.map((job) => {
      const jobName = job.node.location.name
      return jobName
    })

    const uniqueLocations = allLocations.filter(
      (location, index) => allLocations.indexOf(location) === index,
    )

    return uniqueLocations
  }

  const prepFilterList = (newData) => {
    const preppedFilterList = newData.map((entry) => ({
      label: entry.name || entry,
      value: false,
    }))
    return preppedFilterList
  }

  const allGreenhouseLocations = getGreenhouseLocations()

  const [filterList, setFilterList] = useState([
    {
      name: 'careers.filter_location',
      items: prepFilterList(allGreenhouseLocations),
    },
    {
      name: 'careers.filter_department',
      items: prepFilterList(allGreenhouseDepartment.nodes),
    },
  ])
  const [activeGroupIndex, setActiveGroupIndex] = useState(0)
  const groupList = ['Tech', 'Product', 'Comercial', 'Operational']

  const clearFilterList = () => {
    const newFilterList = [...filterList]
    newFilterList.forEach((filterGroup) => {
      filterGroup.items.forEach((filterItem) => {
        // eslint-disable-next-line
        filterItem.value = false
      })
    })

    setFilterList(newFilterList)
  }

  const updateFilterList = (group, label, newValue = null) => {
    const newFilterList = [...filterList]
    const filterGroup = newFilterList.find(
      (listItem) => listItem.name === group,
    )

    const filterItem = filterGroup?.items?.find((item) => item.label === label)

    if (filterItem) {
      if (!newValue) {
        filterItem.value = !filterItem.value
      } else {
        filterItem.value = newValue
      }
      setFilterList(newFilterList)
    }
  }

  return (
    <FilterContext.Provider
      value={{
        filterActive,
        filterList,
        groupList,
        activeGroupIndex,
        updateActiveGroupIndex: (newGroup) => setActiveGroupIndex(newGroup),
        updateFilterActive: () => {
          setFilterActive((filterState) => !filterState)
        },
        update: (group, label, newValue) =>
          updateFilterList(group, label, newValue),
        clear: () => clearFilterList(),
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContext
export { FilterDataProvider }
