import React, { useEffect } from 'react'

import { LayoutContainer, Content, Button } from '../styled'
import Footer from '@/components/Footer'
import PubSub from 'pubsub-js'
import { APP_THREE_PAGE_READY } from '@/events'
//
// @ts-ignore
const noThreeTemplate: React.FC<{
  path: string
  desktop: boolean
  dropdownActive: boolean
}> = ({ children, dropdownActive }) => {
  useEffect(() => {
    PubSub.publish(APP_THREE_PAGE_READY, {})
  }, [])

  return (
    <>
      <LayoutContainer flex>
        <Content flex>{children}</Content>
        <Footer withContact={false} dropdownActive={dropdownActive} subPage />
      </LayoutContainer>
    </>
  )
}

export default noThreeTemplate
