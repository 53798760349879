import { useEffect, useState } from 'react'
import { APP_THREE_PAGE_READY } from '../events'
import PubSub from 'pubsub-js'

const isBrowser = typeof window !== 'undefined'

export const useContentInProp = (): boolean => {
  const [inProp, setInProp] = useState(!isBrowser)

  function onThreePageReady() {
    setInProp(true)
  }

  useEffect(() => {
    const token = PubSub.subscribe(APP_THREE_PAGE_READY, onThreePageReady)

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  return inProp
}
