import styled from '@xstyled/styled-components'

export const Container = styled.div`
  display: flex;
  opacity: 1;
  color: secondary;
  position: absolute;
  left: 50px;
`
export const ContainerHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;
  color: secondary;
  margin: auto;
`

export const ContainerHorizontalLeft = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;
  color: secondary;
`

export const Graphic = styled.svg`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`
export const ThemedPath = styled.path`
  stroke: currentColor;
`
export const Number = styled.div`
  user-select: none;
  cursor: pointer;
`
export const Numbers = styled.div`
  display: flex;
  flex-direction: column;
  font-family: title;
  font-size: 1.9rem;
  line-height: 1.9rem;
  color: currentColor;
  span {
    opacity: 0;
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%) !important;
    &:hover {
      opacity: 1 !important;
    }
  }
  &:hover {
    span {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  div {
    height: 17px;
    overflow: hidden;
    position: relative;
    width: 22px;
  }
`

export const NumbersHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  font-family: title;
  font-size: 1.9rem;
  line-height: 1.9rem;
  color: currentColor;
  /* TODO: Fix no-descending-specificity */
  /* stylelint-disable no-descending-specificity */
  span {
    opacity: 0;
    position: absolute;
    /* &:hover:not(:focus) {
      opacity: 1 !important;
    } */
  }
  &:hover:not(:focus) {
    span {
      transform: translate(0) !important;
      opacity: 1;
    }
  }
  div {
    height: 22px;
    overflow: hidden;
    position: relative;
    width: 17px;
  }
`

export const DotsHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  font-family: title;
  font-size: 1.9rem;
  line-height: 1.9rem;
  color: currentColor;

  // Make sure the current element is not visible when not hovered over
  &:hover>div:not(:hover)>span.active:nth-child(2) {
    opacity: 0;
    transform: translate(-50%, 50%);
  }
`

export const PaginationElement = styled.div`
  position: relative;
  text-align: center;

  :not(:first-child)>span:first-child {
    padding: 13px;
    :after {
      content: '';
      width: 6px;
      height: 6px;
      background: currentColor;
      border-radius: 50%;
      overflow: hidden;
      display: block;
    }
  }

  &:hover span:nth-child(2) {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`

export const PaginationTitle = styled.span`
  display: block;
  opacity: 0;
  transform: translate(-50%, 50%);
  transition: opacity 0.5s, transform 0.5s;
  text-transform: uppercase;
  position: absolute;
  left: 50%;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 16px;

  pointer-events: none;

  &.active {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`

export const PaginationDot = styled.span`
  width: 32px;
  height: 32px;
  position: relative;
  display: block;
  cursor: pointer;

  svg {
    position: relative;
    color: currentColor;
    fill: currentColor;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  :before {
    content: '';
    border-radius: 50%;
    overflow: hidden;
    display: block;
    position: absolute;
    border: 1px solid currentColor;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 17px;
    height: 17px;
    transition: transform 0.5s, opacity 0.5s, border 0.5s;
  }

  &.active:before, &:hover:before {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
`
