import { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import theme from '../../layouts/theme'
import proportion from './proportion'
import containerWidth from '@/modules/utils/containerWidth'

export const siteWrapperCss = css`
  max-width: ${containerWidth()}px;
  width: 100%;

  ${breakpoints({
    xs: css`
      max-width: 100%;
    `,
    md: css`
      max-width: ${containerWidth()}px;
      padding-right: ${proportion(50, 'desktop')};
      padding-left: ${proportion(50, 'desktop')};
    `,
    xl: css`
      padding-right: 50px;
      padding-left: 50px;
    `,
  })}
`

export const siteWrapperNoPaddingCss = css`
  padding: 0;

  width: 100%;

  ${breakpoints({
    xs: css`
      max-width: 100%;
    `,
    md: css`
      max-width: ${containerWidth()}px;
      padding-right: ${proportion(50, 'desktop')};
      padding-left: ${proportion(50, 'desktop')};
    `,
    xl: css`
      padding-right: 50px;
      padding-left: 50px;
    `,
  })}
`
