import styled from '@xstyled/styled-components'
import { Link } from '@reach/router'

export const NavigationLinkContainer = styled(Link)`
  padding-top: 50px;
  padding-bottom: 50px;
`

export const NavigationLink = styled.div`
  transition: color 140ms cubic-bezier(0.61, 1, 0.88, 1);
  color: secondary;
  display: inline-table;
  display: block;
  font-size: xs;
  font-weight: bold;
  margin-right: 4rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  &:before {
    background-color: secondary;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.3s cubic-bezier(0.61, 1, 0.88, 1);
    visibility: hidden;
    width: 100%;
  }

  &:hover {
    :before {
      transform: scaleX(1);
      visibility: visible;
    }
  }
`

export const Label = styled.div`
  position: relative;
  color: secondary;
  cursor: default;
  font-size: xs;
  font-weight: bold;
  margin-right: 4rem;
  text-transform: uppercase;
`

export const Line = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: secondary;
  transform-origin: left;
`
