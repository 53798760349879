import styled from '@xstyled/styled-components'
import React, { useState, useRef, useEffect } from 'react'
import gsap from 'gsap'
import { responsiveValue } from '@/modules/utils/responsiveValue'

const Container = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Background = ({ active }) => {
  const circleRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const radius = Math.max(width, height)
  const viewBox = `0 0 ${width} ${height}`

  useEffect(() => {
    const hamburgerEl = document.getElementById('hamburger')
    const bounds = hamburgerEl?.getBoundingClientRect()
    const startX = responsiveValue({
      default: window.innerWidth - 20,
      md: bounds ? bounds.x + bounds.width : window.innerWidth,
    })
    const startY = responsiveValue({
      default: window.innerHeight - 40,
      md: bounds ? bounds.y + bounds.height : 0,
    })

    setWidth(window.innerWidth)
    setHeight(window.innerHeight)

    const tl = gsap.timeline({ paused: true })

    tl.set(circleRef.current, {
      transformOrigin: 'center',
    })

    tl.fromTo(
      circleRef.current,
      1,
      {
        x: startX,
        y: startY,
        scale: 0,
      },
      {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
        ease: 'power2.in',
        scale: 1,
      },
    )

    if (active) {
      tl.play()
    } else {
      tl.reverse(1)
    }
  }, [active])

  return (
    <Container viewBox={viewBox}>
      <circle
        ref={circleRef}
        cx={width / 2}
        cy={height / 2}
        r={radius}
        fill="#ffffff"
      />
    </Container>
  )
}
