import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { Link } from '@reach/router'
import { breakpoints } from '@xstyled/system'
/* eslint-disable indent */

type LinkProps = {
  url?: string
  text: string
  action?: () => void
}

const ActionLink = styled.button`
  all: unset;
  cursor: pointer;
`

const LinkGroupContainer = styled.div`
  color: #1a1a1a;
  border-bottom: 1px solid;

  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      padding: 0 0 0 3px;
    `,
    md: css`
      padding: 0;
    `,
    /* eslint-enable sort-keys */
  })}
  li:not(:last-child) > & {
    margin-bottom: 40px;
  }

  position: relative;
  counter-increment: link-group;

  &:after {
    content: '(0' counter(link-group) ')';
    position: absolute;

    font-size: 1rem;

    ${breakpoints({
      /* eslint-disable sort-keys */
      xs: css`
        bottom: 1px;
        right: 0;
      `,
      md: css`
        top: 1px;
        right: 6px;
        height: 0;
      `,
      /* eslint-enable sort-keys */
    })}
  }

  h2 {
    font-family: title;
    font-weight: 400;
    line-height: 1em;
    text-transform: uppercase;

    ${breakpoints({
      /* eslint-disable sort-keys */
      xs: css`
        font-size: 3.5rem;
        display: block;
        margin-bottom: 3px;
      `,

      md: css`
        font-size: 7rem;
        display: inline-block;
        text-indent: 0.09em;
        margin: 0;
      `,
      /* eslint-enable sort-keys */
    })}
  }
`
const SublinksContainer = styled.nav`
  text-transform: uppercase;
  font-family: VisueltPro-Light, Arial, Helvetica, sans-serif;

  ul,
  li {
    list-style: none;
  }

  li {
    display: inline-block;

    &:not(:last-child):after {
      content: '/';
      ${breakpoints({
        /* eslint-disable sort-keys */
        xs: css`
          margin: 0 0.5ch;
        `,
        md: css`
          margin: 0 0.7ch;
        `,
        /* eslint-enable sort-keys */
      })}
    }
  }

  ${breakpoints({
    /* eslint-disable sort-keys */
    xs: css`
      position: relative;
      font-size: 1rem;
    `,
    md: css`
      position: absolute;
      right: 0;
      bottom: 10px;
      font-size: 1.4rem;
    `,
    /* eslint-enable sort-keys */
  })}
`
export const LinkGroup = (props: {
  primaryLink: LinkProps
  subLinks: LinkProps[]
}) => {
  const { primaryLink, subLinks } = props
  return (
    <LinkGroupContainer>
      {primaryLink.hasOwnProperty('url') && (
        <Link to={primaryLink.url}>
          <h2>{primaryLink.text}</h2>
        </Link>
      )}
      {primaryLink.hasOwnProperty('action') && (
        <ActionLink onClick={primaryLink.action}>
          <h2>{primaryLink.text}</h2>
        </ActionLink>
      )}

      <SublinksContainer>
        <ul>
          {subLinks.map((subLink, index) => (
            <li key={`key-${index + 1}`}>
              {subLink.hasOwnProperty('url') &&
                subLink.url.substring(0, 4) === 'http' && (
                  <a href={subLink.url}>{subLink.text}</a>
                )}
              {subLink.hasOwnProperty('url') &&
                subLink.url.substring(0, 4) !== 'http' && (
                  <Link to={subLink.url || '#'} onClick={subLink.action}>
                    {subLink.text}
                  </Link>
                )}
              {!subLink.hasOwnProperty('url') && (
                <ActionLink onClick={subLink.action}>{subLink.text}</ActionLink>
              )}
            </li>
          ))}
        </ul>
      </SublinksContainer>
    </LinkGroupContainer>
  )
}
