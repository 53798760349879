import React from 'react'

const FilterIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    x="0"
    y="0"
    viewBox="0 0 90 90"
  >
    <path
      fill="#fff"
      d="M77.8 30H38.2c.2 1 .4 1.6.4 2.4 0 .9-.2 1.6-.5 2.6h39.7c1.1 0 2-1.1 2-2.5s-.9-2.5-2-2.5zM22.9 32.4c0-.8.1-1.4.4-2.4h-11c-1.1 0-2 1.1-2 2.5s.9 2.5 2 2.5h11.1c-.4-1-.5-1.7-.5-2.6zM30.7 28.9c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.2-3.2 3.2s-3.2-1.4-3.2-3.2c0-1.8 1.4-3.2 3.2-3.2m0-3c-3.4 0-6.2 2.8-6.2 6.2 0 3.4 2.8 6.2 6.2 6.2s6.2-2.8 6.2-6.2c0-3.5-2.8-6.2-6.2-6.2zM50.7 57.4c0-.8.1-1.4.4-2.4H12.2c-1.1 0-2 1.1-2 2.5s.9 2.5 2 2.5h39c-.3 0-.5-1.7-.5-2.6zM77.8 55H66.1c.2 1 .4 1.6.4 2.4 0 .9-.2 2.6-.5 2.6h11.8c1.1 0 2-1.1 2-2.5s-.9-2.5-2-2.5zM58.6 54.7c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.2-3.2 3.2-1.8 0-3.2-1.4-3.2-3.2 0-1.7 1.4-3.2 3.2-3.2m0-3c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2c3.4 0 6.2-2.8 6.2-6.2s-2.8-6.2-6.2-6.2z"
    />
  </svg>
)

export default FilterIcon
