import React, { useState, useLayoutEffect } from 'react'
import { globalHistory } from '@reach/router'
import PubSub from 'pubsub-js'
import { ThemeProvider } from '@xstyled/styled-components'
import ResetStyle from '@/components/Reset'

import {
  APP_ROUTE,
  APP_PAGE_ANIMATION_STARTED,
  APP_MENU_TOGGLE,
  APP_PAGE_CHANGE_THEME,
  APP_PAGE_NEXT_AVAILABLE,
  APP_MENU_ANIMATION_STATE,
} from '@/events'
import { useSitePages } from '@/hooks/useSitePages'
import { HamburgerWrap, HamburgerWrapInner } from './styled'
import lightTheme from './theme'
import darkTheme from './theme-dark'
import { getSanitizedLocation } from '@/modules/utils/location'
import { DropdownMenu } from '@/components/DropdownMenu'
import ReactGA from 'react-ga'
import useSiteMetadata from '@/hooks/useSiteMetadata'
import { Helmet } from 'gatsby-plugin-react-i18next'
import LayoutTemplateContainer from './LayoutTemplateContainer'
import ThreeLoader from './ThreeLoader'
import Hamburger from '@/components/Hamburger'

// import CookieBot from 'react-cookiebot/lib/CookieBot'

const debugEvents = false
let isInitialized = false

// const domainGroupId = 'b0af6a27-d8fa-41fc-b3e8-5f77698ec17e' // rk test
// const domainGroupId = '4a6f1615-ef1a-47b8-9171-1c0568b0edb3' // Ohpen

let token
let menuToken
let menuBackgroundToken
let themeUpdateToken
let debugEventsToken
let dropDown

const sitePages = useSitePages()

// @ts-ignore
const Index: React.FC = ({ children, location: currentLocation }) => {
  // const [hasCookieBot, setHasCookieBot] = useState(undefined)
  const siteMetadata = useSiteMetadata()

  const [isSubPage, setIsSubPage] = useState(false)
  const [title, setTitle] = useState('')
  const [dropdownActive, setDropdownActive] = useState(false)
  const [dropdownBackgroundActive, setdropdownBackgroundActive] = useState(
    false,
  )
  const [state, setState] = useState({
    max: 0,
    number: 0,
  })

  const [path, setPath] = useState('')

  const [theme, setTheme] = useState('light')

  const checkDropDown = () => {
    PubSub.publish(APP_MENU_ANIMATION_STATE, false)
    PubSub.publish(APP_MENU_TOGGLE, false)
  }
  const getTitleFromPath = (currentPath) => {
    const pageMeta = siteMetadata && siteMetadata.pageMeta
    const currentPage =
      pageMeta && pageMeta.pages.find((page) => page.url === currentPath)
    if (!currentPage) {
      return 'Ohpen, a cloud-native core banking platform'
    }
    let pageTitle
    if (currentPage.title) {
      pageTitle = currentPage.title
    } else {
      pageTitle = 'Ohpen, a cloud-native core banking platform'
    }
    if (currentPage?.noPrefix) {
      return pageTitle
    }
    return `Ohpen - ${pageTitle}`
  }
  const onRouteUpdate = (topic, data, dropDownVar) => {
    PubSub.publish(APP_PAGE_ANIMATION_STARTED, data)
    if (dropDownVar) {
      checkDropDown()
    }
    let currentPath = getSanitizedLocation(window.location.pathname)
    if (currentPath === '/') {
      currentPath = '/home'
    }
    setPath(currentPath)
    setTitle(getTitleFromPath(currentPath))
    ReactGA.pageview(currentPath)
    const urlParts = currentPath.split('/').slice(1)
    const mainSection = urlParts[0]
    const pages = sitePages[mainSection] as string[]
    if (!pages) {
      return
    }
    const subSectionNumber = pages.indexOf(currentPath)
    if (subSectionNumber === -1) {
      setIsSubPage(true)
      PubSub.publish(APP_PAGE_NEXT_AVAILABLE, false)
      return
    }
    setIsSubPage(false)
    PubSub.publish(APP_PAGE_NEXT_AVAILABLE, subSectionNumber < pages.length - 1)
    setState({
      max: pages.length,
      number: subSectionNumber,
    })
  }

  const location = getSanitizedLocation(currentLocation.pathname)
  const onMenuBackgroundToggle = (eventName, newDropdownState) => {
    setdropdownBackgroundActive(newDropdownState)
  }
  const onMenuToggle = (eventName, newDropdownState) => {
    dropDown = newDropdownState
    setDropdownActive(newDropdownState)
    const mobileFooter = document.getElementById('mobile-footer')
    if (mobileFooter) {
      mobileFooter.style.display = newDropdownState ? 'none' : 'block'
    }
  }
  const onThemeUpdate = (name, newTheme) => {
    setTheme(newTheme.theme)
  }

  useLayoutEffect(() => {
    if (isInitialized) {
      return undefined
    }

    isInitialized = true

    themeUpdateToken = PubSub.subscribe(APP_PAGE_CHANGE_THEME, onThemeUpdate)
    token = PubSub.subscribe(
      APP_ROUTE,
      (name, data) => onRouteUpdate(name, data, dropDown),
      // eslint-disable-next-line
    )
    if (debugEvents) {
      debugEventsToken = PubSub.subscribe('app', (e, data) => {
        console.log('app debug event', e, data)
      })
    }

    // todo: clean this up when possible, no two pubsub events needed in near future
    menuBackgroundToken = PubSub.subscribe(
      APP_MENU_ANIMATION_STATE,
      onMenuBackgroundToggle,
    )
    menuToken = PubSub.subscribe(APP_MENU_TOGGLE, onMenuToggle)
    // todo: clean this up when possible, no two pubsub events needed in near future

    const removeListener = globalHistory.listen(() => {
      setDropdownActive(false)
    })

    return () => {
      PubSub.unsubscribe(token)
      PubSub.unsubscribe(menuToken)
      PubSub.unsubscribe(themeUpdateToken)
      PubSub.unsubscribe(menuBackgroundToken)
      removeListener()

      if (debugEvents) {
        PubSub.unsubscribe(debugEventsToken)
      }
    }
  }, [])

  const { number, max } = state
  /* eslint-disable indent */
  /*
    seperate the layouts based on:
    is a paginator needed.
    default or custom paginator
  */
  return (
    <>
      <Helmet>
        <title> {title ? `${title}` : 'Ohpen'}</title>
      </Helmet>
      <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
        <ResetStyle />
        <ThreeLoader currentLocation={currentLocation} />
        <LayoutTemplateContainer
          paginatorData={{ max, number }}
          currentPath={location}
        >
          {children}
        </LayoutTemplateContainer>
        <DropdownMenu active={dropdownBackgroundActive} />
        <HamburgerWrap visible={dropdownBackgroundActive}>
          <HamburgerWrapInner>
            <Hamburger
              inProp
              isOpen={dropdownBackgroundActive}
              variant="footer"
            />
          </HamburgerWrapInner>
        </HamburgerWrap>
        {/* end */}
      </ThemeProvider>
    </>
  )
}

export default Index
