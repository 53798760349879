import React, { useRef, useEffect, useState } from 'react'
import PubSub from 'pubsub-js'
import gsap from 'gsap'
import { Transition } from 'react-transition-group'
import { APP_PAGE_ANIMATION, APP_MENU_TOGGLE } from '../../events'
import {
  HeaderWrapper,
  Navigation,
  LogoContainer,
  Links,
  HamburgerContainer,
} from './styled'
import Logo from '../Logo'
import LinkOrText from './components/LinkOrText'
import Hamburger from '../Hamburger'
import { useContentInProp } from '@/hooks/useContentInProp'
import { globalHistory } from '@reach/router'
import containerWidth from '@/modules/utils/containerWidth'

const Header: React.FC<{
  title: string
  currentLocation: string
  variant?: string
}> = ({ currentLocation: initialLocation }) => {
  const inProp = useContentInProp()
  const linksRef = useRef(null)
  const [dropdownActive, setDropdownActive] = useState(false)
  const [, setAnimate] = useState(false)
  const [location, setLocation] = useState(initialLocation)
  const containerRef = useRef(null)
  const isServer = typeof window === 'undefined'

  const onMenuToggle = (eventName, isOpen) => {
    setDropdownActive(isOpen)
  }

  const onPageAnimation = (topic, data) => {
    const isFirstRender = data && data.previousLocation === null
    const isFirstHomepageRender = isFirstRender && location === '/'
    setAnimate(isFirstHomepageRender)
    if (data && data.location) {
      setLocation(data.location)
    }
  }

  const onResize = () => {
    if (!isServer) {
      containerRef.current.style.maxWidth = `${containerWidth()}px`
    }
  }

  const addDomEvents = () => {
    window.addEventListener('resize', onResize)
  }

  const removeDomEvents = () => {
    window.removeEventListener('resize', onResize)
  }

  useEffect(() => {
    addDomEvents()
    onResize()
    const token = PubSub.subscribe(APP_PAGE_ANIMATION, onPageAnimation)
    const menuToken = PubSub.subscribe(APP_MENU_TOGGLE, onMenuToggle)
    const removeListener = globalHistory.listen(() => {
      setDropdownActive(false)
    })

    return () => {
      removeDomEvents()
      removeListener()
      PubSub.unsubscribe(token)
      PubSub.unsubscribe(menuToken)
    }
  }, [])

  function onEnter() {
    const links = linksRef.current.children

    gsap.set(linksRef.current, {
      opacity: 1,
    })

    gsap.fromTo(
      links,
      0.4,
      {
        opacity: 0,
      },
      {
        stagger: 0.002,
        opacity: 1,
      },
    )
  }

  return (
    <Transition in={inProp} onEnter={onEnter} timeout={0}>
      <HeaderWrapper ref={containerRef}>
        <Navigation>
          <Links ref={linksRef}>
            <LinkOrText
              to="/our-platform"
              currentLocation={location}
              inProp={inProp}
              label="our platform"
            />
            <LinkOrText
              to="/our-dna"
              currentLocation={location}
              inProp={inProp}
              label="Our DNA"
            />
          </Links>
        </Navigation>
        <LogoContainer>
          <Logo currentLocation={location} />
        </LogoContainer>
        <HamburgerContainer>
          <Hamburger isOpen={dropdownActive} inProp={inProp} variant="header" />
        </HamburgerContainer>
      </HeaderWrapper>
    </Transition>
  )
}

export default Header
