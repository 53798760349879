import theme from '../../layouts/theme'

interface Mapping {
  [key: string]: number
}

function isBrowser() {
  return typeof window !== 'undefined'
}

export function responsiveValue(mapping: Mapping): number {
  const keys = Object.keys(theme.breakpoints)
  let result = mapping.default

  if (!isBrowser()) {
    return mapping.default
  }

  keys.forEach((key) => {
    const breakpoint = theme.breakpoints[key]
    const value = mapping[key]
    const match = window.matchMedia(`(min-width: ${breakpoint}px)`).matches

    if (match && value) {
      result = value
    }
  })

  return result
}
