import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import templates from './templates'
import { PaginatorProps } from '@/components/Paginator'
import getIsServer from '@/modules/utils/getIsServer'
import { APP_MENU_TOGGLE, APP_PAGE_ANIMATION_COMPLETED } from '@/events'
import { globalHistory } from '@reach/router'
import PubSub from 'pubsub-js'
import { AnimatePresence, motion } from 'framer-motion'
import Header from '@/components/Header'
import { HeaderContainer } from './styled'

// this file will handle the seperation of the different type of layouts

/* the selection is done based on an array of objects based on possible routes
    not being included in the array will result in selecting a default fallback.

    however, I @David, much prefer to specificly add the default as a named template instead of
    defaulting it, as that makes any and all possible needed refactors or resourcing, a lot easier.

    the longest route that matches will be selected in the end.
    so make sure to think about specificity.
    i.e. having a followup/hotel route will overwrite an hotel route.
    but, having a home/followup route, will allow us to overwrite a default template
    with a subpage template by creating a route template for '/hotel'
*/

const routeTemplates = [
  {
    route: '/home',
    template: 'default',
  },
  {
    route: '/followup/meet',
    template: 'subPage',
  },
  {
    route: '/followup/hotel',
    template: 'subPage',
  },

  {
    route: '/our-dna',
    template: 'default',
  },
  {
    route: '/our-dna/ohpeneers',
    template: 'default',
  },
  {
    route: '/our-dna/social/planet',
    template: 'subPage',
  },
  {
    route: '/our-dna/social/people',
    template: 'subPage',
  },
  {
    route: '/our-dna/join/our-purpose',
    template: 'subPage',
  },
  {
    route: '/our-dna/join/benefits',
    template: 'subPage',
  },
  {
    route: '/our-dna/join/innovation',
    template: 'subPage',
  },
  {
    route: '/our-dna/join/diverse-teams',
    template: 'subPage',
  },
  {
    route: '/our-dna/values/extraordinarity',
    template: 'subPage',
  },
  {
    route: '/our-dna/values/reliable',
    template: 'subPage',
  },
  {
    route: '/our-dna/values/companionship',
    template: 'subPage',
  },
  {
    route: '/our-dna/values/initiative',
    template: 'subPage',
  },
  {
    route: '/our-dna/values/persevere',
    template: 'subPage',
  },
  {
    route: '/sponsor/marks-journey',
    template: 'subPage',
  },
  {
    route: '/our-platform',
    template: 'default',
  },
  {
    route: '/our-platform/modules',
    template: 'default',
  },
  {
    route: '/our-platform/all-modules',
    template: 'module',
  },
  {
    route: '/our-platform/module',
    template: 'module',
  },
  {
    route: '/latest-insights',
    template: 'news',
  },
  {
    route: '/latest-insights/overview',
    template: 'newsOverview',
  },
  {
    route: '/latest-insights/article',
    template: 'newsArticle',
  },
  {
    route: '/clients-and-cases',
    template: 'default',
  },
  {
    route: '/clients-and-cases/cases',
    template: 'subPage',
  },
  {
    route: '/ohpeneer-overview',
    template: 'default',
  },
]

const isServer = getIsServer()

let menuToken
let TemplateComponent

const LayoutTemplateContainer: React.FC<{
  currentPath: string
  paginatorData: PaginatorProps
}> = ({ children, currentPath, paginatorData }) => {
  if (!currentPath) {
    return null
  }

  // view & resize logic
  const [desktop, setDesktop] = useState(null)
  const timeoutRef = useRef(null)

  const onResize = () => {
    if (!isServer) {
      if (window.innerWidth > 750) {
        setDesktop(true)
      } else {
        setDesktop(false)
      }
    }
  }

  if (desktop === null) {
    onResize()
  }

  const addDomEvents = () => {
    if (!isServer) {
      window.addEventListener('resize', onResize)
    }
  }

  const removeDomEvents = () => {
    if (!isServer) {
      window.removeEventListener('resize', onResize)
    }
  }

  useEffect(() => {
    addDomEvents()

    return () => {
      removeDomEvents()
      clearTimeout(timeoutRef.current)
    }
  }, [])
  // end view & resize logic

  // dropdown Logic

  const [dropdownActive, setDropdownActive] = useState(false)

  const onMenuToggle = (eventName, newDropdownState) => {
    setDropdownActive(newDropdownState)
    const mobileFooter = document.getElementById('mobile-footer')
    if (mobileFooter) {
      mobileFooter.style.display = newDropdownState ? 'none' : 'block'
    }
  }

  useLayoutEffect(() => {
    menuToken = PubSub.subscribe(APP_MENU_TOGGLE, onMenuToggle)

    const removeListener = globalHistory.listen(() => {
      setDropdownActive(false)
    })

    return () => {
      PubSub.unsubscribe(menuToken)
      removeListener()
    }
  }, [])

  // end of dropdown logic

  const getMatchingRoutes = () => {
    const filteredRoutes = routeTemplates.filter((routeTemplate) => {
      const { route } = routeTemplate
      return currentPath?.indexOf(route) !== -1
    })
    if (filteredRoutes.length === 0) {
      return null
    }
    return filteredRoutes
  }
  const getCurrentRoute = () => {
    const matchingRoutes = getMatchingRoutes()
    if (!matchingRoutes) {
      return null
    }
    if (matchingRoutes.length === 1) {
      return matchingRoutes[0]
    }
    const bestMatch = matchingRoutes.reduce((a, b) => {
      const aRouteLength = a.route.length
      const bRouteLength = b.route.length
      return aRouteLength > bRouteLength ? a : b
    })
    return bestMatch
  }
  const currentRoute = getCurrentRoute()

  useEffect(() => {
    if (!currentPath) {
      return
    }
    TemplateComponent = templates[currentRoute?.template || 'default']

    timeoutRef.current = setTimeout(() => {
      PubSub.publish(APP_PAGE_ANIMATION_COMPLETED)
    }, 600)
  }, [currentPath])

  return (
    <>
      <HeaderContainer>
        <Header title="Ohpen" variant="dark" currentLocation={currentPath} />
      </HeaderContainer>

      {TemplateComponent && (
        <TemplateComponent
          dropdownActive={dropdownActive}
          desktop
          paginatorData={paginatorData}
          path={currentPath}
        >
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => {
              PubSub.publish(APP_PAGE_ANIMATION_COMPLETED)
            }}
          >
            <motion.main key={currentPath}>{children}</motion.main>
          </AnimatePresence>
        </TemplateComponent>
      )}
    </>
  )
}

export default LayoutTemplateContainer
