/* TODO:

  - lijn dunner
  - schadow minder
  - beginnen groter

*/

const ThreeMaskCanvas = function () {
  this.canvas = document.createElement("canvas")
  this.canvas.width = window.innerWidth
  this.canvas.height = window.innerHeight

  this.canvas.style.width = "20%"
  this.canvas.style.height = "20%"
  this.canvas.style.position = "fixed"
  this.canvas.style.zIndex = "9999"
  this.canvas.style.bottom = "0px"
  this.canvas.style.left = "0px"
  // document.body.appendChild(this.canvas);

  this.maskProps = { x: 0.5, y: 0.5, ringX: 0.5, ringY: 0.5, ringPos: 0, pos: 0, ripplePos: 0, particlePos: 0 }

  this.setSize = function (width, height) {
    this.canvas.width = width
    this.canvas.height = height
  }

  this.renderMask = function () {
    var maxCanvasSize = Math.max(this.canvas.width, this.canvas.height);

    // draw canvas mask

    var maskX = this.canvas.width * this.maskProps.x;
    var maskY = this.canvas.height * this.maskProps.y;
    var ringX = this.canvas.width * this.maskProps.ringX;
    var ringY = this.canvas.height * this.maskProps.ringY;
    var radius = maxCanvasSize * 1.5 * (1 - this.maskProps.pos);
    var radius2 = maxCanvasSize * 1.5 * (1 - this.maskProps.ripplePos);

    var ctx = this.canvas.getContext("2d");
    ctx.fillStyle = "#000000";
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    ctx.globalCompositeOperation = "screen";

    // draw ripple    
    var grd = ctx.createRadialGradient(maskX, maskY, radius, maskX, maskY, radius2);
    grd.addColorStop(0, "rgba(0,255,0,0)");
    grd.addColorStop(0.5, "rgba(0,255,0,0.5)");
    grd.addColorStop(1, "rgba(0,255,0,0)");
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    var grd = ctx.createRadialGradient(maskX, maskY, radius * 0.6, maskX, maskY, radius2 * 0.6);
    grd.addColorStop(0, "rgba(0,255,0,0)");
    grd.addColorStop(0.5, "rgba(0,255,0,0.4)");
    grd.addColorStop(1, "rgba(0,255,0,0)");
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    //var ringPosAlpha = (Math.sin(this.maskProps.ringPos * Math.PI) + 1) / 2;
    var ringPosAlpha = Math.max(0, Math.min(1, (this.maskProps.ringPos - 0.2) * 4));
    var ringPosDistortion = Math.max(0, Math.min(1, this.maskProps.ringPos * 2));
    var ringPosSize = this.maskProps.ringPos * 0.7 + 0.1;

    // distortion circle shadow
    var grd = ctx.createRadialGradient(ringX + maxCanvasSize * 0.01, ringY + maxCanvasSize * 0.01, maxCanvasSize * ringPosSize, ringX + maxCanvasSize * 0.005, ringY + maxCanvasSize * 0.005, maxCanvasSize * (ringPosSize + 0.05 + 0.015));
    grd.addColorStop(0, "rgba(0,0,255,0)");
    grd.addColorStop(0.5, "rgba(0,0,255," + ringPosAlpha * 0.2 + ")");
    grd.addColorStop(1, "rgba(0,0,255,0)");
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);


    ctx.globalCompositeOperation = "source-over";
    // distortion circle

    var red = Math.round(ringPosDistortion * 255);

    ctx.fillStyle = "rgb(" + red + ",0,0)";
    ctx.beginPath();
    ctx.arc(ringX, ringY, maxCanvasSize * ringPosSize, 0, 2 * Math.PI);
    ctx.arc(ringX, ringY, maxCanvasSize * (ringPosSize + 0.065), 0, 2 * Math.PI, true);
    ctx.closePath();
    ctx.fill();


    // draw mask
    ctx.globalCompositeOperation = "destination-in";

    ctx.fillStyle = "#ff0000";
    ctx.beginPath();
    ctx.arc(maskX, maskY, radius, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    ctx.globalCompositeOperation = "source-over";
  }
}

export default ThreeMaskCanvas