import React, { useRef, useState, useEffect } from 'react'
import styled from '@xstyled/styled-components'
import PubSub from 'pubsub-js'
import { APP_MENU_TOGGLE, APP_MENU_ANIMATION_STATE } from '@/events'
import debounce from 'lodash.debounce'
import Icon from './components/Icon'

const HamburgerContainer: React.FC<{
  inProp: boolean
  isOpen: boolean
  variant: string
}> = ({ inProp, isOpen, variant = 'header' }) => {
  const [readyForUpdate, setReadyForUpdate] = useState(true)
  const publishOpenState = (newState) => {
    setReadyForUpdate(true)
    PubSub.publish(APP_MENU_TOGGLE, newState)
  }
  const publishAnimatingState = (newState) => {
    setReadyForUpdate(true)
    PubSub.publish(APP_MENU_ANIMATION_STATE, newState)
  }
  const OnHandleMenuToggle = (newState: boolean) => {
    if (readyForUpdate) {
      setReadyForUpdate(false)
      if (newState) {
        PubSub.publish(APP_MENU_ANIMATION_STATE, newState)
        setTimeout(() => publishOpenState(newState), 1000)
      } else {
        publishOpenState(newState)
        setTimeout(() => publishAnimatingState(newState), 100)
      }
    }
  }

  const menuToggleDebounced = useRef(
    debounce((newState) => OnHandleMenuToggle(newState), 250, {
      leading: true,
      trailing: false,
    }),
  ).current

  const onToggleMenu = (newState: boolean) => {
    menuToggleDebounced(newState)
  }

  return (
    <ClickContainer
      data-title="menu"
      onClick={() => onToggleMenu(!isOpen)}
      data-url=" "
    >
      <Icon inProp={inProp} isOpen={isOpen} variant={variant} />
    </ClickContainer>
  )
}

const ClickContainer = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 25px;
  position: absolute;
  cursor: pointer;
  right: -5;
`

export default HamburgerContainer
