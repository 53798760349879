import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { getSanitizedLocation } from '@/modules/utils/location'
import { clientOnly } from '../utils/clientOnly'

function getCookieBotKey(hostname) {
  const liveUrls = ['ohpen.com', 'ohpen.staging.robotkittens.nl']

  if (liveUrls.includes(hostname)) {
    return '4a6f1615-ef1a-47b8-9171-1c0568b0edb3'
  }

  return 'b0af6a27-d8fa-41fc-b3e8-5f77698ec17e'
}

function isTrackingEnabled() {
  return window['CookieBot']?.consent?.statistics
}

const CookieBot = () => {
  const cookieBotKey = getCookieBotKey(window.location.hostname)

  useEffect(() => {
    const head = document.head
    const script = document.createElement('script')

    script.setAttribute('id', 'CookieBot')
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
    script.setAttribute('data-cbid', cookieBotKey)
    script.setAttribute('data-blockingmode', 'none')
    script.setAttribute('type', 'text/javascript')

    head.insertBefore(script, head.firstChild)
  }, [])

  return (
    <script
      id="CookieDeclaration"
      src={`https://consent.cookiebot.com/${cookieBotKey}/cd.js`}
      type="text/javascript"
      async
    />
  )
}

export const TrackingContainer = (props) => {
  const [trackingEnabled, setTrackingEnabled] = useState(false)
  const [trackingInstalled, setTrackingInstalled] = useState(false)

  function onChangePreferences() {
    console.log('IS TRACKING ENABLED', isTrackingEnabled())
    setTrackingEnabled(isTrackingEnabled())
  }

  useEffect(() => {
    onChangePreferences()

    window.addEventListener('CookiebotOnAccept', onChangePreferences)
    window.addEventListener('CookiebotOnLoad', onChangePreferences)
    window.addEventListener('CookiebotOnDecline', onChangePreferences)
  }, [])

  useEffect(() => {
    if (!trackingEnabled || trackingInstalled) return

    console.log('INITIALIZING TRACKING')
    // const trackingId = 'UA-177210608-1' // RK test
    const trackingId = 'UA-15680303-3' // Ohpen
    ReactGA.initialize(trackingId, { debug: false })
    ReactGA.pageview(getSanitizedLocation(window.location.pathname))

    var s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = '//js.hs-scripts.com/8709561.js'
    s.async = true
    s.id = 'hs-script-loader'
    document.head.appendChild(s)

    setTrackingInstalled(true)
  }, [trackingEnabled, trackingInstalled])

  return <CookieBot />
}
