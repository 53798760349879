import React, { useRef, useLayoutEffect } from 'react'

import Three from '@/modules/Three'
import ThreeManager from '@/modules/ThreeManager'

import { ThreeContainer } from './styled'
import { HistoryLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

let isInitialized = false
let three: Three = null
let threeManager: ThreeManager = null

// @ts-ignore
const ThreeLoader: React.FC<{ currentLocation: HistoryLocation }> = ({
  currentLocation,
}) => {
  // const [hasCookieBot, setHasCookieBot] = useState(undefined)
  const threeContainer = useRef(null)
  const data = useStaticQuery<GatsbyTypes.cmsDataModuleThreeQuery>(graphql`
    query cmsDataModuleThree {
      allCmsModule(filter: { status: { eq: "published" } }) {
        edges {
          node {
            id
            slug
            body
            intro
            title
          }
        }
      }
    }
  `)

  useLayoutEffect(() => {
    if (isInitialized) {
      return
    }
    isInitialized = true
    three = new Three()
    threeManager = new ThreeManager(threeContainer.current)
    threeManager.setCallbacks({
      onInit: three.onInit,
      onPointerDown: three.onPointerDown,
      onPointerMove: three.onPointerMove,
      onPointerUp: three.onPointerUp,
      onResize: three.onResize,
      onRouteChange: three.onRouteChange,
      onUpdate: three.onUpdate,
    })
    threeManager.init(currentLocation, data)
  }, [])
  return <ThreeContainer ref={threeContainer} id="three-container" />
}

export default ThreeLoader
