import React from 'react'
import PropTypes from 'prop-types'
import Dotted from './Dotted'

export interface PaginatorProps {
  number: number
  max: number
  type?: 'dotted'
  visible?: boolean
}

/**
 * @component Paginator
 * @param {number} props.number posistion of section array
 * @param {number} props.max length of section array
 * @param {'dotted'} [props.type] how to paginator needs to be shown
 * @param {number} [props.animate] animation to new logation
 */

const Paginator: React.FC<PaginatorProps> = ({ visible, type, ...props }) => {
  if (!visible) {
    return null
  }

  return <Dotted {...props} />

}

Paginator.defaultProps = {
  visible: true,
  type: 'dotted',
}

Paginator.propTypes = {
  number: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['dotted']),
}

export default Paginator
