export default {
  borders: {
    dark: '1px solid #ffffff',
    light: '1px solid #000000',
  },
  borderStyles: {
    solid: 'solid',
  },
  /* eslint-disable sort-keys */
  borderWidths: {
    md: '0.0625rem', // 1px
    lg: '0.125rem', // 2px
  },
  proportions: {
    handheld: 750,
    desktop: 1200,
  },
  breakpoints: {
    xs: 0,
    // sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
    xxxl: 1680,
  },
  /* eslint-enable sort-keys */
  // Using http://chir.ag/projects/name-that-color/#ffffff
  colors: {
    dark: '#000000',
    shade: '#323232',
    denim: '#1a77dd',
    light: '#ffffff',
    grayed: '#efefef',
    primary: '#fff',
    secondary: '#000',
    decoration: '#13322B',
  },
  fonts: {
    primary: 'Arial, Helvetica, sans-serif',
    // title: 'Arial, Helvetica, sans-serif',
    // title: 'Univers LT Std',
    title: 'Univers LT Ultra Condensed',
  },
  /* eslint-disable sort-keys */
  fontSizes: {
    xxxs: '1.1rem', // 12px
    // xxs: '0.8125rem', // 13px
    xs: '1.4rem', // 14px
    // sm: '0.9375rem', // 15px
    md: '1.6rem', // 16px
    lg: '1.8rem',
    xl: '4rem',
    xxl: '6.7rem',
    xxxl: '11.5rem',
    xxxxl: '18rem',
  },
  /* eslint-enable sort-keys */
  fontWeights: {
    bold: 'bold',
    normal: 'normal',
  },
  /* eslint-disable sort-keys */
  space: {
    xs: '0.2rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xxl: '3rem',
    xxxl: '4rem',
  },
  /* eslint-enable sort-keys */
}
