import React from 'react'

import { LayoutContainer, Content } from '../styled'
import Footer from '@/components/Footer'

//
// @ts-ignore
const ModuleTemplate: React.FC<{
  path: string
  desktop: boolean
  dropdownActive: boolean
}> = ({ children, dropdownActive }) => (
  <>
    <LayoutContainer flex>
      <Content flex>{children}</Content>
      <Footer withContact={false} dropdownActive={dropdownActive} subPage />
    </LayoutContainer>
  </>
)

export default ModuleTemplate
