import React, { useState, useLayoutEffect } from 'react'
import PubSub from 'pubsub-js'
import { APP_PAGE_CHANGE_THEME } from '../../events'
import { Container } from './styled'
import { CustomNavigate } from '@/modules/utils/CustomNavigate'
import OhpenLogo from '../../assets/images/svg/OhpenLogo'

let currentTheme

const Logo: React.FC<{ currentLocation: string }> = ({ currentLocation }) => {
  const [theme, setTheme] = useState('light')

  const onLogoClick = () => {
    if (currentLocation !== '/') {
      CustomNavigate('/')
    }
  }
  const onThemeUpdate = (token, data) => {
    setTheme(data)
  }

  useLayoutEffect(() => {
    currentTheme = PubSub.subscribe(APP_PAGE_CHANGE_THEME, onThemeUpdate)
    return () => PubSub.unsubscribe(currentTheme)
  }, [])

  return (
    <Container disabled={currentLocation === '/'} onClick={() => onLogoClick()}>
      <OhpenLogo color={theme.theme === 'dark' ? '#000000' : '#ffffff'} />
    </Container>
  )
}

export default Logo
