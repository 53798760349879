import DefaultTemplate from './DefaultTemplate'
import NewsTemplate from './NewsTemplate'
import NewsOverviewTemplate from './NewsOverviewTemplate'
import ModuleTemplate from './ModuleTemplate'

import SubPageTemplate from './SubPageTemplate'
import noThreeTemplate from './NoThreeRoutingTemplate'
import noThreeTemplateNews from './NoThreeRoutingNews'
import CareerOverviewTemplate from './CareerOverviewTemplate'

export default {
  default: DefaultTemplate,
  ohpeneer: SubPageTemplate,
  module: ModuleTemplate,
  subPage: SubPageTemplate,
  news: NewsTemplate,
  newsOverview: NewsOverviewTemplate,
  careerOverview: CareerOverviewTemplate,
  newsArticle: noThreeTemplateNews,
  vacency: DefaultTemplate,
  jobTemplate: noThreeTemplate,
}
