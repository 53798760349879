// @ts-nocheck
/* tslint:disable */

const CheckWebGLSupport = function () {
  var webGLSupport = {
    gl1: false,
    gl2: false
  };

  var message = "";
  const gl = document.createElement('canvas').getContext('webgl');
  const gl2 = document.createElement('canvas').getContext('webgl2');
  if (!gl) {
    message += ('your browser/OS/drivers do not support WebGL1<br>');
  } else {
    webGLSupport.gl1 = true;
    message += ('supports WebGL1<br>');
  }
  if (!gl2) {
    message += ('your browser/OS/drivers do not support WebGL2');
  } else {
    webGLSupport.gl2 = true;
    message += ('supports WebGL2');
  }

  // var messageDiv = document.createElement('div');
  // messageDiv.style.position = "fixed";
  // messageDiv.style.color = "#ffffff";
  // messageDiv.style.bottom = "0px";
  // messageDiv.style.fontSize = "10px";
  // messageDiv.innerHTML = message;
  // document.body.appendChild(messageDiv);

  return webGLSupport;
}

export default CheckWebGLSupport