import React from 'react'

import { LayoutContainer, Content } from '../styled'
import Footer from '@/components/Footer'

import { PaginatorProps } from '@/components/Paginator'

//
// @ts-ignore
const NewsTemplate: React.FC<{
  path: string
  paginatorData: PaginatorProps
  desktop: boolean
  dropdownActive: boolean
}> = ({ children, path, paginatorData, desktop, dropdownActive }) => {
  const { number } = paginatorData
  return (
    <>
      <LayoutContainer flex>
        <Content flex>{children}</Content>
        <Footer
          withContact={
            !desktop && number === 0 && !dropdownActive && path !== '/contact'
          }
          dropdownActive={dropdownActive}
          subPage={false}
        />
      </LayoutContainer>
    </>
  )
}

export default NewsTemplate
