// @ts-nocheck
/* tslint:disable */

import * as THREE from 'three'
import { GLTFLoader } from './GLTFLoader'
import { OBJLoader } from './OBJLoader'
import { SimplexNoise } from './SimplexNoise'
import { TweenMax, Power3, Power2, Power1, Linear } from 'gsap'
// import { texts } from '@/texts/modules'

const processDynamicParticles = function (
  polyMesh,
  lerpValue,
  maxSpeed,
  deltaTime,
) {
  let vect, i, speedScale, lockedFactor, lerpOffsetValue
  const geom = polyMesh.geometry

  if (lerpValue === undefined) lerpValue = 1
  if (maxSpeed === undefined) maxSpeed = 9999
  if (deltaTime === undefined) deltaTime = 0.016667

  const deltaTimeOffset = Math.min(2, Math.max(1, deltaTime * 60))

  const length = polyMesh.geometry.vertices.length
  for (i = 0; i < length; i++) {
    vect = geom.vertices[i]
    lockedFactor = vect.lockedFactor

    vect.currentPos.x = parseFloat(vect.lockedPos.x * lockedFactor)
    vect.currentPos.y = parseFloat(vect.lockedPos.y * lockedFactor)
    vect.currentPos.z = parseFloat(vect.lockedPos.z * lockedFactor)

    vect.currentPos.x += parseFloat(vect.freePos.x * (1 - lockedFactor))
    vect.currentPos.y += parseFloat(vect.freePos.y * (1 - lockedFactor))
    vect.currentPos.z += parseFloat(vect.freePos.z * (1 - lockedFactor))

    if (isNaN(vect.x)) {
      vect.x = vect.freePos.x
    }
    if (isNaN(vect.y)) {
      vect.y = vect.freePos.y
    }
    if (isNaN(vect.z)) {
      vect.z = vect.freePos.z
    }

    vect.speed.x = vect.x - vect.currentPos.x
    vect.speed.y = vect.y - vect.currentPos.y
    vect.speed.z = vect.z - vect.currentPos.z

    // max speed
    speedScale = 1
    speedScale = Math.max(speedScale, Math.abs(vect.speed.x) / maxSpeed)
    speedScale = Math.max(speedScale, Math.abs(vect.speed.y) / maxSpeed)
    speedScale = Math.max(speedScale, Math.abs(vect.speed.z) / maxSpeed)

    vect.speed.x /= speedScale
    vect.speed.y /= speedScale
    vect.speed.z /= speedScale

    lerpOffsetValue = Math.min(20, Math.max(1, lerpValue / deltaTimeOffset))

    vect.x -= vect.speed.x / lerpOffsetValue
    vect.y -= vect.speed.y / lerpOffsetValue
    vect.z -= vect.speed.z / lerpOffsetValue
  }
  geom.verticesNeedUpdate = true
}

const createDetailTriangle = function (scene): Object3D {
  const triangle = new THREE.Object3D()
  const material = new THREE.MeshBasicMaterial({ color: 0xffffff, fog: false })
  const geometry = new THREE.TorusGeometry(0.2, 0.01, 3, 3)

  const triangleLines = new THREE.Mesh(geometry, material)

  triangle.add(triangleLines)

  return triangle
}

const removeChildrenFromObject = function (object3D: THREE.Object3D): void {
  let tries = 100
  while (object3D.children[0] != undefined && tries > 0) {
    object3D.remove(object3D.children[0])
    tries--
  }
}

const easeInOutQuad = function (t: number): number {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
}

const easeOutQuad = function (t: number): number {
  return t * (2 - t)
}

const easeInQuad = function (t: number): number {
  return t * t
}

const particleDistanceGenerator = function (
  freeRandomRange: THREE.Vector3,
  positionOffset: THREE.Vector3,
): THREE.Vector3 {
  if (freeRandomRange === undefined)
    freeRandomRange = new THREE.Vector3(1, 1, 1)
  if (positionOffset === undefined) positionOffset = new THREE.Vector3()
  let side = Math.random() > 0.5 ? 1 : -1
  let pos = easeOutQuad(easeOutQuad(Math.random())) * side
  side = Math.random() > 0.5 ? 1 : -1
  let pos1 = easeOutQuad(easeOutQuad(Math.random())) * side
  side = Math.random() > 0.5 ? 1 : -1
  let pos2 = easeOutQuad(easeOutQuad(Math.random())) * side
  let vert = new THREE.Vector3(pos, pos1, pos2)
  vert.normalize()
  vert.x *= freeRandomRange.x
  vert.y *= freeRandomRange.y
  vert.z *= freeRandomRange.z
  vert.x += positionOffset.x
  vert.y += positionOffset.y
  vert.z += positionOffset.z
  return vert
}

const defaultRandomFreeVertexSetup = function (
  polyMesh: any,
  freeRandomRange,
  linkedDistance,
  emptyRadius,
  positionOffset,
) {
  const polyGeom = polyMesh.geometry

  if (freeRandomRange === undefined) {
    freeRandomRange = new THREE.Vector3(5, 5, 5)
  }
  if (positionOffset === undefined) {
    positionOffset = new THREE.Vector3(0, 0, 0)
  }

  // force linkedDistance for now, if look is final, linkedDistance can be const
  linkedDistance = 0.0001
  //if (linkedDistance === undefined) linkedDistance = 0.001
  if (emptyRadius === undefined) emptyRadius = 0.5

  const zeroVector = new THREE.Vector3(0, 0, 0)
  let dist, scale, newVector

  const length = polyGeom.vertices.length
  for (let i = 0; i < length; i += 3) {
    newVector = particleDistanceGenerator(freeRandomRange, positionOffset)

    dist = newVector.distanceTo(zeroVector)
    scale = 1
    if (dist < emptyRadius) {
      scale = emptyRadius / dist
    }
    newVector.x *= scale
    newVector.y *= scale
    newVector.z *= scale

    const vect1 = polyGeom.vertices[i]
    const vect2 = polyGeom.vertices[i + 1]
    const vect3 = polyGeom.vertices[i + 2]

    vect1.freePos.x = newVector.x
    vect1.freePos.y = newVector.y
    vect1.freePos.z = newVector.z

    vect1.lockedFactor = 0
    vect1.hasLockedPos = false

    vect2.freePos.x = vect1.freePos.x + Math.random() * linkedDistance
    vect2.freePos.y = vect1.freePos.y + Math.random() * linkedDistance
    vect2.freePos.z = vect1.freePos.z + Math.random() * linkedDistance
    vect2.lockedFactor = 0
    vect2.hasLockedPos = false

    vect3.freePos.x = vect1.freePos.x + Math.random() * linkedDistance
    vect3.freePos.y = vect1.freePos.y + Math.random() * linkedDistance
    vect3.freePos.z = vect1.freePos.z + Math.random() * linkedDistance
    vect3.lockedFactor = 0
    vect3.hasLockedPos = false

    vect1.x = vect1.currentPos.x = vect1.freePos.x
    vect1.y = vect1.currentPos.y = vect1.freePos.y
    vect1.z = vect1.currentPos.z = vect1.freePos.z
    vect2.x = vect2.currentPos.x = vect2.freePos.x
    vect2.y = vect2.currentPos.y = vect2.freePos.y
    vect2.z = vect2.currentPos.z = vect2.freePos.z
    vect3.x = vect3.currentPos.x = vect3.freePos.x
    vect3.y = vect3.currentPos.y = vect3.freePos.y
    vect3.z = vect3.currentPos.z = vect3.freePos.z
  }

  processDynamicParticles(polyMesh, 1)
}

const defaultStartupScene = function (
  scene,
  polyMesh,
  wireMesh,
  pointMesh,
  assetStorageArray,
) {
  pointMesh.position.x = polyMesh.position.x = wireMesh.position.x = 0
  pointMesh.position.y = polyMesh.position.y = wireMesh.position.y = 0
  pointMesh.position.z = polyMesh.position.z = wireMesh.position.z = 0
  pointMesh.rotation.x = polyMesh.rotation.x = wireMesh.rotation.x = 0
  pointMesh.rotation.y = polyMesh.rotation.y = wireMesh.rotation.y = 0
  pointMesh.rotation.z = polyMesh.rotation.z = wireMesh.rotation.z = 0
  pointMesh.scale.x = polyMesh.scale.x = wireMesh.scale.x = 1
  pointMesh.scale.y = polyMesh.scale.y = wireMesh.scale.y = 1
  pointMesh.scale.z = polyMesh.scale.z = wireMesh.scale.z = 1
}

const colorGreen = { r: 19 / 255, g: 50 / 255, b: 43 / 255 }
const colorBlack = { r: 30 / 255, g: 30 / 255, b: 30 / 255 }
const colorNone = { r: 0, g: 0, b: 0 }

const colorBlockGreen = {
  blockColor1: colorGreen,
  blockColor2: colorGreen,
}

const colorBlockDark = {
  blockColor1: colorNone,
  blockColor2: colorBlack,
}

const colorBlockNone = {
  // Sites crashes if no color is provided, so this is placeholder for no color
  blockColor1: colorNone,
  blockColor2: colorNone,
}

const ScenesData = {
  loading: {
    name: 'loading',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [0.5, 0.5],
      ...colorBlockGreen,
    },
  },
  default: {
    name: 'default',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [0.5, 0.5],
      ...colorBlockGreen,
    },
  },
  defaultDark: {
    name: 'defaultDark',
    theme: 'dark',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [0.5, 0.5],
      ...colorBlockGreen,
    },
  },
  home: {
    name: 'home',
    mediaSettings: {
      mediaHTMLElement: '/videos/video_mountains_forward.mp4',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  hotel: {
    name: 'hotel',
    mediaSettings: {
      mediaHTMLElement: '/images/background_home_hotel.jpg',
      position: new THREE.Vector2(0.8, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'toBottom'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  meeting: {
    name: 'meeting',
    mediaSettings: {
      mediaHTMLElement: '/images/background_home_meeting.jpg',
      position: new THREE.Vector2(0.9, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  demo: {
    name: 'demo',
    mediaSettings: {
      mediaHTMLElement: '/images/background_home_demo.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'right', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  contact: {
    name: 'contact',
    mediaSettings: {
      mediaHTMLElement: '/images/background_contact.jpg',
      position: new THREE.Vector2(0.75, 0.4),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  followup: {
    name: 'followup',
    mediaSettings: {
      mediaHTMLElement: '/images/background_home_followup.jpg',
      position: new THREE.Vector2(0.8, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  ourPlatform: {
    name: 'ourPlatform',
    mediaSettings: {
      mediaHTMLElement: '/videos/video_01.mp4',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  ourPlatformCertifications: {
    name: 'ourPlatformCertifications',
    mediaSettings: {
      mediaHTMLElement: '/videos/video_02.mp4',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  ourDNA: {
    name: 'ourDNA',
    themeChangeDelay: 1,
    pageReadyDelay: 1,
    mediaSettings: {
      mediaHTMLElement: '/videos/video_snow_mountain.mp4',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  ourDNAManifest: {
    name: 'ourDNAManifest',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/videos/video_snow_mountain.mp4',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  ourDNAFirst: {
    name: 'ourDNAFirst',
    //transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/videos/video_03.mp4',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'right', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  ourDNAJoin: {
    name: 'ourDNAJoin',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_join1.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  ourDNAJoin1: {
    name: 'ourDNAJoin1',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_join2.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  ourDNAJoin2: {
    name: 'ourDNAJoin2',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_join3.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  ourDNAJoin3: {
    name: 'ourDNAJoin3',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_join4.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  ourDNAValues: {
    name: 'ourDNAValues',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_values.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  ourDNAOhpeneers: {
    name: 'ourDNAOhpeneers',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [0.6, 0.6],
      ...colorBlockGreen,
    },
  },
  ourDNAOhpeneerOverview: {
    name: 'ourDNAOhpeneerOverview',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  ourDNASponsor: {
    name: 'ourDNASponsor',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_sponsor.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [0.5, 0.5],
      ...colorBlockGreen,
    },
  },
  ourDNASponsorJourney: {
    name: 'ourDNASponsorJourney',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [0.5, 0.5],
      ...colorBlockGreen,
    },
  },
  ourDNASocial: {
    name: 'ourDNASocial',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_social.jpg',
      position: new THREE.Vector2(0.7, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromTop" "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  ourDNASocialPlanet: {
    name: 'ourDNASocialPlanet',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_social.jpg',
      position: new THREE.Vector2(0.2, 0.2),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromTop" "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  ourDNASocialPeople: {
    name: 'ourDNASocialPeople',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_social.jpg',
      position: new THREE.Vector2(0.9, 0.9),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromTop" "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockDark,
    },
  },
  ourDNAOhpeneer: {
    name: 'ourDNAOhpeneer',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromTop" "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  careersSettle: {
    name: 'careersHomeScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/images/background_careers_landing.jpg',
      position: new THREE.Vector2(0.6, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "none" "right" "left" "full"
      slideAnimations: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
      slideAnimationsMobile: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
      ...colorBlockNone,
    },
  },
  careersTour: {
    name: 'careersTourScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/videos/ohpen_careers_short.mp4',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  careersBecome: {
    name: 'careersBecomeScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/images/background_careers_become.jpg',
      position: new THREE.Vector2(0.15, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "none" "right" "left" "full"
      slideAnimations: ['fromTopInverted', 'none'], // "none" "fromTop" "fromBottom"
      slideAnimationsMobile: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
      ...colorBlockGreen,
    },
  },
  careersHealth: {
    name: 'careersHealthScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/videos/ohpen_health_50.mp4',
    },
    colorBlockSettings: {
      desktopType: 'right', // "none" "right" "left" "full"
      slideAnimations: ['fromTopInverted', 'none'], // "none" "fromTop" "fromBottom"
      slideAnimationsMobile: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
      ...colorBlockNone,
    },
  },
  careersPlay: {
    name: 'careersPlayScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/images/background_ourdna_ohpeneer_overview.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'right', // "none" "right" "left" "full"
      slideAnimations: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
      slideAnimationsMobile: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
      ...colorBlockGreen,
    },
  },
  careersHotel: {
    name: 'careersHotelScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/images/background_careers_hotel_50.jpg',
      crop: 'right',
    },
    colorBlockSettings: {
      desktopType: 'right', // "none" "right" "left" "full"
      slideAnimations: ['fromTopInverted', 'none'], // "none" "fromTop" "fromBottom"
      slideAnimationsMobile: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
      ...colorBlockGreen,
    },
  },
  careersMission: {
    name: 'careersMissionScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/images/background_careers_mission.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  careersOverview: {
    name: 'careersOverviewScene', // unique name, will be te refference for switch scene event
    theme: 'dark',
    mediaSettings: {
      mediaHTMLElement: '/images/background_careers_overview.jpg',
      position: new THREE.Vector2(0.8, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'right', // "right" "left" "full" "none"
      slideAnimations: ['fromTopInverted', 'none'],
      slideAnimationsMobile: ['fromTop', 'fromTop'], // "fromBottom" "fromLeft" "fromRight" "instant"
      blockColor1: { r: 239 / 255, g: 239 / 255, b: 239 / 255 },
      blockColor2: { r: 239 / 255, g: 239 / 255, b: 239 / 255 },
    },
  },

  newsIntro: {
    name: 'newsIntroScene', // unique name, will be te refference for switch scene event
    mediaSettings: {
      mediaHTMLElement: '/images/background_news_intro.jpg',
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      blockColor1: { r: 239 / 255, g: 239 / 255, b: 239 / 255 },
      blockColor2: { r: 239 / 255, g: 239 / 255, b: 239 / 255 },
    },
  },
  clientLogos: {
    name: 'clientLogos',
    //transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/cases/background_client_logos_v2.jpg',
      position: new THREE.Vector2(0.77, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromTop" "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [0.3, 0.3],
      ...colorBlockNone,
    },
  },
  caseBackground: {
    name: 'caseBackground',
    //transitionFrom: 'oldScene', // "oldScene" "currentScene"
    mediaSettings: {
      mediaHTMLElement: '/images/cases/content-bg.jpg',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromTop" "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [1, 1],
      ...colorBlockNone,
    },
  },
  cheetahVideo: {
    name: 'cheetahVideo',
    mediaSettings: {
      mediaHTMLElement: '/videos/cheetah_loop.mp4',
      position: new THREE.Vector2(0.13, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'right', // "right" "left" "full"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacityMobile: [1, 0.3],
      ...colorBlockGreen,
    },
  },
  treeBranchVideo: {
    name: 'treeBranchVideo',
    mediaSettings: {
      mediaHTMLElement: '/videos/tree_loop.mp4',
      position: new THREE.Vector2(0.82, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },

  landscapeDark: {
    name: 'landscapeDark',
    mediaSettings: {
      mediaHTMLElement: '/images/01_home_about_section_desktop.png',
      position: new THREE.Vector2(0.8, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['fromTop', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },

  landscapeLight: {
    name: 'landscapeLight',
    //transitionFrom: "currentScene",  // "oldScene" "currentScene"
    theme: 'dark',
    mediaSettings: {
      mediaHTMLElement: '/images/03_home_reliable_section_desktop.png',
      position: new THREE.Vector2(0.8, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  waterDark: {
    name: 'waterDark',
    mediaSettings: {
      mediaHTMLElement: '/images/02_home_adaptable_section_desktop.png',
      position: new THREE.Vector2(0.8, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'left', // "right" "left" "full" "none"
      slideAnimations: ['nonr', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockGreen,
    },
  },
  treebranch: {
    name: 'treebranch',
    camera: {
      position: new THREE.Vector3(0, 0, 5),
      rotation: new THREE.Vector3(-0.1, 0, 0),
      fov: 35,
      near: 0.01,
      far: 1000,
    },

    fog: {
      depthFar: 15,
      colorFar: 25,
    },

    backgroundColor: new THREE.Color(0x191919),
    polyMaterialSettings: {
      normalOffset: new THREE.Vector3(0.005, 0, -0.005),
      color: new THREE.Color(0x111111),
      emissive: new THREE.Color(0x000000),
    },
    wireMaterialSettings: {
      color: new THREE.Color(0xffffff),
      alpha: 0.1,
    },
    pointMaterialSettings: {
      color: new THREE.Color(0xbbbbbb),
      size: 0.01,
      alpha: 0.4,
    },
    particlesMeshSettings: {
      position: new THREE.Vector3(0, 0, 1),
      scale: new THREE.Vector3(0.1, 0.1, 0.1),
    },
    ohpenShaderSettings: {
      focus: 0.4,
      focusRange: 0.1,
      aperture: 15,
      maxblur: 0.03,
    },
    mediaSettings: {
      mediaHTMLElement: '',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
    cleanupScene: function (
      currentSceneData,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      callbackFunction,
    ) {
      removeChildrenFromObject(currentSceneData.tempData.treeLeafHolder)
      scene.remove(currentSceneData.tempData.treeLeafHolder)

      removeChildrenFromObject(scene.modelHolder)

      currentSceneData.tempData = {}

      if (callbackFunction != undefined) {
        callbackFunction()
      }
    },
    startupScene: function (
      currentSceneData,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
    ) {
      //setPolysOfMainGeom(polyMesh.geometry, 3040);

      defaultStartupScene(
        scene,
        polyMesh,
        wireMesh,
        pointMesh,
        assetStorageArray,
      )

      currentSceneData.tempData = {}
      currentSceneData.tempData.particleAnimation = { pos: 0, speed: 0 }
      currentSceneData.tempData.meshAnimation = { pos: 0 }

      function showTreeAnimation() {
        TweenMax.to(currentSceneData.tempData.particleAnimation, 10, {
          delay: 0,
          pos: 1,
          ease: Power3.easeOut,
        })
        TweenMax.to(currentSceneData.tempData.particleAnimation, 10, {
          delay: 1,
          speed: 1,
          ease: Power3.easeOut,
        })
        TweenMax.to(currentSceneData.tempData.meshAnimation, 12, {
          delay: 0,
          pos: 1,
          ease: Power1.easeInOut,
        })
      }

      if (assetStorageArray['treebranch'] === undefined) {
        assetStorageArray['treebranch'] = { loaded: false }
        const loader = new GLTFLoader()
        loader.load('/models3d/branch.gltf', function (gltf) {
          console.log('branch', gltf)
          assetStorageArray['treebranch'].loaded = true
          assetStorageArray['treebranch'].model = gltf
          assetStorageArray['treebranch'].model.scene.name = 'treebranch-model'

          showTreeAnimation()
        })
      } else {
        if (assetStorageArray['treebranch'].loaded) {
          showTreeAnimation()
        }
      }

      defaultRandomFreeVertexSetup(
        polyMesh,
        new THREE.Vector3(1, 4, 1),
        0,
        0.5,
        new THREE.Vector3(-2, 0, 5),
      )

      // create tree leaves

      const leafAlpha = new THREE.TextureLoader().load(
        '/textures/leaf_alpha_texture.png',
      )
      const leafColor = new THREE.TextureLoader().load(
        '/textures/leaf_color_texture.png',
      )
      const leafDepthColor = new THREE.TextureLoader().load(
        '/textures/leaf_depth_texture.png',
      )

      currentSceneData.tempData.treeLeafArray = []
      const treeLeafHolder = new THREE.Object3D()
      const leafGeom = new THREE.PlaneGeometry()
      leafGeom.rotateZ(Math.PI * 0.25)
      leafGeom.translate(0, 0.73, 0)
      leafGeom.scale(0.05, 0.08, 1)
      const leafMaterial = new THREE.MeshBasicMaterial({
        map: leafColor,
        alphaMap: leafAlpha,
        alphaTest: 0.3,
        side: 2,
      })

      leafMaterial.depthColorMap = leafDepthColor
      leafMaterial.backupColorMap = leafColor

      const totalLeafs = 800

      let plane
      for (let i = 0; i < totalLeafs; i++) {
        plane = new THREE.Mesh(leafGeom, leafMaterial)
        plane.scale.x = plane.scale.y = plane.scale.z = 0
        plane.position.x = plane.position.y = plane.position.z = 5000
        treeLeafHolder.add(plane)
        currentSceneData.tempData.treeLeafArray.push(plane)
      }

      if (window.ohpenExtraMaterials === undefined) {
        window.ohpenExtraMaterials = []
      }
      window.ohpenExtraMaterials.push(leafMaterial)

      currentSceneData.tempData.treeLeafHolder = treeLeafHolder
      scene.add(currentSceneData.tempData.treeLeafHolder)
    },
    meshUpdateScript: function (
      renderer,
      currentSceneData,
      camera,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      mousePoint,
      deltaTime,
    ) {
      polyMesh.visible = wireMesh.visible = pointMesh.visible = true

      if (assetStorageArray['treebranch'].loaded) {
        if (currentSceneData.viewMode === 'mobile') {
          currentSceneData.ohpenShaderSettings.focus =
            0.1 + currentSceneData.tempData.meshAnimation.pos * 0.15
        } else {
          currentSceneData.ohpenShaderSettings.focus =
            0.2 + currentSceneData.tempData.meshAnimation.pos * 0.15
        }
      }

      const leafHolder = currentSceneData.tempData.treeLeafHolder
      leafHolder.position.x = polyMesh.position.x = wireMesh.position.x = pointMesh.position.x = 1.9
      leafHolder.position.y = polyMesh.position.y = wireMesh.position.y = pointMesh.position.y = -0.5
      leafHolder.position.z = polyMesh.position.z = wireMesh.position.z = pointMesh.position.z = 0

      leafHolder.rotation.y = polyMesh.rotation.y = wireMesh.rotation.y = pointMesh.rotation.y =
        -2.9 - mousePoint.x * 0.1
      leafHolder.rotation.x = polyMesh.rotation.x = wireMesh.rotation.x = pointMesh.rotation.x =
        0 - mousePoint.y * 0.05
      leafHolder.rotation.z = polyMesh.rotation.z = wireMesh.rotation.z = pointMesh.rotation.z = 0

      leafHolder.scale.z = polyMesh.scale.z = wireMesh.scale.z = pointMesh.scale.z = 1
      leafHolder.scale.z = polyMesh.scale.x = wireMesh.scale.x = pointMesh.scale.x = 1
      leafHolder.scale.z = polyMesh.scale.y = wireMesh.scale.y = pointMesh.scale.y = 1

      if (currentSceneData.viewMode === 'mobile') {
        leafHolder.position.z = polyMesh.position.z = wireMesh.position.z = pointMesh.position.z = -2
        leafHolder.position.x = polyMesh.position.x = wireMesh.position.x = pointMesh.position.x = 1.3
      }
    },
    geomProcessScript: function (
      currentSceneData,
      camera,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      mousePoint,
      deltaTime,
    ) {
      const polyGeom = polyMesh.geometry

      if (assetStorageArray['treebranch'].loaded) {
        const model = assetStorageArray['treebranch'].model.scene.children[0]

        let vertex = new THREE.Vector3()
        let foundMeshVertex

        const geometry = model.geometry
        const index = geometry.index
        const position = geometry.attributes.position

        // morph target frame finder

        const animationFrames = geometry.morphAttributes.position.length - 1

        // get mesh animation frame
        const frame =
          currentSceneData.tempData.meshAnimation.pos * animationFrames
        const startFrame = Math.min(Math.floor(frame), animationFrames)
        const endFrame = Math.min(Math.ceil(frame), animationFrames)

        const frameOffset = frame - Math.floor(frame)

        let polyPos, vertOffset, vertPos, indexSkip

        let vertLength = polyGeom.vertices.length

        for (let i = 0; i < vertLength; i += 1) {
          polyPos = Math.floor(i / 3) * 3
          vertOffset = i % 3
          vertPos = polyPos + vertOffset

          foundMeshVertex = false
          if (index.array[vertPos] && vertPos > 2) {
            foundMeshVertex = true
          }

          polyGeom.vertices[vertPos].lockedFactor = 0
          polyGeom.vertices[vertPos].hasLockedPos = false

          if (foundMeshVertex) {
            vertex.fromBufferAttribute(position, index.array[vertPos])

            indexSkip = model.geometry.index.array[i] * 3

            vertex.x +=
              geometry.morphAttributes.position[startFrame].array[indexSkip] *
              (1 - frameOffset)
            vertex.y +=
              geometry.morphAttributes.position[startFrame].array[
                indexSkip + 1
              ] *
              (1 - frameOffset)
            vertex.z +=
              geometry.morphAttributes.position[startFrame].array[
                indexSkip + 2
              ] *
              (1 - frameOffset)

            vertex.x +=
              geometry.morphAttributes.position[endFrame].array[indexSkip] *
              frameOffset
            vertex.y +=
              geometry.morphAttributes.position[endFrame].array[indexSkip + 1] *
              frameOffset
            vertex.z +=
              geometry.morphAttributes.position[endFrame].array[indexSkip + 2] *
              frameOffset

            polyGeom.vertices[vertPos].lockedPos.x = vertex.x * 0.015
            polyGeom.vertices[vertPos].lockedPos.y = vertex.y * 0.015
            polyGeom.vertices[vertPos].lockedPos.z = vertex.z * 0.015
            polyGeom.vertices[vertPos].lockedFactor = 1
            polyGeom.vertices[vertPos].hasLockedPos = true
          }
        }

        let meshOffset, lockedVal

        for (let i = 0; i < vertLength; i++) {
          vertex.fromBufferAttribute(position, i)

          if (i % 3 === 0) {
            meshOffset = vertex.y / 250 + 0.1
          }

          lockedVal = Math.min(
            1,
            Math.max(
              0,
              currentSceneData.tempData.particleAnimation.pos * 3 -
                (meshOffset + 1),
            ),
          )
          polyGeom.vertices[i].lockedFactor = polyGeom.vertices[i].hasLockedPos
            ? lockedVal
            : 0
        }

        const lerpValue = Math.max(
          3,
          3 +
            (1 - currentSceneData.tempData.particleAnimation.pos) * 4 -
            currentSceneData.tempData.meshAnimation.pos * 10,
        )
        const maxSpeed =
          0.1 + 0.6 * currentSceneData.tempData.particleAnimation.speed

        processDynamicParticles(polyMesh, lerpValue, maxSpeed, deltaTime)

        // update leaf position
        if (!window.simplexNoise) window.simplexNoise = new SimplexNoise()

        let leaf, vert, noise, leafScale
        let leafCount = 0
        const perf = performance.now()
        vertLength = polyGeom.vertices.length
        const leafArrayLength = currentSceneData.tempData.treeLeafArray.length
        for (let i = 0; i < vertLength; i += 5) {
          if (leafCount < leafArrayLength) {
            leaf = currentSceneData.tempData.treeLeafArray[leafCount]
            vert = polyGeom.vertices[i]
            if (
              vert.y > -1.5 &&
              vert.y < 1.5 &&
              vert.x > 0.9 &&
              vert.hasLockedPos
            ) {
              leaf.position.x = vert.x
              leaf.position.y = vert.y
              leaf.position.z = vert.z

              noise = window.simplexNoise.noise(vert.x * 2, vert.y * 3)
              leaf.rotation.z = 0.3 - Math.PI * 0.5 + noise * 1.1
              leaf.rotation.z += Math.sin(vert.x * 2 + perf * 0.002) * 0.1
              leaf.rotation.y = 0.2 - noise * 0.3

              leafScale = Math.max(0, Math.min(1, (vert.x - 0.9) * 4))
              leafScale *= easeOutQuad(
                easeInOutQuad(
                  Math.max(
                    0,
                    Math.min(
                      1,
                      currentSceneData.tempData.meshAnimation.pos * 1.6 - 0.4,
                    ),
                  ),
                ),
              )
              leafScale *= 1 + Math.max(0, (noise - 0.3) * 0.6)
              leaf.scale.x = leaf.scale.y = leaf.scale.z = leafScale

              leafCount++
            }
          }
        }

        for (let i = leafCount; i < leafArrayLength; i++) {
          leaf = currentSceneData.tempData.treeLeafArray[leafCount]
          leaf.position.z = leaf.position.y = leaf.position.x = 5000
          leaf.scale.x = leaf.scale.y = leaf.scale.z = 0
        }
      }
    },
  },
  malehead: {
    name: 'malehead',
    mediaSettings: {
      mediaHTMLElement: '/images/04_home_testimonials_section_desktop.jpg',
      position: new THREE.Vector2(0, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  femalehead: {
    name: 'femalehead',
    mediaSettings: {
      mediaHTMLElement: '/images/04_home_testimonials_section_desktop.jpg',
      position: new THREE.Vector2(0, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      ...colorBlockNone,
    },
  },
  ourPlatformAbout: {
    name: 'ourPlatformAbout',
    //transitionFrom: "currentScene",  // "oldScene" "currentScene"
    camera: {
      position: new THREE.Vector3(0, 0, 5),
      rotation: new THREE.Vector3(0, 0, 0),
      fov: 35,
      near: 0.01,
      far: 1000,
    },

    fog: {
      depthFar: 15,
      depthNear: 1,
      colorFar: 10,
      colorNear: 3,
    },

    backgroundColor: new THREE.Color(0x191919),
    polyMaterialSettings: {
      normalOffset: new THREE.Vector3(0.001, 0, -0.001),
      color: new THREE.Color(0x222222),
      emissive: new THREE.Color(0x000000),
      alpha: 1,
    },
    wireMaterialSettings: {
      color: new THREE.Color(0xffffff),
      alpha: 1,
    },
    pointMaterialSettings: {
      color: new THREE.Color(0x888888),
      size: 0.03,
      alpha: 1,
    },
    particlesMeshSettings: {
      position: new THREE.Vector3(0, 0, 1),
      scale: new THREE.Vector3(0.1, 0.1, 0.1),
    },
    ohpenShaderSettings: {
      focus: 0.38,
      focusRange: 0.05,
      aperture: 15,
      maxblur: 0.02,
    },
    mediaSettings: {
      mediaHTMLElement: '',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'right', // "right" "left" "full"
      slideAnimations: ['fromTop', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacity: [1, 1],
      sceneOpacityMobile: [1, 0.3],
      blockColor1: colorGreen,
      blockColor2: colorGreen,
    },
    cleanupScene: function (
      currentSceneData,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      callbackFunction,
    ) {
      if (currentSceneData.tempData.cheetahPointersDiv != undefined) {
        if (
          currentSceneData.tempData.cheetahPointersDiv.parentNode ===
          scene.threeContainerElement
        ) {
          console.log('cheetahPointersDiv removed')
          scene.threeContainerElement.removeChild(
            currentSceneData.tempData.cheetahPointersDiv,
          )
        }
      }
      if (currentSceneData.tempData.cheetahPointersObject3D != undefined) {
        polyMesh.remove(currentSceneData.tempData.cheetahPointersObject3D)
      }

      currentSceneData.tempData = {}

      removeChildrenFromObject(scene.modelHolder)
      if (callbackFunction != undefined) {
        callbackFunction()
      }
    },
    startupScene: function (
      currentSceneData,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
    ) {
      //setPolysOfMainGeom(polyMesh.geometry, 1754);

      defaultStartupScene(
        scene,
        polyMesh,
        wireMesh,
        pointMesh,
        assetStorageArray,
      )

      currentSceneData.tempData = {}
      currentSceneData.tempData.particleAnimation = {
        pos: 0,
        speed: 0,
        animationPrepped: false,
        direction: -1,
      }
      currentSceneData.tempData.meshAnimation = { pos: 0 }

      const cheetahPointersDiv = document.createElement('div')
      cheetahPointersDiv.style.width = '1px'
      cheetahPointersDiv.style.height = '1px'
      cheetahPointersDiv.style.top = '0px'
      cheetahPointersDiv.style.left = '0px'
      cheetahPointersDiv.style.position = 'absolute'
      currentSceneData.tempData.cheetahPointersDiv = cheetahPointersDiv

      const newImg = new Image()
      TweenMax.set(newImg, { autoAlpha: 0, scale: 0.9 })
      newImg.onload = function () {
        currentSceneData.tempData.cheetahPointersDiv.appendChild(newImg)
      }
      newImg.src = '/textures/cheetah_pointers_texture.png'

      scene.threeContainerElement.appendChild(
        currentSceneData.tempData.cheetahPointersDiv,
      )

      currentSceneData.tempData.cheetahPointersObject3D = new THREE.Object3D()
      currentSceneData.tempData.cheetahPointersObject3D.position.x = 0.6
      currentSceneData.tempData.cheetahPointersObject3D.position.y = 0.5
      currentSceneData.tempData.cheetahPointersObject3D.position.z = 0
      polyMesh.add(currentSceneData.tempData.cheetahPointersObject3D)

      const startCheetahAnimation = () => {
        TweenMax.to(currentSceneData.tempData.particleAnimation, 8, {
          delay: 1,
          pos: 1,
          ease: Power2.easeOut,
        })

        TweenMax.to(currentSceneData.tempData.particleAnimation, 7, {
          delay: 1,
          speed: 1,
          ease: Power3.easeOut,
        })

        TweenMax.to(currentSceneData.tempData.meshAnimation, 0.45, {
          delay: 0,
          pos: 1,
          repeat: -1,
          ease: Linear.easeNone,
        })

        if (currentSceneData.viewMode === 'mobile') {
          TweenMax.to(newImg, 2, {
            delay: 3,
            autoAlpha: 0.4,
            scale: 1,
            ease: Power2.easeOut,
          })
        } else {
          TweenMax.to(newImg, 2, {
            delay: 3,
            autoAlpha: 1,
            scale: 1,
            ease: Power2.easeOut,
          })
        }
      }

      if (assetStorageArray['cheetah'] === undefined) {
        assetStorageArray['cheetah'] = { loaded: false }
        const loader = new GLTFLoader()
        loader.load('/models3d/cheetah_animation.gltf', function (gltf) {
          console.log('cheetah', gltf)
          assetStorageArray['cheetah'].loaded = true
          assetStorageArray['cheetah'].model = gltf
          assetStorageArray['cheetah'].model.scene.name = 'cheetah-model'
          startCheetahAnimation()
        })
      } else {
        if (assetStorageArray['cheetah'].loaded) {
          startCheetahAnimation()
        }
      }

      defaultRandomFreeVertexSetup(
        polyMesh,
        new THREE.Vector3(1, 1, 1),
        0.05,
        1,
        new THREE.Vector3(-8, 0, 2),
      )
    },
    meshUpdateScript: function (
      renderer,
      currentSceneData,
      camera,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      mousePoint,
      deltaTime,
    ) {
      polyMesh.visible = wireMesh.visible = pointMesh.visible = true

      const aspectRatio = window.innerWidth / window.innerHeight / (16 / 9)

      polyMesh.position.x = wireMesh.position.x = pointMesh.position.x =
        -1.1 * aspectRatio
      polyMesh.position.y = wireMesh.position.y = pointMesh.position.y = -0.6
      polyMesh.position.z = wireMesh.position.z = pointMesh.position.z = 0

      polyMesh.rotation.y = wireMesh.rotation.y = pointMesh.rotation.y =
        -2 - (mousePoint.x - 0.5) * 1
      polyMesh.rotation.x = wireMesh.rotation.x = pointMesh.rotation.x =
        0 - (mousePoint.y - 0.5) * 0.3
      polyMesh.rotation.z = wireMesh.rotation.z = pointMesh.rotation.z = 0

      polyMesh.scale.x = wireMesh.scale.x = pointMesh.scale.x =
        (aspectRatio + 2) / 3

      if (currentSceneData.viewMode === 'mobile') {
        polyMesh.position.x = wireMesh.position.x = pointMesh.position.x = 0.1
        polyMesh.scale.x = wireMesh.scale.x = pointMesh.scale.x = 1.1
      }

      polyMesh.scale.y = wireMesh.scale.y = pointMesh.scale.y = polyMesh.scale.x
      polyMesh.scale.z = wireMesh.scale.z = pointMesh.scale.z = polyMesh.scale.x

      camera.updateMatrixWorld()

      const proj = toScreenPosition(
        currentSceneData.tempData.cheetahPointersObject3D,
        camera,
        renderer,
      )

      currentSceneData.tempData.cheetahPointersDiv.style.left = proj.x + 'px'
      currentSceneData.tempData.cheetahPointersDiv.style.top = proj.y + 'px'

      if (currentSceneData.tempData.cheetahPointersDiv != undefined) {
        if (
          currentSceneData.tempData.cheetahPointersDiv.childNodes[0] !=
          undefined
        ) {
          const newImg =
            currentSceneData.tempData.cheetahPointersDiv.childNodes[0]
          TweenMax.set(newImg, {
            width: '65vh',
            height: '65vh',
            x: '-50%',
            y: '-45%',
          })
          if (currentSceneData.viewMode === 'mobile') {
            TweenMax.set(newImg, {
              width: '100vw',
              height: '100vw',
              x: '-47%',
              y: '-45%',
            })
          }
        }
      }
    },
    geomProcessScript: function (
      currentSceneData,
      camera,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      mousePoint,
      deltaTime,
    ) {
      const polyGeom = polyMesh.geometry

      if (assetStorageArray['cheetah'].loaded) {
        const model = assetStorageArray['cheetah'].model.scene.children[0]

        let vertex = new THREE.Vector3()
        const geometry = model.geometry
        const index = geometry.index
        const position = geometry.attributes.position
        const meshScaleOffset = 0.01
        let foundMeshVertex, indexSkip

        const animationFrames = geometry.morphAttributes.position.length

        // get mesh animation frame
        const frame =
          currentSceneData.tempData.meshAnimation.pos * animationFrames
        const startFrame = Math.floor(frame) % animationFrames
        const endFrame = Math.ceil(frame) % animationFrames
        const frameOffset = frame - Math.floor(frame)

        const vertLength = polyGeom.vertices.length
        for (let i = 0; i < vertLength; i += 1) {
          foundMeshVertex = false
          if (index.array[i] && i > 2) {
            foundMeshVertex = true
          }

          polyGeom.vertices[i].lockedFactor = 0
          polyGeom.vertices[i].hasLockedPos = false

          if (foundMeshVertex) {
            vertex.fromBufferAttribute(position, index.array[i])

            indexSkip = model.geometry.index.array[i] * 3

            vertex.x +=
              geometry.morphAttributes.position[startFrame].array[indexSkip] *
              (1 - frameOffset)
            vertex.y +=
              geometry.morphAttributes.position[startFrame].array[
                indexSkip + 1
              ] *
              (1 - frameOffset)
            vertex.z +=
              geometry.morphAttributes.position[startFrame].array[
                indexSkip + 2
              ] *
              (1 - frameOffset)

            vertex.x +=
              geometry.morphAttributes.position[endFrame].array[indexSkip] *
              frameOffset
            vertex.y +=
              geometry.morphAttributes.position[endFrame].array[indexSkip + 1] *
              frameOffset
            vertex.z +=
              geometry.morphAttributes.position[endFrame].array[indexSkip + 2] *
              frameOffset

            polyGeom.vertices[i].lockedPos.x = vertex.x * meshScaleOffset
            polyGeom.vertices[i].lockedPos.y = vertex.y * meshScaleOffset
            polyGeom.vertices[i].lockedPos.z = vertex.z * meshScaleOffset
            polyGeom.vertices[i].lockedFactor = 1
            polyGeom.vertices[i].hasLockedPos = true
          }
        }

        let meshOffset, lockedVal

        for (let i = 0; i < vertLength; i++) {
          vertex.fromBufferAttribute(position, i)

          if (i % 3 === 0) {
            meshOffset =
              (vertex.x *
                currentSceneData.tempData.particleAnimation.direction) /
                170 +
              0.1
          }

          lockedVal = Math.min(
            1,
            Math.max(
              0,
              currentSceneData.tempData.particleAnimation.pos * 3 -
                (meshOffset + 1),
            ),
          )

          polyGeom.vertices[i].lockedFactor = polyGeom.vertices[i].hasLockedPos
            ? lockedVal
            : 0
        }

        const lerpValue =
          1 + (1 - currentSceneData.tempData.particleAnimation.pos) * 10
        const maxSpeed =
          0.3 +
          3 * easeInQuad(currentSceneData.tempData.particleAnimation.speed)

        processDynamicParticles(polyMesh, lerpValue, maxSpeed, deltaTime)

        currentSceneData.tempData.particleAnimation.animationPrepped = true
      }
    },
  },
  ourPlatformOne: {
    name: 'ourPlatformOne',
    transitionFrom: 'oldScene', // "oldScene" "currentScene"
    camera: {
      position: new THREE.Vector3(0, 0, 5),
      rotation: new THREE.Vector3(0, 0, 0),
      fov: 35,
      near: 0.01,
      far: 1000,
    },
    fog: {
      depthNear: 0.01,
      depthFar: 15,
      colorNear: 4,
      colorFar: 6.5,
    },
    sendThemeChangeEvent: 1.5,
    pageReadyDelay: 1.5,
    backgroundColor: new THREE.Color(0x191919),
    polyMaterialSettings: {
      normalOffset: new THREE.Vector3(0, 0, 0),
      color: new THREE.Color(0x000000),
      emissive: new THREE.Color(0x141414),
      alpha: 1,
    },
    wireMaterialSettings: {
      color: new THREE.Color(0xffffff),
      alpha: 1,
    },
    pointMaterialSettings: {
      color: new THREE.Color(0xffffff),
      size: 0.05,
      alpha: 1,
    },
    particlesMeshSettings: {
      position: new THREE.Vector3(0, 0, 1),
      scale: new THREE.Vector3(0.1, 0.1, 0.1),
    },
    ohpenShaderSettings: {
      focus: 0.31,
      focusRange: 0.005,
      aperture: 10,
      maxblur: 0.01,
    },
    mediaSettings: {
      mediaHTMLElement: '',
      position: new THREE.Vector2(0.5, 0.5),
    },
    colorBlockSettings: {
      desktopType: 'none', // "right" "left" "full" "none"
      slideAnimations: ['none', 'none'],
      slideAnimationsMobile: ['none', 'none'], // "fromBottom" "fromLeft" "fromRight" "instant"
      sceneOpacity: [1, 1],
      sceneOpacityMobile: [1, 0.5],
      blockColor1: { r: 0, g: 0, b: 0 },
      blockColor2: { r: 30 / 255, g: 30 / 255, b: 30 / 255 },
    },
    cleanupScene: function (
      currentSceneData,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      callbackFunction,
    ) {
      polyMesh.remove(currentSceneData.tempData.rayCastMesh)
      scene.remove(currentSceneData.tempData.partnerSphere)

      if (currentSceneData.tempData.moduleMouseOverNameDiv != undefined) {
        if (
          currentSceneData.tempData.moduleMouseOverNameDiv.parentNode ===
          scene.threeContainerElement
        ) {
          console.log('moduleMouseOverNameDiv removed')
          scene.threeContainerElement.removeChild(
            currentSceneData.tempData.moduleMouseOverNameDiv,
          )
        }
      }

      currentSceneData.tempData = {}

      if (callbackFunction != undefined) {
        callbackFunction()
      }
    },
    startupScene: function (
      currentSceneData,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      moduleData,
    ) {
      defaultStartupScene(
        scene,
        polyMesh,
        wireMesh,
        pointMesh,
        assetStorageArray,
      )
      //setPolysOfMainGeom(polyMesh.geometry, 1280);

      currentSceneData.tempData = {}
      currentSceneData.tempData.particleAnimation = { pos: 0, speed: 0 }
      currentSceneData.tempData.positionOffset = { x: 0, y: 0, z: 0 }
      currentSceneData.tempData.rotationOffset = { x: 0, y: 0, z: 0 }
      currentSceneData.tempData.scaleOffset = { x: 0, y: 0, z: 0 }
      currentSceneData.tempData.coreScaleOffset = { pos: 0 }
      currentSceneData.tempData.modulesOffset = { pos: 0 }
      currentSceneData.tempData.modulesCloseUpOffset = { pos: 0 }
      currentSceneData.tempData.partnersOffset = { pos: 0 }
      currentSceneData.tempData.meshNoise = { pos: 0 }
      currentSceneData.tempData.currentPartnerCategory = -1
      currentSceneData.tempData.lastRoute = 'dummy'

      TweenMax.to(currentSceneData.tempData.particleAnimation, 8, {
        delay: 1,
        pos: 1,
        ease: Power2.easeOut,
      })

      TweenMax.to(currentSceneData.tempData.particleAnimation, 7, {
        delay: 3,
        speed: 1,
        ease: Power3.easeOut,
      })

      const moduleNameArray = moduleData.allCmsModule.edges.map((edge) => {
        return {
          name: edge.node.slug,
          link: edge.node.slug,
        }
      })

      currentSceneData.tempData.moduleDataArray = moduleNameArray

      const moduleMouseOverNameDiv = document.createElement('div')
      moduleMouseOverNameDiv.style.width = '1px'
      moduleMouseOverNameDiv.style.height = '1px'
      moduleMouseOverNameDiv.style.top = '0px'
      moduleMouseOverNameDiv.style.left = '0px'
      moduleMouseOverNameDiv.style.color = '#ffffff'

      moduleMouseOverNameDiv.style.fontFamily = "'Univers LT Ultra Condensed'"
      moduleMouseOverNameDiv.style.fontSize = '20px'
      moduleMouseOverNameDiv.style.lineHeight = '20px'
      moduleMouseOverNameDiv.style.letterSpacing = '0.4px'
      moduleMouseOverNameDiv.style.cursor = 'pointer'

      TweenMax.set(moduleMouseOverNameDiv, { autoAlpha: 0 })

      moduleMouseOverNameDiv.dotDiv = document.createElement('div')
      TweenMax.set(moduleMouseOverNameDiv.dotDiv, {
        position: 'absolute',
        backgroundColor: '#bbbbbb',
        height: 1,
        width: 23,
        transformOrigin: '0% 100%',
        rotation: -18,
        boxShadow:
          '0 0 4px rgba(15,15,15,1), 0 0 4px rgba(15,15,15,1), 0 0 4px rgba(15,15,15,1)',
      })
      moduleMouseOverNameDiv.appendChild(moduleMouseOverNameDiv.dotDiv)

      moduleMouseOverNameDiv.textDiv = document.createElement('div')
      moduleMouseOverNameDiv.textDiv.style.whiteSpace = 'nowrap'
      moduleMouseOverNameDiv.textDiv.style.left = '30px'
      TweenMax.set(moduleMouseOverNameDiv.textDiv, {
        position: 'absolute',
        height: 20,
        width: 'auto',
        y: '-100%',
        textShadow:
          '0 0 4px rgba(15,15,15,1), 0 0 4px rgba(15,15,15,1), 0 0 20px rgba(15,15,15,1)',
      })
      moduleMouseOverNameDiv.appendChild(moduleMouseOverNameDiv.textDiv)

      moduleMouseOverNameDiv.style.position = 'absolute'
      currentSceneData.tempData.moduleMouseOverNameDiv = moduleMouseOverNameDiv
      currentSceneData.tempData.moduleMouseOverNameDiv.posParams = {
        x: 0,
        y: 0,
        targetX: 0,
        targetY: 0,
      }

      currentSceneData.tempData.moduleMouseOverNameDiv.showMe = false

      scene.threeContainerElement.appendChild(
        currentSceneData.tempData.moduleMouseOverNameDiv,
      )

      currentSceneData.tempData.moduleMouseOverObject3D = new THREE.Object3D()
      polyMesh.add(currentSceneData.tempData.moduleMouseOverObject3D)

      // create logo sphere points

      const logoImageArray = [
        {
          src: '/textures/partners/logo_clearstream.png',
          cat: ['Ordering/Trading'],
        },
        {
          src: '/textures/partners/logo_abnamro.png',
          cat: ['Ordering/Trading', 'Payments (Corporate)'],
        },
        {
          src: '/textures/partners/logo_calastone.png',
          cat: ['Ordering/Trading'],
        },
        {
          src: '/textures/partners/logo_ing.png',
          cat: ['Payments (Corporate)'],
        },
        {
          src: '/textures/partners/logo_amazonsns.png',
          cat: ['Customer Communication Channels'],
        },
        {
          src: '/textures/partners/logo_jpmorgan.png',
          cat: ['Ordering/Trading'],
        },
        {
          src: '/textures/partners/logo_hsbc.png',
          cat: ['Payments (Corporate)'],
        },
        { src: '/textures/partners/logo_infront.png', cat: ['Fund pricing'] },
        {
          src: '/textures/partners/logo_equens.png',
          cat: ['Payments (Interbanking)'],
        },
        {
          src: '/textures/partners/logo_solgary.png',
          cat: ['Customer Communication Channels'],
        },
        {
          src: '/textures/partners/logo_kasbank.png',
          cat: ['Ordering/Trading'],
        },
        { src: '/textures/partners/logo_gbg.png', cat: ['CDD/KYC/AML'] },
        {
          src: '/textures/partners/logo_office365.png',
          cat: ['Customer Communication Channels'],
        },
        {
          src: '/textures/partners/logo_worldpay.png',
          cat: ['Payments (Interbanking)', 'Payments'],
        },
        { src: '/textures/partners/logo_aws.png', cat: ['Hosting'] },
        {
          src: '/textures/partners/logo_iquality.png',
          cat: ['Front-end development'],
        },
        { src: '/textures/partners/logo_experian.png', cat: ['CDD/KYC/AML'] },
        {
          src: '/textures/partners/logo_zendesk.png',
          cat: ['Customer Communication Channels'],
        },
        {
          src: '/textures/partners/logo_ortecfinance.png',
          cat: ['Financial Planning'],
        },
        {
          src: '/textures/partners/logo_appian.png',
          cat: ['Business Process management'],
        },
        {
          src: '/textures/partners/logo_weareyou.png',
          cat: ['Front-end development'],
        },
        { src: '/textures/partners/logo_plaid.png', cat: ['Payments'] },
        { src: '/textures/partners/logo_raet.png', cat: ['Remuneration'] },
        {
          src: '/textures/partners/logo_atlus.png',
          cat: ['Portfolio transfers'],
        },
        { src: '/textures/partners/logo_progress.png', cat: ['Hosting'] },
        { src: '/textures/partners/logo_iwize.png', cat: ['CDD/KYC/AML'] },
        { src: '/textures/partners/logo_fourthline.png', cat: ['CDD/KYC/AML'] },
        { src: '/textures/partners/logo_focum.png', cat: ['CDD/KYC/AML'] },
        { src: '/textures/partners/logo_bkr.png', cat: ['CDD/KYC/AML'] },
        { src: '/textures/partners/logo_bvd.png', cat: ['CDD/KYC/AML'] },
        { src: '/textures/partners/logo_nhg.png', cat: ['CDD/KYC/AML'] },
        {
          src: '/textures/partners/logo_ech.png',
          cat: ['Data Communication Standards'],
        },
        {
          src: '/textures/partners/logo_hdn.png',
          cat: ['Data Communication Standards'],
        },
        { src: '/textures/partners/logo_evidos.png', cat: ['Technology'] },
        { src: '/textures/partners/logo_avast.png', cat: ['Technology'] },
        {
          src: '/textures/partners/logo_abbyy.png',
          cat: ['Business Process management'],
        },
      ]

      const partnerArray = [
        'Hosting',
        'Ordering/Trading',
        'CDD/KYC/AML',
        'Fund pricing',
        'Portfolio transfers',
        'Payments (Interbanking)',
        'Payments (Corporate)',
        'Financial Planning',
        'Front-end development',
        'Customer Communication Channels',
        'Remuneration',
        'Chat',
        'Telephone',
        'Payments',
        'Business Process management',
        'Technology',
        'Data Communication Standards',
      ]

      const partnerRotationArray = []
      partnerRotationArray.push(new THREE.Vector2(0.52, -1.37))
      partnerRotationArray.push(new THREE.Vector2(-0.35, 0.95))
      partnerRotationArray.push(new THREE.Vector2(2.07, -0.4))
      partnerRotationArray.push(new THREE.Vector2(-1.61, 1.69))
      partnerRotationArray.push(new THREE.Vector2(-0.23, -2.98))
      partnerRotationArray.push(new THREE.Vector2(0.54, 1.91))
      partnerRotationArray.push(new THREE.Vector2(0.61, 0.15))
      partnerRotationArray.push(new THREE.Vector2(-0.27, 2.55))
      partnerRotationArray.push(new THREE.Vector2(-1.02, -2.66))
      partnerRotationArray.push(new THREE.Vector2(-0.51, -0.89))

      partnerRotationArray.push(new THREE.Vector2(0.05, -2))

      partnerRotationArray.push(new THREE.Vector2(0.1, -2.32))
      partnerRotationArray.push(new THREE.Vector2(0.54, -1.47))
      partnerRotationArray.push(new THREE.Vector2(2.83, -1.33))
      partnerRotationArray.push(new THREE.Vector2(0.78, -2.32))
      partnerRotationArray.push(new THREE.Vector2(0, -2.6))
      partnerRotationArray.push(new THREE.Vector2(0, -2.6))
      partnerRotationArray.push(new THREE.Vector2(0, 0))

      currentSceneData.tempData.partnerRotationArray = partnerRotationArray

      currentSceneData.tempData.spriteMaterialArray = []

      currentSceneData.tempData.partnerCategorySelectNumber = -1
      currentSceneData.tempData.partnerCategorySelect =
        'this is an empty category'
      currentSceneData.tempData.partnerSelect = function (data) {
        const pos = partnerArray.indexOf(data)
        currentSceneData.tempData.partnerCategorySelectNumber = pos
        currentSceneData.tempData.partnerCategorySelect = data
      }

      const partnerHolder = new THREE.Object3D()
      partnerHolder.frustumCulled = false

      let phi, theta, vector, spriteMap, spriteMaterial, sprite
      const num_pts = logoImageArray.length
      const spherePointsArray = []
      for (let i = 0; i < num_pts; i++) {
        spriteMap = new THREE.TextureLoader().load(logoImageArray[i].src)
        spriteMap.minFilter = THREE.LinearFilter
        spriteMap.magFilter = THREE.LinearFilter
        spriteMaterial = new THREE.SpriteMaterial({
          alphaMap: spriteMap,
          alphaTest: 0.2,
          transparent: true,
        })
        spriteMaterial.targetOpacity = 1
        spriteMaterial.catergoriesArray = logoImageArray[i].cat

        currentSceneData.tempData.spriteMaterialArray.push(spriteMaterial)

        phi = Math.acos(1 - (2 * (i + 0.5)) / num_pts)
        theta = Math.PI * (1 + 5 ** 0.5) * (i + 0.5)
        vector = new THREE.Vector3(
          Math.cos(theta) * Math.sin(phi),
          Math.sin(theta) * Math.sin(phi),
          Math.cos(phi),
        )
        spherePointsArray.push(vector)

        sprite = new THREE.Sprite(spriteMaterial)
        sprite.scale.x = sprite.scale.y = sprite.scale.z = 0.7
        sprite.position.x = vector.x * 0.85
        sprite.position.y = vector.y * 0.85
        sprite.position.z = vector.z * 0.85
        partnerHolder.add(sprite)
        partnerHolder.position.z = 0
      }

      currentSceneData.tempData.partnerSphere = partnerHolder
      currentSceneData.tempData.partnerSphere.position.x = -0.7
      currentSceneData.tempData.partnerSphere.position.z = 0
      scene.add(currentSceneData.tempData.partnerSphere)

      // set interaction mesh for module selection
      currentSceneData.tempData.raycaster = new THREE.Raycaster()
      currentSceneData.tempData.sphereGeom = new THREE.IcosahedronGeometry(8, 3)
      currentSceneData.tempData.rayCastMesh = new THREE.Mesh(
        currentSceneData.tempData.sphereGeom,
        new THREE.MeshBasicMaterial({
          color: 0x00ff0000,
          transparent: true,
          opacity: 0,
          alphaTest: 0,
        }),
      )
      currentSceneData.tempData.rayCastMesh.scale.x = 0.099
      currentSceneData.tempData.rayCastMesh.scale.y = 0.099
      currentSceneData.tempData.rayCastMesh.scale.z = 0.099
      polyMesh.add(currentSceneData.tempData.rayCastMesh)

      console.log(currentSceneData.tempData.sphereGeom)

      defaultRandomFreeVertexSetup(
        polyMesh,
        new THREE.Vector3(7, 7, 7),
        0.1,
        1.4,
        new THREE.Vector3(-2, 0, -9),
      )
    },
    meshUpdateScript: function (
      renderer,
      currentSceneData,
      camera,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      mousePoint,
      deltaTime,
    ) {
      polyMesh.visible = wireMesh.visible = pointMesh.visible = true

      const aspectRatio = window.innerWidth / window.innerHeight / (16 / 9)

      for (
        let i = currentSceneData.tempData.spriteMaterialArray.length - 1;
        i >= 0;
        i--
      ) {
        currentSceneData.tempData.spriteMaterialArray[i].targetOpacity =
          currentSceneData.tempData.partnersOffset.pos

        if (
          !currentSceneData.tempData.spriteMaterialArray[
            i
          ].catergoriesArray.includes(
            currentSceneData.tempData.partnerCategorySelect,
          )
        ) {
          if (
            currentSceneData.tempData.partnerCategorySelect !=
            'this is an empty category'
          ) {
            currentSceneData.tempData.spriteMaterialArray[
              i
            ].targetOpacity *= 0.1
          }
        }

        currentSceneData.tempData.spriteMaterialArray[i].color.r -=
          (currentSceneData.tempData.spriteMaterialArray[i].color.r -
            (0.2 +
              currentSceneData.tempData.spriteMaterialArray[i].targetOpacity *
                0.8)) /
          10
        currentSceneData.tempData.spriteMaterialArray[i].color.g =
          currentSceneData.tempData.spriteMaterialArray[i].color.r
        currentSceneData.tempData.spriteMaterialArray[i].color.b =
          currentSceneData.tempData.spriteMaterialArray[i].color.r
      }

      polyMesh.position.x = wireMesh.position.x = pointMesh.position.x =
        currentSceneData.tempData.positionOffset.x * aspectRatio
      polyMesh.position.y = wireMesh.position.y = pointMesh.position.y =
        currentSceneData.tempData.positionOffset.y
      polyMesh.position.z = wireMesh.position.z = pointMesh.position.z =
        currentSceneData.tempData.positionOffset.z

      polyMesh.rotation.y = currentSceneData.tempData.rotationOffset.y
      polyMesh.rotation.x = currentSceneData.tempData.rotationOffset.x

      polyMesh.rotation.y -=
        (mousePoint.x - 0.5) *
        (0.8 +
          currentSceneData.tempData.modulesOffset.pos * 2 -
          currentSceneData.tempData.modulesCloseUpOffset.pos)
      polyMesh.rotation.x -=
        (mousePoint.y - 0.5) *
        (0.6 +
          currentSceneData.tempData.modulesOffset.pos * 2 -
          currentSceneData.tempData.modulesCloseUpOffset.pos)

      wireMesh.rotation.x = pointMesh.rotation.x = polyMesh.rotation.x
      wireMesh.rotation.y = pointMesh.rotation.y = polyMesh.rotation.y

      pointMesh.scale.x = polyMesh.scale.x = wireMesh.scale.x =
        1 + currentSceneData.tempData.scaleOffset.x
      pointMesh.scale.y = polyMesh.scale.y = wireMesh.scale.y =
        1 + currentSceneData.tempData.scaleOffset.y
      pointMesh.scale.z = polyMesh.scale.z = wireMesh.scale.z =
        1 + currentSceneData.tempData.scaleOffset.z

      // make points a little bit biggier
      wireMesh.scale.x = wireMesh.scale.y = wireMesh.scale.z =
        wireMesh.scale.x -
        0.001 * (currentSceneData.tempData.modulesCloseUpOffset.pos * 2 - 1)
      pointMesh.scale.x = pointMesh.scale.y = pointMesh.scale.z =
        pointMesh.scale.x -
        0.001 * (currentSceneData.tempData.modulesCloseUpOffset.pos * 2 - 1)

      // core offset

      currentSceneData.polyMaterialSettings.alpha =
        currentSceneData.tempData.coreScaleOffset.pos * 2 - 1
      currentSceneData.wireMaterialSettings.alpha =
        currentSceneData.tempData.coreScaleOffset.pos * 2 - 1
      currentSceneData.pointMaterialSettings.alpha = 1

      const colorValue = 1 - currentSceneData.tempData.partnersOffset.pos * 0.7

      currentSceneData.wireMaterialSettings.color.r = colorValue
      currentSceneData.wireMaterialSettings.color.g = colorValue
      currentSceneData.wireMaterialSettings.color.b = colorValue

      currentSceneData.pointMaterialSettings.color.r = colorValue
      currentSceneData.pointMaterialSettings.color.g = colorValue
      currentSceneData.pointMaterialSettings.color.b = colorValue

      currentSceneData.wireMaterialSettings.alpha = Math.min(
        1,
        Math.max(0, currentSceneData.wireMaterialSettings.alpha),
      )
      currentSceneData.polyMaterialSettings.alpha = Math.min(
        1,
        Math.max(0, currentSceneData.wireMaterialSettings.alpha),
      )

      wireMesh.scale.x *= currentSceneData.tempData.coreScaleOffset.pos
      wireMesh.scale.y = wireMesh.scale.z = wireMesh.scale.x
      polyMesh.scale.x *= currentSceneData.tempData.coreScaleOffset.pos
      polyMesh.scale.y = polyMesh.scale.z = polyMesh.scale.x

      wireMesh.position.z += 1 - currentSceneData.tempData.coreScaleOffset.pos
      polyMesh.position.z += 1 - currentSceneData.tempData.coreScaleOffset.pos

      // lock partner Holder and moduler holder to sphere
      currentSceneData.tempData.partnerSphere.position.x = polyMesh.position.x
      currentSceneData.tempData.partnerSphere.position.y = polyMesh.position.y
      currentSceneData.tempData.partnerSphere.position.z = polyMesh.position.z

      if (currentSceneData.viewMode === 'mobile') {
        currentSceneData.tempData.partnerSphere.scale.x = 0.85
        currentSceneData.tempData.partnerSphere.scale.y = 0.85
        currentSceneData.tempData.partnerSphere.scale.z = 0.85
      }

      let newRotX, newRotY

      if (currentSceneData.tempData.partnerCategorySelectNumber < 0) {
        newRotX = (mousePoint.targetY - 0.5) * 8
        newRotY = (mousePoint.targetX - 0.5) * 8
      } else {
        newRotX =
          currentSceneData.tempData.partnerRotationArray[
            currentSceneData.tempData.partnerCategorySelectNumber
          ].x
        newRotY =
          currentSceneData.tempData.partnerRotationArray[
            currentSceneData.tempData.partnerCategorySelectNumber
          ].y - 0.3

        // add mouse position offset
        newRotX += (mousePoint.targetY - 0.5) * 0.2
        newRotY += (mousePoint.targetX - 0.5) * 0.2
      }

      // check the partner sphere rotation

      const maxSpeed = 0.05

      const speedX = Math.max(
        -maxSpeed,
        Math.min(
          maxSpeed,
          (currentSceneData.tempData.partnerSphere.rotation.x - newRotX) / 20,
        ),
      )
      const speedY = Math.max(
        -maxSpeed,
        Math.min(
          maxSpeed,
          (currentSceneData.tempData.partnerSphere.rotation.y - newRotY) / 20,
        ),
      )

      currentSceneData.tempData.partnerSphere.rotation.x -= speedX
      currentSceneData.tempData.partnerSphere.rotation.y -= speedY

      currentSceneData.tempData.partnerSphere.visible = false
      if (currentSceneData.tempData.partnersOffset.pos > 0.01) {
        currentSceneData.tempData.partnerSphere.visible = true
      }

      currentSceneData.ohpenShaderSettings.focus =
        0.31 +
        currentSceneData.tempData.partnersOffset.pos * 0.1 +
        easeInQuad(currentSceneData.tempData.modulesCloseUpOffset.pos) * 0.13
      currentSceneData.ohpenShaderSettings.aperture =
        10 + currentSceneData.tempData.partnersOffset.pos * 30
      currentSceneData.fog.depthNear =
        0.01 + currentSceneData.tempData.partnersOffset.pos * 2
      currentSceneData.fog.depthFar =
        15 + currentSceneData.tempData.partnersOffset.pos * 1
      currentSceneData.ohpenShaderSettings.maxblur =
        0.01 * (1 - currentSceneData.tempData.partnersOffset.pos)

      if (
        currentSceneData.tempData.lastRoute != currentSceneData.route ||
        currentSceneData.lastViewMode != currentSceneData.viewMode
      ) {
        TweenMax.to(currentSceneData.tempData.moduleMouseOverNameDiv, 0.05, {
          autoAlpha: 0,
        })
        currentSceneData.tempData.moduleMouseOverNameDiv.showMe = false

        const easeFactor = Power3.easeInOut
        const timing = 2.3

        polyMesh.visible = wireMesh.visible = pointMesh.visible = true

        currentSceneData.tempData.modulesCloseUpOffset.backward = true
        currentSceneData.colorBlockSettings.desktopType = 'none'
        currentSceneData.colorBlockSettings.slideAnimations = ['none', 'none']
        currentSceneData.colorBlockSettings.slideAnimationsMobile = [
          'none',
          'none',
        ]
        currentSceneData.colorBlockSettings.sceneOpacity = [1, 1]
        currentSceneData.colorBlockSettings.sceneOpacityMobile = [1, 1]

        if (currentSceneData.route === '/our-platform/one') {
          TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
            x: 0,
            y: 0,
            z: 0.2,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.rotationOffset, timing, {
            x: 0,
            y: 0,
            z: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.scaleOffset, timing, {
            x: -0.3,
            y: -0.3,
            z: -0.3,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.coreScaleOffset, timing * 0.8, {
            pos: 1,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.modulesOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          TweenMax.to(
            currentSceneData.tempData.modulesCloseUpOffset,
            timing * 0.5,
            { pos: 0, ease: easeFactor, overwrite: 1 },
          )
          TweenMax.to(currentSceneData.tempData.partnersOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.meshNoise, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          currentSceneData.colorBlockSettings.sceneOpacityMobile = [0.5, 0.5]
        }

        if (currentSceneData.route === '/our-platform/foundation') {
          if (currentSceneData.viewMode === 'mobile') {
            TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
              x: 0,
              y: 0,
              z: 0,
              ease: easeFactor,
            })
          } else {
            TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
              x: -1.4,
              y: 0,
              z: 0,
              ease: easeFactor,
            })
          }
          TweenMax.to(currentSceneData.tempData.rotationOffset, timing, {
            x: 0,
            y: 0,
            z: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.scaleOffset, timing, {
            x: 0,
            y: 0,
            z: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.coreScaleOffset, timing * 0.8, {
            pos: 0.5,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.modulesOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          TweenMax.to(
            currentSceneData.tempData.modulesCloseUpOffset,
            timing * 0.5,
            { pos: 0, ease: easeFactor, overwrite: 1 },
          )
          TweenMax.to(currentSceneData.tempData.partnersOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.meshNoise, timing, {
            pos: 1,
            ease: easeFactor,
          })

          currentSceneData.colorBlockSettings.sceneOpacityMobile = [0.5, 0.5]
        }

        if (
          currentSceneData.route === '/our-platform/modules' ||
          currentSceneData.route === '/our-platform/all-modules'
        ) {
          if (currentSceneData.viewMode === 'mobile') {
            TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
              x: 0,
              y: 0,
              z: 0,
              ease: easeFactor,
            })
          } else {
            TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
              x: 1.1,
              y: 0,
              z: 0,
              ease: easeFactor,
            })
          }
          TweenMax.to(currentSceneData.tempData.rotationOffset, timing, {
            x: 0,
            y: 0,
            z: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.scaleOffset, timing, {
            x: 0,
            y: 0,
            z: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.coreScaleOffset, timing * 0.8, {
            pos: 1,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.modulesOffset, timing * 1.5, {
            pos: 1,
            ease: easeFactor,
          })
          TweenMax.to(
            currentSceneData.tempData.modulesCloseUpOffset,
            timing * 1.5,
            { pos: 0, ease: easeFactor, overwrite: 1 },
          )
          TweenMax.to(currentSceneData.tempData.partnersOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.meshNoise, timing * 0.7, {
            pos: 0,
            ease: easeFactor,
          })

          if (currentSceneData.viewMode === 'mobile') {
            currentSceneData.colorBlockSettings.sceneOpacityMobile = [0.3, 0.3]
          } else {
            currentSceneData.colorBlockSettings.sceneOpacityMobile = [1, 1]
          }

          if (currentSceneData.route === '/our-platform/all-modules') {
            currentSceneData.colorBlockSettings.desktopType = 'left'
            currentSceneData.colorBlockSettings.slideAnimations = [
              'fromTop',
              'none',
            ]
            currentSceneData.colorBlockSettings.slideAnimationsMobile = [
              'none',
              'none',
            ]
            // if (currentSceneData.viewMode === 'mobile') {
            //   currentSceneData.colorBlockSettings.sceneOpacityMobile = [0.3, 0.3]
            // } else {
            //   currentSceneData.colorBlockSettings.sceneOpacityMobile = [1, 1]
            // }

            currentSceneData.colorBlockSettings.blockColor2 = {
              r: 0,
              g: 0,
              b: 0,
            }
            currentSceneData.colorBlockSettings.blockColor1 = {
              r: 30 / 255,
              g: 30 / 255,
              b: 30 / 255,
            }
          }
        }

        if (
          currentSceneData.route === '/our-platform/partners' ||
          currentSceneData.route === '/our-platform/our-partners'
        ) {
          console.log(
            'platform sphere position viewmode',
            currentSceneData.viewMode,
          )
          if (currentSceneData.viewMode === 'mobile') {
            TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
              x: 0,
              y: 0,
              z: 0,
              ease: easeFactor,
            })
          } else {
            TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
              x: -1.3,
              y: 0,
              z: 0,
              ease: easeFactor,
            })
          }
          TweenMax.to(currentSceneData.tempData.rotationOffset, timing, {
            x: 0,
            y: 0,
            z: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.scaleOffset, timing, {
            x: -0.7,
            y: -0.7,
            z: -0.7,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.coreScaleOffset, timing * 0.8, {
            pos: 1,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.modulesOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          TweenMax.to(
            currentSceneData.tempData.modulesCloseUpOffset,
            timing * 0.5,
            { pos: 0, ease: easeFactor, overwrite: 1 },
          )
          TweenMax.to(currentSceneData.tempData.partnersOffset, timing * 0.4, {
            delay: timing * 0.3,
            pos: 1,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.meshNoise, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })

          if (currentSceneData.route === '/our-platform/partners') {
            currentSceneData.tempData.partnerCategorySelectNumber = -1
            currentSceneData.tempData.partnerCategorySelect =
              'this is an empty category'
            currentSceneData.colorBlockSettings.sceneOpacityMobile = [0.1, 0.1]
          }
          if (currentSceneData.route === '/our-platform/our-partners') {
            currentSceneData.colorBlockSettings.desktopType = 'right'
            currentSceneData.colorBlockSettings.slideAnimations = [
              'fromTop',
              'none',
            ]
            currentSceneData.colorBlockSettings.slideAnimationsMobile = [
              'none',
              'none',
            ]
            currentSceneData.colorBlockSettings.blockColor1 = {
              r: 0,
              g: 0,
              b: 0,
            }
            currentSceneData.colorBlockSettings.blockColor2 = {
              r: 30 / 255,
              g: 30 / 255,
              b: 30 / 255,
            }
            currentSceneData.colorBlockSettings.sceneOpacityMobile = [1, 1]
          }
        }

        if (currentSceneData.route.indexOf('/our-platform/module/') === 0) {
          let moduleName = currentSceneData.route.split('/our-platform/module/')
          moduleName = moduleName[moduleName.length - 1]

          TweenMax.to(currentSceneData.tempData.positionOffset, timing, {
            x: 0,
            y: 0,
            z: 3,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.rotationOffset, timing, {
            x: 0,
            y: 0,
            z: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.scaleOffset, timing, {
            x: 3,
            y: 3,
            z: 3,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.coreScaleOffset, timing * 0.8, {
            pos: 1,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.modulesOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          currentSceneData.tempData.modulesCloseUpOffset.backward = false
          TweenMax.to(
            currentSceneData.tempData.modulesCloseUpOffset,
            timing * 0.5,
            { pos: 1, ease: Power1.easeIn, overwrite: 1 },
          )
          TweenMax.to(currentSceneData.tempData.partnersOffset, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })
          TweenMax.to(currentSceneData.tempData.meshNoise, timing * 0.5, {
            pos: 0,
            ease: easeFactor,
          })

          currentSceneData.colorBlockSettings.sceneOpacityMobile = [0.3, 0.3]
          currentSceneData.colorBlockSettings.sceneOpacity = [0.3, 0.3]
        }
        currentSceneData.tempData.lastRoute = currentSceneData.route
        currentSceneData.lastViewMode = currentSceneData.viewMode
      }

      currentSceneData.tempData.selectedFace = undefined
      // raytrace sphere
      const mouse = new THREE.Vector2(
        mousePoint.targetX * 2 - 1,
        (0 - mousePoint.targetY) * 2 + 1,
      )
      currentSceneData.tempData.raycaster.setFromCamera(mouse, camera)
      let intersects = []

      if (
        currentSceneData.route === '/our-platform/modules' ||
        currentSceneData.route === '/our-platform/all-modules'
      ) {
        intersects = currentSceneData.tempData.raycaster.intersectObject(
          currentSceneData.tempData.rayCastMesh,
        )
      }

      if (
        intersects.length > 0 &&
        currentSceneData.viewMode != 'mobile' &&
        currentSceneData.tempData.modulesCloseUpOffset.pos < 0.1
      ) {
        if (polyMesh.geometry.vertices[0].lockedFactor > 0.9) {
          const face = intersects[0].face
          currentSceneData.tempData.selectedFace = face
        }
      }

      if (currentSceneData.tempData.modulesCloseUpOffset.pos > 0.1) {
        currentSceneData.tempData.selectedFace = undefined
      }

      camera.updateMatrixWorld()

      const moduleMouseOverNameDiv =
        currentSceneData.tempData.moduleMouseOverNameDiv

      if (currentSceneData.tempData.selectedFace != undefined) {
        moduleMouseOverNameDiv.textDiv.innerHTML = currentSceneData.tempData.currentModuleData.name
          .split('-')
          .join(' ')
          .toUpperCase()
        if (!moduleMouseOverNameDiv.showMe) {
          TweenMax.to(moduleMouseOverNameDiv, 0.3, { autoAlpha: 1 })
          moduleMouseOverNameDiv.showMe = true
          document.body.style.cursor = 'pointer'
        }
      } else {
        if (moduleMouseOverNameDiv.showMe) {
          TweenMax.to(moduleMouseOverNameDiv, 0.05, { autoAlpha: 0 })
          moduleMouseOverNameDiv.showMe = false
          document.body.style.cursor = 'default'
        }
      }

      let proj

      if (moduleMouseOverNameDiv.showMe) {
        proj = toScreenPosition(
          currentSceneData.tempData.moduleMouseOverObject3D,
          camera,
          renderer,
        )
      } else {
        proj = toScreenPosition(polyMesh, camera, renderer)
      }

      moduleMouseOverNameDiv.posParams.targetX = proj.x
      moduleMouseOverNameDiv.posParams.targetY = proj.y

      moduleMouseOverNameDiv.posParams.x -=
        (moduleMouseOverNameDiv.posParams.x -
          moduleMouseOverNameDiv.posParams.targetX) /
        5
      moduleMouseOverNameDiv.posParams.y -=
        (moduleMouseOverNameDiv.posParams.y -
          moduleMouseOverNameDiv.posParams.targetY) /
        5

      currentSceneData.tempData.moduleMouseOverNameDiv.style.left =
        moduleMouseOverNameDiv.posParams.x + 'px'
      currentSceneData.tempData.moduleMouseOverNameDiv.style.top =
        moduleMouseOverNameDiv.posParams.y + 'px'
    },
    geomProcessScript: function (
      currentSceneData,
      camera,
      scene,
      polyMesh,
      wireMesh,
      pointMesh,
      assetStorageArray,
      mousePoint,
      deltaTime,
    ) {
      const currentTime = performance.now() / 1000

      const polyGeom = polyMesh.geometry

      let polyNr, vertOffset, vertChar, vert, moduleCloseupPos
      const vertCharArray = ['a', 'b', 'c']

      const vertLength = polyGeom.vertices.length

      const meshScaleOffset = 0.1

      let x, y, z, selectedOffset
      const selectedFace = currentSceneData.tempData.selectedFace

      currentSceneData.tempData.currentModuleData = undefined

      for (let i = 0; i < vertLength; i += 1) {
        moduleCloseupPos = currentSceneData.tempData.modulesCloseUpOffset.pos
        if (currentSceneData.tempData.modulesCloseUpOffset.backward) {
          moduleCloseupPos = moduleCloseupPos * 2 - (i / vertLength) * 2
          moduleCloseupPos = easeInOutQuad(
            Math.min(1, Math.max(0, moduleCloseupPos)),
          )
        } else {
          moduleCloseupPos = moduleCloseupPos * 2 - easeOutQuad(i / vertLength)
          moduleCloseupPos = Math.min(1, Math.max(0, moduleCloseupPos))
        }

        x = 0
        y = 0
        z = 0

        polyNr = Math.floor(i / 3)
        vertOffset = i % 3
        vertChar = vertCharArray[vertOffset]

        if (currentSceneData.tempData.sphereGeom.faces[polyNr]) {
          vert = currentSceneData.tempData.sphereGeom.faces[polyNr][vertChar]
          x =
            currentSceneData.tempData.sphereGeom.vertices[vert].x *
            meshScaleOffset
          y =
            currentSceneData.tempData.sphereGeom.vertices[vert].y *
            meshScaleOffset
          z =
            currentSceneData.tempData.sphereGeom.vertices[vert].z *
            meshScaleOffset
          polyGeom.vertices[i].hasLockedPos = true
        } else {
          polyGeom.vertices[i].hasLockedPos = false
        }

        polyGeom.vertices[i].lockedPos.x = x
        polyGeom.vertices[i].lockedPos.y = y
        polyGeom.vertices[i].lockedPos.z = z
      }

      if (!window.simplexNoise) window.simplexNoise = new SimplexNoise()

      let meshOffset, lockedVal, meshNoiseOffset

      let mouseOverVert = new THREE.Vector3(-2000, -2000, -2000)
      let normalOffset = 0
      let normalOffsetOpenSphere = { x: 0, y: 0, z: 0 }

      for (let i = 0; i < vertLength; i++) {
        polyNr = Math.floor(i / 3)

        meshNoiseOffset = 0
        if (currentSceneData.tempData.meshNoise.pos > 0) {
          meshNoiseOffset =
            window.simplexNoise.noise3d(
              polyGeom.vertices[i].lockedPos.x * 1.5 + currentTime * 0.1,
              polyGeom.vertices[i].lockedPos.y * 1.2 - currentTime * 0.3,
              polyGeom.vertices[i].lockedPos.z * 1.8 - currentTime * 0.2,
            ) * 0.1
          polyGeom.vertices[i].lockedPos.x +=
            polyGeom.vertices[i].lockedPos.x *
            meshNoiseOffset *
            currentSceneData.tempData.meshNoise.pos
          polyGeom.vertices[i].lockedPos.y +=
            polyGeom.vertices[i].lockedPos.y *
            meshNoiseOffset *
            currentSceneData.tempData.meshNoise.pos
          polyGeom.vertices[i].lockedPos.z +=
            polyGeom.vertices[i].lockedPos.z *
            meshNoiseOffset *
            currentSceneData.tempData.meshNoise.pos
        }

        // check if poly has mouse over
        selectedOffset = false
        if (
          currentSceneData.tempData.sphereGeom.faces[polyNr] === selectedFace
        ) {
          currentSceneData.tempData.currentModuleData =
            currentSceneData.tempData.moduleDataArray[
              polyNr % currentSceneData.tempData.moduleDataArray.length
            ]
          selectedOffset = true
        }

        if (selectedOffset) {
          normalOffset = 0.15
        } else {
          if (i % 3 === 0) {
            normalOffset = window.simplexNoise.noise(
              polyGeom.vertices[i].lockedPos.x + currentTime * 0.01,
              polyGeom.vertices[i].lockedPos.y - currentTime * 0.01,
            )
            normalOffsetOpenSphere = { x: 0, y: 0, z: 0 }

            normalOffset = Math.abs(normalOffset * normalOffset)
            normalOffset = Math.min(1, Math.max(0, normalOffset * 20 - 5))
            if (polyGeom.vertices[i].lockedPos.z > 0.65) {
              normalOffset += easeInOutQuad(
                currentSceneData.tempData.modulesCloseUpOffset.pos,
              )
              normalOffsetOpenSphere.x +=
                easeOutQuad(
                  currentSceneData.tempData.modulesCloseUpOffset.pos,
                ) * Math.sign(polyGeom.vertices[i].lockedPos.x)
              normalOffsetOpenSphere.y +=
                easeOutQuad(
                  currentSceneData.tempData.modulesCloseUpOffset.pos,
                ) * Math.sign(polyGeom.vertices[i].lockedPos.y)
              normalOffsetOpenSphere.z += easeOutQuad(
                currentSceneData.tempData.modulesCloseUpOffset.pos,
              )
            } else {
              normalOffset -= easeInOutQuad(
                currentSceneData.tempData.modulesCloseUpOffset.pos,
              )
            }
            normalOffset = Math.min(1.3, Math.max(0, normalOffset))
            normalOffset = -easeInOutQuad(normalOffset * 0.7) * 0.8
          }
        }

        polyGeom.vertices[i].lockedPos.x +=
          polyGeom.vertices[i].lockedPos.x *
          normalOffset *
          currentSceneData.tempData.modulesOffset.pos
        polyGeom.vertices[i].lockedPos.y +=
          polyGeom.vertices[i].lockedPos.y *
          normalOffset *
          currentSceneData.tempData.modulesOffset.pos
        polyGeom.vertices[i].lockedPos.z +=
          polyGeom.vertices[i].lockedPos.z *
          normalOffset *
          currentSceneData.tempData.modulesOffset.pos

        polyGeom.vertices[i].lockedPos.x +=
          polyGeom.vertices[i].lockedPos.x *
          normalOffset *
          currentSceneData.tempData.modulesCloseUpOffset.pos
        polyGeom.vertices[i].lockedPos.y +=
          polyGeom.vertices[i].lockedPos.y *
          normalOffset *
          currentSceneData.tempData.modulesCloseUpOffset.pos
        polyGeom.vertices[i].lockedPos.z +=
          polyGeom.vertices[i].lockedPos.z *
          normalOffset *
          currentSceneData.tempData.modulesCloseUpOffset.pos

        polyGeom.vertices[i].lockedPos.x +=
          normalOffsetOpenSphere.x *
          currentSceneData.tempData.modulesCloseUpOffset.pos
        polyGeom.vertices[i].lockedPos.y +=
          normalOffsetOpenSphere.y *
          currentSceneData.tempData.modulesCloseUpOffset.pos
        polyGeom.vertices[i].lockedPos.z -=
          normalOffsetOpenSphere.z *
          currentSceneData.tempData.modulesCloseUpOffset.pos *
          5

        // set module mouse over position
        if (
          currentSceneData.tempData.sphereGeom.faces[polyNr] === selectedFace
        ) {
          if (mouseOverVert.x < polyGeom.vertices[i].lockedPos.x) {
            mouseOverVert = polyGeom.vertices[i]
          }
        }

        if (i % 3 === 0) {
          meshOffset = polyGeom.vertices[i].lockedPos.x / 2 + 0.5
        }

        lockedVal = Math.min(
          1,
          Math.max(
            0,
            currentSceneData.tempData.particleAnimation.pos * 3 -
              (meshOffset + 1),
          ),
        )

        polyGeom.vertices[i].lockedFactor = polyGeom.vertices[i].hasLockedPos
          ? lockedVal
          : 0
      }
      const lerpValue = 3 + currentSceneData.tempData.particleAnimation.pos * 3
      const maxSpeed =
        0.3 + 0.7 * currentSceneData.tempData.particleAnimation.speed

      processDynamicParticles(polyMesh, lerpValue, maxSpeed, deltaTime)

      // set mouse over to correct vert
      if (selectedFace != undefined) {
        currentSceneData.tempData.moduleMouseOverObject3D.position.x =
          mouseOverVert.x
        currentSceneData.tempData.moduleMouseOverObject3D.position.y =
          mouseOverVert.y
        currentSceneData.tempData.moduleMouseOverObject3D.position.z =
          mouseOverVert.z
      }
    },
  },
}

const toScreenPosition = function (obj, camera) {
  const vector = new THREE.Vector3()

  const widthHalf = 0.5 * window.innerWidth
  const heightHalf = 0.5 * window.innerHeight

  obj.updateMatrixWorld()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = vector.x * widthHalf + widthHalf
  vector.y = -(vector.y * heightHalf) + heightHalf

  return {
    x: vector.x,
    y: vector.y,
  }
}

export default ScenesData
