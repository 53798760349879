import styled from '@xstyled/styled-components'
import { Link } from '@reach/router'

export const Container = styled.div`
  display: flex;
`

interface VariantProps {
  variant: string
}

export const NavigationLink = styled(Link)<VariantProps>`
  font-size: 14px;
  font-weight: bold;
  &:link,
  &:visited,
  &:active {
    color: secondary;
  }
  text-decoration: none;
  text-transform: uppercase;
`
