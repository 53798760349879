import React from 'react'

import { LayoutContainer, Left, Content } from '../styled'
import Footer from '@/components/Footer'

import Paginator, { PaginatorProps } from '@/components/Paginator'

//
// @ts-ignore
const DefaultTemplate: React.FC<{
  path: string
  paginatorData: PaginatorProps
  desktop: boolean
  dropdownActive: boolean
}> = ({ children, path, paginatorData, desktop, dropdownActive }) => {
  // const [hasCookieBot, setHasCookieBot] = useState(undefined)
  const { max, number } = paginatorData

  return (
    <>
      <Left>
        {max > 1 && (
          <Paginator visible={!dropdownActive} number={number} max={max} />
        )}
      </Left>
      <LayoutContainer flex>
        <Content flex>{children}</Content>
        <Footer
          withContact={
            !desktop && number === 0 && !dropdownActive && path !== '/contact'
          }
          dropdownActive={dropdownActive}
          subPage={false}
          Paginator={
            max > 1 && (
              <Paginator
                visible={!dropdownActive}
                type="dotted"
                number={number}
                max={max}
              />
            )
          }
        />
      </LayoutContainer>
    </>
  )
}

export default DefaultTemplate
